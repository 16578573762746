import * as Types from './types';

const initialState: Types.ITransactionState = { transactionProcessing: false };

export function transactionReducer(
  state = initialState,
  action: Types.ActionType
): Types.ITransactionState {
  switch (action.type) {
    case Types.UPDATE_TRANSACTION:
      return {
        ...state,
        transactionResponse: action.transactionResponse,
        transactionProcessing: false,
      };
    case Types.SET_TRANSACTION_PROCESSING:
      return { ...state, transactionProcessing: action.processing };
    default:
      return state;
  }
}
