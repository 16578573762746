import { IPledgeInformationResponse } from '../../models/api/PledgeInformationResponse';
import { IPledgePayments } from '../../models/PledgePayments';
import { IExistingPledgePaymentType } from '../../util/dataSets/existingPledgePaymentAmounts';
import * as Types from './types';

export function setPledgeType(
  pledgeType?: Types.PledgeType
): Types.ActionTypes {
  return {
    type: Types.SET_PLEDGE_TYPE,
    pledgeType,
  };
}

export function clearPledge(): Types.ActionTypes {
  return {
    type: Types.CLEAR_PLEDGE,
  };
}

export function setExistingPledgeID(id?: string): Types.ActionTypes {
  return {
    type: Types.SET_EXISTING_PLEDGE_ID,
    id,
  };
}

export function fetchExistingPledgeSuccess(
  id: string,
  response: IPledgeInformationResponse
): Types.ActionTypes {
  return {
    type: Types.FETCH_EXISTING_PLEDGE_SUCCESS,
    pledge: response,
    id,
  };
}

export function setExistingPledgeNoIDPaymentAmount(
  amount?: number
): Types.ActionTypes {
  return {
    type: Types.SET_EXISTING_PLEDGE_NO_ID_PAYMENT_AMOUNT,
    amount,
  };
}
export function setExistingPledgePaymentType(
  paymentType: IExistingPledgePaymentType
): Types.ActionTypes {
  return {
    type: Types.SET_EXISTING_PLEDGE_PAYMENT_TYPE,
    paymentType,
  };
}

export function togglePaymentAmount(
  paymentAmount: IPledgePayments
): Types.ActionTypes {
  return {
    type: Types.SET_EXISTING_PLEDGE_PAYMENT_AMOUNT_TYPE,
    link:
      paymentAmount.pledgeReceiptLink.toString() +
        '_' +
        paymentAmount.paymentDueDate || '',
    value: paymentAmount?.paymentAmountDue,
  };
}

export function clearExistingPerPayments(): Types.ActionTypes {
  return {
    type: Types.CLEAR_EXISTING_PLEDGE_PAYMENT_AMOUNT_TYPE,
  };
}

export function setExistingPledgeCustomPaymentAmount(
  id: string,
  amount?: number
): Types.ActionTypes {
  return {
    type: Types.SET_EXISTING_PLEDGE_CUSTOM_PAYMENT_AMOUNT,
    id,
    amount,
  };
}

export function clearExistingPledgeCustomPayments(): Types.ActionTypes {
  return {
    type: Types.CLEAR_EXISTING_PLEDGE_CUSTOM_PAYMENTS,
  };
}

export function setExistingPledgeEnableAutopay(
  value: boolean
): Types.ActionTypes {
  return {
    type: Types.SET_EXISTING_PLEDGE_ENABLE_AUTOPAY,
    value,
  };
}

export function setExistingPledgeIDKnown(value: boolean): Types.ActionTypes {
  return {
    type: Types.SET_EXISTING_PLEDGE_ID_KNOWN,
    value,
  };
}

export function setExistingPledgeComments(
  comments?: string
): Types.ActionTypes {
  return {
    type: Types.SET_EXISTING_PLEDGE_COMMENTS,
    comments,
  };
}

export function setExistingPledgeIsLoading(
  isLoading?: boolean
): Types.ActionTypes {
  return {
    type: Types.SET_EXISTING_PLEDGE_IS_LOADING,
    isLoading,
  };
}
