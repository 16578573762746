import React from 'react';
import './TextInput.scss';

export type IHTMLInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface IWrapperProps {
  inputField: (props: IHTMLInputProps) => JSX.Element;
}

export interface ISharedInputProps {
  value?: any;

  className?: string;
  id?: string;
  label?: string;
  error?: string;
  placeholder?: string;

  valid?: boolean;
  required?: boolean;
  missing?: boolean;

  styleSearch?: boolean;
  styleCurrency?: boolean;
}

export type IProps = IWrapperProps & ISharedInputProps;

export const UnwrapEventWithCallback =
  (callback: (value?: string) => void) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    callback(event.currentTarget.value);
  };

/** InputWrapper slaps the CSS, label and error message around "inputField",
 *  a function prop through which the caller can inject the actual <input> field.
 *  Useful for styling third-party utils such as IntlTelInput and CurrencyInputField.
 */

export const InputWrapper = (props: IProps) => {
  const input = props.inputField({
    className: inputClassesForProps(props),
    placeholder: props.placeholder,
  });

  const error = props.error ? (
    <p className='ogp-text-input__error-msg'>{props.error}</p>
  ) : null;

  return (
    <div className={`ogp-text-input ${props.className || ''}`}>
      {Label(props.label, props.id)}
      <div
        className={`ogp-text-input__container ${containerClassesForProps(
          props
        )}`}
      >
        {input}
      </div>
      {error}
    </div>
  );
};

const inputFieldBaseClass = 'ogp-text-input__field';

export const inputClassesForProps = (props: IProps) => {
  return [
    inputFieldBaseClass,
    props.valid ? `${inputFieldBaseClass}--valid-icon` : '',
    props.error ? `${inputFieldBaseClass}--error` : '',
    props.styleSearch ? `${inputFieldBaseClass}--search` : '',
    props.styleSearch ? `${inputFieldBaseClass}--search-icon` : '',
    props.styleCurrency ? `${inputFieldBaseClass}--currency` : '',
    props.required ? `${inputFieldBaseClass}--required-icon` : '',
    props.missing && (!props.valid || props.error !== undefined)
      ? `${inputFieldBaseClass}--missing`
      : '',
    props.error ? `${inputFieldBaseClass}--error-icon` : '',
  ].join(' ');
};

const inputContainerBaseClass = 'ogp-text-input__container';
export const containerClassesForProps = (props: IProps) => {
  return [
    props.valid ? `${inputContainerBaseClass}--valid-icon` : '',
    props.styleSearch ? `${inputContainerBaseClass}--search` : '',
    props.styleSearch ? `${inputContainerBaseClass}--search-icon` : '',
    props.styleCurrency ? `${inputContainerBaseClass}--currency` : '',
    props.error ? `${inputContainerBaseClass}--error` : '',
    props.required ? `${inputContainerBaseClass}--required-icon` : '',
    props.error ? `${inputContainerBaseClass}--error-icon` : '',
  ].join(' ');
};

const Label = (label?: string, id?: string) =>
  label ? (
    <label className='ogp-text-input__label' htmlFor={id}>
      {label}
    </label>
  ) : null;
