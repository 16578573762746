import React from 'react';
import { Controller, FormContextValues, FormContext } from 'react-hook-form';

interface IInjectedInputProps {
  rules?: any;
  as: React.ElementType | string;
  nameOrSuffix: string;
}

type FormContext = FormContextValues<Record<string, any>>;

export interface IInputFieldGroupProps {
  injectControlledInput: (props: IInjectedInputProps & any) => any;
  getCompleteFieldName: (suffix: string) => string;

  context: FormContext;
  errors: any;
}

interface WrapperProps {
  fieldNamePrefix?: string;
  context: FormContext;
}

export const InputFieldGroup = <IProps extends object>(
  Component: React.ComponentType<IProps & IInputFieldGroupProps>
) => {
  return (props: IProps & WrapperProps) => {
    const getCompleteFieldName = (suffix: string) =>
      props.fieldNamePrefix && props.fieldNamePrefix.length
        ? `${props.fieldNamePrefix}-${suffix}`
        : suffix;

    const injectInput = (inputProps: IInjectedInputProps & any) => {
      const { nameOrSuffix, ...sanitizedInputProps } = inputProps;
      return (
        <Controller
          {...sanitizedInputProps}
          as={inputProps.as}
          rules={inputProps.rules}
          context={props.context}
          control={props.context.control}
          errors={props.context.errors}
          name={getCompleteFieldName(nameOrSuffix)}
        />
      );
    };

    return (
      <FormContext {...props.context}>
        <Component
          {...props}
          getCompleteFieldName={getCompleteFieldName}
          injectControlledInput={injectInput}
          errors={props.context.errors}
        />
      </FormContext>
    );
  };
};
