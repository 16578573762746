import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setGiftTypeSingle } from '../../../store/app/actions';
import { clearTransaction } from '../../../store/transaction/actions';
import { ResponsiveContainer } from '../../components/responsiveContainer/ResponsiveContainer';
import { SearchOptionsUnwrapper } from '../../wrappers/urlQueryUnwrapper/SearchOptionsUnwrapper';
import { OGPPageDesktop } from './OGPPageDesktop';
import { OGPPageMobile } from './OGPPageMobile';

const mapStateToProps = () => ({});
const mapDispatchToProps = { setGiftTypeSingle, clearTransaction };

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const OGPPageView = (props: ReduxProps) => {
  const { setGiftTypeSingle, clearTransaction } = props;

  React.useEffect(() => {
    setGiftTypeSingle();
  }, [setGiftTypeSingle]);

  React.useEffect(() => {
    clearTransaction();
  }, [clearTransaction]);

  const Container = ResponsiveContainer(OGPPageDesktop, OGPPageMobile);
  return (
    <>
      <SearchOptionsUnwrapper>
        <Container />
      </SearchOptionsUnwrapper>
    </>
  );
};

export const OGPPage = connector(OGPPageView);
