import React from 'react';

import { InfoTooltip } from '../../../components/infoTooltip/InfoTooltip';
import { IProps } from '../PledgeView';
import { PledgePaymentNoIDView } from './noID/PledgePaymentNoIDView';
import { PledgePaymentWithIDView } from './withID/PledgePaymentWithIDView';

// import { no, yes } from '../../../../../assets/values/yesNo.json';
// import { Radio } from '../../../components/radio/Radio';
export const PledgePaymentView = (props: IProps) => {
  // const pledgeIdKnownOptions = [
  //   { label: 'Yes, I know my pledge ID.', value: yes.value },
  //   { label: "I don't know my pledge ID.", value: no.value },
  // ];
  // const idKnownRadioValue = props.existingPledgeIDKnown ? yes.value : no.value;
  // const onIDKnownSelected = (value: string) => {
  //   props.setExistingPledgeIDKnown(value === yes.value);
  //   props.setPledgeType(
  //     value === yes.value ? 'PLEDGE_TYPE_PAYMENT' : 'PLEDGE_TYPE_ESTABLISH'
  //   );
  // };

  return (
    <div>
      <div className='d--f'>
        <h3 className='ut-h3 pb--20'>Enter your Pledge ID</h3>
        {/* <h3 className='ut-h3 pb--20'>Do you know your pledge ID?</h3> */}
        <InfoTooltip
          tooltipContent={
            'Your Pledge ID number can be found on your pledge reminder.'
          }
        />
      </div>
      {/* <p className='pb--20'>
        {`Your pledge ID can be found on this spot on your pledge reminder letter. `}
      </p> */}
      {/* <Radio
        className='pb--20'
        options={pledgeIdKnownOptions}
        onChange={onIDKnownSelected}
        value={idKnownRadioValue}
      /> */}
      <div>
        {props.existingPledgeIDKnown ? (
          <PledgePaymentWithIDView {...props} />
        ) : (
          <PledgePaymentNoIDView {...props} />
        )}
      </div>
    </div>
  );
};
