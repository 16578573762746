import React from 'react';

import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as singleGiftSelectors from '../../../store/cart/singleGift//selectors';
import { RootState } from '../../../store';
import { Routes } from '../../../router/Routes';

const mapStateToProps = (state: RootState) => {
  const itemCount = singleGiftSelectors.getItemCount(state);

  return {
    hasGifts: itemCount > 0,
  };
};

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const FrequencyNoCartRedirectView = (props: ReduxProps) => {
  return !props.hasGifts ? <Redirect to={Routes.Sections.Search.path} /> : null;
};

export const FrequencyNoCartRedirect = connector(FrequencyNoCartRedirectView);
