import React from 'react';
import { useTextFieldOpenListener } from '../../wrappers/InputOpenListener';
import {
  IHTMLInputProps,
  InputWrapper,
  ISharedInputProps,
} from './InputWrapper';

interface IUniqueProps {
  keydown?: (event: any) => void;
}

export type IProps = IUniqueProps & ISharedInputProps & IHTMLInputProps;

export const TextInput = (props: IProps) => {
  const focusEventProps = useTextFieldOpenListener(props);

  // Remove non-input keys from the <input> props, otherwise React gets mad
  const {
    styleSearch,
    styleCurrency,
    error,
    valid,
    missing = false,
    keydown,
    ...sanitizedProps
  } = props;

  return (
    <InputWrapper
      {...props}
      missing={missing && (!valid || error !== undefined)}
      inputField={inputProps =>
        !keydown ? (
          <input {...sanitizedProps} {...inputProps} {...focusEventProps} />
        ) : (
          <input
            {...sanitizedProps}
            {...inputProps}
            {...focusEventProps}
            onKeyDown={keydown}
          />
        )
      }
    />
  );
};
