import { createSelector } from 'reselect';

import { RootState } from '../';
import {
  getNewPledgeIsValid,
  getSummaryVisible as getEstablishSummaryVisible,
} from '../cart/pledge/selectors';
import {
  getAutopayPossible as getExistingAutopayPossible,
  getGiftValid as getExistingGiftValid,
  getSummaryVisibleCreator as getExistingSummaryVisibleCreator,
} from './existingPledge/selectors';
import { PLEDGE_TYPE_ESTABLISH, PLEDGE_TYPE_PAYMENT } from './types';

export const getPledgeState = (state: RootState) => state.pledge;

export const getPledgeType = createSelector([getPledgeState], state => {
  const type = state.existingPledgeID
    ? PLEDGE_TYPE_PAYMENT
    : PLEDGE_TYPE_ESTABLISH;
  return type;
});

export const getPledgeAutopayPossible = createSelector(
  [getExistingAutopayPossible],
  existingPossible => {
    return existingPossible;
  }
);

export const getSummaryVisibleCreator = () =>
  createSelector(
    [
      getPledgeType,
      getEstablishSummaryVisible,
      getExistingSummaryVisibleCreator(),
    ],
    (pledgeType, establishVisible, getVisible) => (checkoutPage: boolean) => {
      switch (pledgeType) {
        case PLEDGE_TYPE_ESTABLISH:
          return establishVisible;
        case PLEDGE_TYPE_PAYMENT:
          return getVisible(checkoutPage);
      }
      return false;
    }
  );

export const getPledgeGiftValid = createSelector(
  [getPledgeType, getExistingGiftValid, getNewPledgeIsValid],
  (type, existingValid, establishValid) => {
    switch (type) {
      case PLEDGE_TYPE_PAYMENT:
        return existingValid;
      case PLEDGE_TYPE_ESTABLISH:
        return establishValid;
    }
    return false;
  }
);
