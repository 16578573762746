import * as Types from './types';
import { GIFT_TYPE_SINGLE, GIFT_TYPE_PLEDGE } from '../../models/GiftType';

export function setGiftTypeSingle(): Types.ActionType {
  return {
    type: Types.SET_GIFT_TYPE,
    giftType: GIFT_TYPE_SINGLE,
  };
}

export function setGiftTypePledge(): Types.ActionType {
  return {
    type: Types.SET_GIFT_TYPE,
    giftType: GIFT_TYPE_PLEDGE,
  };
}
