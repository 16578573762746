import * as React from 'react';
import { useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Routes } from '../../../router/Routes';
import { RootState } from '../../../store';
import {
  getDefaultPaymentFormValues,
  getShowMissingFields,
} from '../../../store/payment/selectors';
import { PledgeMobileNav } from '../../containers/mobileNav/PledgeMobileNav';
import { MobilePledgeSummary } from '../../containers/pledgeSummary/mobile/MobilePledgeSummary';
import { WindowTo404Redirect } from '../../router/redirects/WindowTo404Redirect';
import {
  CheckoutPageMobile,
  NextButton as CheckoutNextButton,
} from '../checkout/mobile/CheckoutPageMobile';
import {
  NextButton as HomeNextButton,
  PledgeHomePageMobile,
} from '../home/pledge/mobile/PledgeHomePageMobile';

const basePath = Routes.Roots.Pledge.path;
const searchPath = basePath + Routes.Sections.Search.path;
const checkoutPath = basePath + Routes.Sections.Checkout.path;

interface PledgeRouterContent {
  checkout: React.ReactNode;
  home: React.ReactNode;
}

const mapStateToProps = (state: RootState) => {
  return {
    defaultValues: getDefaultPaymentFormValues(state),
    showMissingFields: getShowMissingFields(state),
  };
};

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type IProps = ReduxProps & {};

const createPledgeMobileRouter = (content: PledgeRouterContent) => (
  <>
    <Switch>
      <Route path={checkoutPath}>{content.checkout}</Route>
      <Route exact path={searchPath}>
        {content.home}
      </Route>
      <Route path='*'>
        <WindowTo404Redirect />
      </Route>
    </Switch>
  </>
);

const PledgePageMobileView = (props: IProps) => {
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: props.defaultValues,
  });

  const [summaryheightPx, setSummaryHeightPx] = React.useState(0);
  const contentPaddingBottom = `calc(${summaryheightPx}px + 1rem)`;

  const location = useLocation();
  const displayingCheckout = location.pathname === checkoutPath;

  const pageRouterContent = createPledgeMobileRouter({
    home: <PledgeHomePageMobile />,
    checkout: <CheckoutPageMobile form={form} />,
  });

  const nextButton = createPledgeMobileRouter({
    home: <HomeNextButton />,
    checkout: <CheckoutNextButton />,
  });

  return (
    <div style={{ paddingBottom: contentPaddingBottom }}>
      <PledgeMobileNav basePath={basePath} homePageTitle='Pledge Pay' />
      {pageRouterContent}
      <div className='mob-pin-bottom'>
        <MobilePledgeSummary
          form={form}
          isCheckoutPage={displayingCheckout}
          nextButton={nextButton}
          onHeightUpdated={setSummaryHeightPx}
        />
      </div>
    </div>
  );
};

export const PledgePageMobile = connector(PledgePageMobileView);
