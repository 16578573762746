import { IPledgeInformationResponse } from '../../models/api/PledgeInformationResponse';
import { IExistingPledgePaymentType } from '../../util/dataSets/existingPledgePaymentAmounts';

export const PLEDGE_TYPE_ESTABLISH = 'PLEDGE_TYPE_ESTABLISH';
export const PLEDGE_TYPE_PAYMENT = 'PLEDGE_TYPE_PAYMENT';

export type PledgeType =
  | typeof PLEDGE_TYPE_ESTABLISH
  | typeof PLEDGE_TYPE_PAYMENT;

export interface IPledgePayment {
  id: string;
  amount?: string;
  comments?: string;
}

export interface IStore {
  pledgeType?: PledgeType;

  existingPledgeIDKnown?: boolean;
  existingPledgeID?: string;

  existingPledgeResponse?: IPledgeInformationResponse;
  existingPledgePaymentType?: IExistingPledgePaymentType;
  existingPledgeEnableAutopay?: boolean;
  existingPledgeComments?: string;
  existingPledgeCustomPaymentAmounts: { [id: string]: number | undefined };
  existingPledgePerPaymentAmounts: { [id: string]: number | undefined };

  existingPledgeNoIDPaymentAmount?: number;
  existingPledgeIsLoading?: boolean;
}

export const SET_PLEDGE_TYPE = 'SET_PLEDGE_TYPE';
export const CLEAR_PLEDGE = 'CLEAR_PLEDGE';

export const SET_EXISTING_PLEDGE_ID = 'SET_EXISTING_PLEDGE_ID';
export const SET_EXISTING_PLEDGE_IS_LOADING = 'SET_EXISTING_PLEDGE_IS_LOADING';
export const FETCH_EXISTING_PLEDGE_SUCCESS = 'FETCH_EXISTING_PLEDGE_SUCCESS';
export const SET_EXISTING_PLEDGE_NO_ID_PAYMENT_AMOUNT =
  'SET_EXISTING_PLEDGE_NO_ID_PAYMENT_AMOUNT';
export const SET_EXISTING_PLEDGE_PAYMENT_TYPE =
  'SET_EXISTING_PLEDGE_PAYMENT_TYPE';
export const SET_EXISTING_PLEDGE_PAYMENT_AMOUNT_TYPE =
  'SET_EXISTING_PLEDGE_PAYMENT_AMOUNT_TYPE';
export const CLEAR_EXISTING_PLEDGE_PAYMENT_AMOUNT_TYPE =
  'CLEAR_EXISTING_PLEDGE_PAYMENT_AMOUNT_TYPE';
export const SET_EXISTING_PLEDGE_CUSTOM_PAYMENT_AMOUNT =
  'SET_EXISTING_PLEDGE_CUSTOM_PAYMENT_AMOUNT';
export const CLEAR_EXISTING_PLEDGE_CUSTOM_PAYMENTS =
  'CLEAR_EXISTING_PLEDGE_CUSTOM_PAYMENTS';
export const SET_EXISTING_PLEDGE_ENABLE_AUTOPAY =
  'SET_EXISTING_PLEDGE_ENABLE_AUTOPAY';
export const SET_EXISTING_PLEDGE_ID_KNOWN = 'SET_EXISTING_PLEDGE_ID_KNOWN';
export const SET_EXISTING_PLEDGE_COMMENTS = 'SET_EXISTING_PLEDGE_COMMENTS';

interface ISetPledgeType {
  type: typeof SET_PLEDGE_TYPE;
  pledgeType?: PledgeType;
}

interface IClearPledge {
  type: typeof CLEAR_PLEDGE;
}

interface ISetExistingPledgeID {
  type: typeof SET_EXISTING_PLEDGE_ID;
  id?: string;
}

interface IFetchExistingPledgeSuccess {
  type: typeof FETCH_EXISTING_PLEDGE_SUCCESS;
  id: string;
  pledge: IPledgeInformationResponse;
}

interface ISetNoIDPaymentAmount {
  type: typeof SET_EXISTING_PLEDGE_NO_ID_PAYMENT_AMOUNT;
  amount?: number;
}

interface ISetExistingPledgePaymentType {
  type: typeof SET_EXISTING_PLEDGE_PAYMENT_TYPE;
  paymentType: IExistingPledgePaymentType;
}

interface ISetExistingPledgePaymentAmountType {
  type: typeof SET_EXISTING_PLEDGE_PAYMENT_AMOUNT_TYPE;
  link: string;
  value?: number;
}

interface IClearExistingPledgePaymentAmountType {
  type: typeof CLEAR_EXISTING_PLEDGE_PAYMENT_AMOUNT_TYPE;
}

interface ISetExistingPledgeCustomPaymentAmount {
  type: typeof SET_EXISTING_PLEDGE_CUSTOM_PAYMENT_AMOUNT;
  id: string;
  amount?: number;
}

interface IClearExistingPledgeCustomPayments {
  type: typeof CLEAR_EXISTING_PLEDGE_CUSTOM_PAYMENTS;
}

interface ISetExistingPledgeEnableAutopay {
  type: typeof SET_EXISTING_PLEDGE_ENABLE_AUTOPAY;
  value: boolean;
}

interface ISetExistingPledgeIDKnown {
  type: typeof SET_EXISTING_PLEDGE_ID_KNOWN;
  value: boolean;
}

interface ISetExistingPledgeComments {
  type: typeof SET_EXISTING_PLEDGE_COMMENTS;
  comments?: string;
}

interface ISetExistingPledgeIsLoading {
  type: typeof SET_EXISTING_PLEDGE_IS_LOADING;
  isLoading?: boolean;
}

export type ActionTypes =
  | ISetPledgeType
  | ISetExistingPledgeID
  | ISetExistingPledgeIDKnown
  | IFetchExistingPledgeSuccess
  | ISetExistingPledgePaymentType
  | ISetExistingPledgePaymentAmountType
  | ISetExistingPledgeCustomPaymentAmount
  | IClearExistingPledgePaymentAmountType
  | IClearExistingPledgeCustomPayments
  | ISetNoIDPaymentAmount
  | ISetExistingPledgeEnableAutopay
  | IClearPledge
  | ISetExistingPledgeComments
  | ISetExistingPledgeIsLoading;
