import { RootState } from '..';
import { createSelector } from 'reselect';

const getLayoutState = (state: RootState) => state.layout;

export const getFooterWidth = createSelector(
  [getLayoutState],
  state => state.footerWidth
);
export const getHeaderHeight = createSelector(
  [getLayoutState],
  state => state.headerHeight
);
export const getNavHeight = createSelector(
  [getLayoutState],
  state => state.navHeight
);

export const getTextFieldOpen = createSelector(
  [getLayoutState],
  state => state.textFieldOpen
);
