import React, { useEffect } from 'react';

import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  fetchGivingItems,
  updateDepartmentCode,
  updateDepartmentTitle,
  setFixedHeight,
} from '../../../store/embed/actions';
import { setURLData } from '../../../store/legacy/actions';
import {
  DEPARTMENT,
  DEPARTMENT_CODE,
  SKUS,
  FIXED_HEIGHT,
} from '../../../util/URLKeys';
import { unwrapDataFromURLParams } from './LegacyURLUnwrapper';

const mapDispatchToProps = {
  fetchGivingItems,
  updateDepartmentCode,
  updateDepartmentTitle,
  setFixedHeight,
};

const connector = connect(() => ({}), mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type IProps = ReduxProps & {
  children: React.ReactNode;
};

const EmbedQueryUnwrapperView = (props: IProps) => {
  const {
    fetchGivingItems,
    updateDepartmentCode,
    updateDepartmentTitle,
    setFixedHeight,
  } = props;
  const searchString = useLocation().search;
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(searchString);
    fetchGivingItems(unwrapSkusFromURLParams(queryParams));

    const departmentCode = unwrapDepartmentCodeFromURLParams(queryParams);
    if (departmentCode) {
      updateDepartmentCode(departmentCode);
    }

    const department = unwrapDepartmentFromURLParams(queryParams);
    if (department) {
      updateDepartmentTitle(department);
    }

    const fixedHeight = unwrapFixedHeightFromURLParams(queryParams);
    setFixedHeight(fixedHeight !== undefined);

    const urlData = unwrapDataFromURLParams(queryParams);
    dispatch(setURLData(urlData));
  }, [
    searchString,
    updateDepartmentCode,
    updateDepartmentTitle,
    fetchGivingItems,
    setFixedHeight,
    dispatch,
  ]);

  return <>{props.children}</>;
};

const unwrapSkusFromURLParams = (params: URLSearchParams) => {
  try {
    const skuData = params.get(SKUS);
    if (skuData) {
      const skus = skuData.split(',');
      return skus;
    }
  } catch (error) {
    onError(error);
  }
  return [];
};

const unwrapDepartmentCodeFromURLParams = (params: URLSearchParams) => {
  try {
    return params.get(DEPARTMENT_CODE) || undefined;
  } catch (error) {
    onError(error);
  }
};

const unwrapDepartmentFromURLParams = (params: URLSearchParams) => {
  try {
    return params.get(DEPARTMENT) || undefined;
  } catch (error) {
    onError(error);
  }
};

const unwrapFixedHeightFromURLParams = (params: URLSearchParams) => {
  try {
    return params.get(FIXED_HEIGHT) || undefined;
  } catch (error) {
    onError(error);
  }
};

const onError = (error: any) => {
  // tslint:disable-next-line:no-console
  console.error(error);
};

export default connector(EmbedQueryUnwrapperView);
