import React from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  GIFT_TYPE_PLEDGE,
  GIFT_TYPE_SINGLE,
} from '../../../../models/GiftType';
import { Routes } from '../../../../router/Routes';
import { RootState } from '../../../../store';
import { getGiftType } from '../../../../store/app/selectors';
import { getSerializedCartParams } from '../../../../store/cart/selectors';
import { previewCard, previewEmail } from '../../../../store/modal/actions';
import {
  resetFormData,
  setFormData,
  setFormValid,
} from '../../../../store/payment/actions';
import {
  getDefaultPaymentFormValues,
  getFormHonoreeSectionEnabled,
  getShowMissingFields,
} from '../../../../store/payment/selectors';
import { getTransactionProcessing } from '../../../../store/transaction/selectors';
import { DonorFields } from '../../../components/forms/DonorView/DonorFields';
import { useFooterWidthSetter } from '../../../effects/layout/FooterWidth';
import { GiftSummaryView } from '../../giftSummary/view/GiftSummaryView';
import { PledgeSummary } from '../../pledgeSummary/desktop/PledgeSummary';
import './CheckoutForm.scss';

const mapStateToProps = (state: RootState) => {
  return {
    defaultValues: getDefaultPaymentFormValues(state),
    giftType: getGiftType(state),
    transactionProcessing: getTransactionProcessing(state),
    serializedCart: getSerializedCartParams(state),
    honoreeSectionEnabled: getFormHonoreeSectionEnabled(state),
    showMissingFields: getShowMissingFields(state),
  };
};

const mapDispatchToProps = {
  setFormData,
  setFormValid,
  previewCard,
  previewEmail,
  resetFormData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type IProps = ReduxProps & {};

const createBackLink = (props: IProps) => {
  let name;
  let route;
  switch (props.giftType) {
    case GIFT_TYPE_SINGLE:
      name = 'Online Giving';
      route = Routes.Roots.OGP;
      break;
    case GIFT_TYPE_PLEDGE:
      name = 'PledgePay';
      route = Routes.Roots.Pledge;
  }

  return (
    <Link to={route.path} className='checkout-form__back-link pb--40'>
      Go back to {name}
    </Link>
  );
};

const CheckoutFormView = (props: IProps) => {
  const {
    giftType,
    setFormData,
    setFormValid,
    defaultValues,
    previewCard,
    previewEmail,
    honoreeSectionEnabled,
    showMissingFields,
  } = props;

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  });

  const formValues = form.watch();
  const stringifiedValues = JSON.stringify(formValues);
  const isValid = form.formState.isValid;

  React.useEffect(() => {
    setFormData(JSON.parse(stringifiedValues));
  }, [stringifiedValues, setFormData]);

  React.useEffect(() => {
    setFormValid(isValid);
  }, [isValid, setFormValid]);

  // Block manual submission of form
  const onFormSubmit = (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
  };

  const Summary = () => {
    switch (giftType) {
      case GIFT_TYPE_SINGLE:
        return <GiftSummaryView />;
      case GIFT_TYPE_PLEDGE:
        return <PledgeSummary checkoutPage />;
    }
  };

  const containerRef = React.useRef<HTMLDivElement>(null);
  useFooterWidthSetter(containerRef);

  return (
    <div className='checkout-form__container'>
      <form
        onSubmit={onFormSubmit}
        className='columns is-marginless'
        id='donor-form'
      >
        <div
          className='column checkout-form__donor-container'
          ref={containerRef}
        >
          {createBackLink(props)}
          <FormContext {...form}>
            <DonorFields
              onPreviewCard={previewCard}
              onPreviewEmail={previewEmail}
              honoreeSectionEnabled={honoreeSectionEnabled}
              defaultValues={defaultValues}
              showMissingFields={showMissingFields}
            />
          </FormContext>
        </div>

        <div className='column is-paddingless checkout-form__gift-summary-container'>
          <FormContext {...form}>{Summary()}</FormContext>
        </div>
      </form>
    </div>
  );
};

export const CheckoutForm = connector(CheckoutFormView);
