import { createSelector } from 'reselect';

import { RootState } from '../..';
import {
  builderIsValid,
  buildGiftUnsafe,
  IPledgeGiftBuilder,
} from '../../../models/GiftBuilder';
import { GIFT_TYPE_PLEDGE } from '../../../models/GiftType';
import { getPledgeFrequencyObject } from '../../../util/dataSets/frequencies';

const getCartState = (state: RootState) => state.cart;

const getSkusToBuilders = createSelector(
  [getCartState],
  state => state.builders.PLEDGE
);

export const getSharedAttributes = createSelector(
  [getCartState],
  state => state.attributes.PLEDGE
);

export const getBuilders = createSelector([getSkusToBuilders], skusToBuilders =>
  Object.values(skusToBuilders)
);

export const getRecurringAmount = createSelector(
  [getBuilders, getSharedAttributes],
  (items, attributes) => {
    if (!attributes.amount) {
      return;
    }
    return items.length * attributes.amount;
  }
);

export const getItemCount = createSelector(
  [getBuilders],
  builders => builders.length
);

export const getTotalPayment = createSelector(
  [getRecurringAmount, getSharedAttributes],
  (recurringAmount, attributes) => {
    const { frequency, durationInYears } = attributes;
    const frequencyObject = getPledgeFrequencyObject(frequency);
    if (!recurringAmount || !durationInYears || !frequencyObject) {
      return;
    }

    return recurringAmount * frequencyObject.paymentsPerYear * durationInYears;
  }
);

export const getFrequency = createSelector([getSharedAttributes], attributes =>
  getPledgeFrequencyObject(attributes.frequency)
);

export const getGivingItemWithSkuCreator = () =>
  createSelector([getBuilderWithSkuCreator()], getBuilder => (sku: string) => {
    return getBuilder(sku).givingItem;
  });

export const hasGivingItemWithSkuCreator = () =>
  createSelector([getSkusToBuilders], skusToBuilders => (sku: string) => {
    return sku in skusToBuilders;
  });

export const getBuilderWithSkuCreator = () =>
  createSelector(
    [getSkusToBuilders],
    skusToBuilders => (sku: string) => skusToBuilders[sku] as IPledgeGiftBuilder
  );

export const getNewPledgeIsValid = createSelector(
  [getBuilders, getSharedAttributes],
  (builders, pledgeAttributes) => {
    const attributes = { [GIFT_TYPE_PLEDGE]: pledgeAttributes };
    return builders.every(b => builderIsValid(b, attributes));
  }
);

export const getCompletedGifts = createSelector(
  [getBuilders, getSharedAttributes],
  (builders, pledgeAttributes) => {
    const attributes = { [GIFT_TYPE_PLEDGE]: pledgeAttributes };
    return builders
      .filter(b => builderIsValid(b, attributes))
      .map(b => buildGiftUnsafe(b, attributes));
  }
);

export const getSummaryVisible = createSelector(
  [getBuilders, getSharedAttributes],
  (builders, attributes) => {
    return builders.length && attributes.amount;
  }
);
