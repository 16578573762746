import { createSelector } from 'reselect';

import {
  GIFT_TYPE_SINGLE,
  GIFT_TYPE_PLEDGE,
  GiftType,
} from '../../models/GiftType';
import {
  getCompletedGifts as getSingleGifts,
  getBuilders as getSingleGiftBuilders,
} from './singleGift/selectors';
import {
  getCompletedGifts as getPledgeGifts,
  getBuilders as getPledgeGiftBuilders,
} from './pledge/selectors';
import { MAX_CART_SIZE } from './types';
import { RootState } from '..';
import { createCartURLParams } from '../../util/serialization/SerializeCart';

const getCartState = (state: RootState) => state.cart;

export const getSerializedCartParams = createSelector(getCartState, state =>
  createCartURLParams(state)
);

export const getAllCompletedGifts = createSelector(
  [getSingleGifts, getPledgeGifts],
  (singleGifts, pledgeGifts) => ({
    [GIFT_TYPE_SINGLE]: singleGifts,
    [GIFT_TYPE_PLEDGE]: pledgeGifts,
  })
);

export const getNumberOfGiftsForGiftTypeCreator = (giftType: GiftType) =>
  createSelector(
    [getAllBuilders],
    buildersDict => buildersDict[giftType].length
  );

const getAllBuilders = createSelector(
  [getSingleGiftBuilders, getPledgeGiftBuilders],
  (singleGiftBuilders, pledgeGiftBuilders) => ({
    [GIFT_TYPE_SINGLE]: Object.values(singleGiftBuilders),
    [GIFT_TYPE_PLEDGE]: Object.values(pledgeGiftBuilders),
  })
);

export const getCartIsFullForGiftTypeCreator = (giftType: GiftType) =>
  createSelector(
    [getAllBuilders],
    buildersDict => buildersDict[giftType].length >= MAX_CART_SIZE
  );
