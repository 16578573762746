import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setGiftTypePledge } from '../../../store/app/actions';
import { clearTransaction } from '../../../store/transaction/actions';
import { ResponsiveContainer } from '../../components/responsiveContainer/ResponsiveContainer';
import { PledgePageDesktop } from './PledgePageDesktop';
import { PledgePageMobile } from './PledgePageMobile';

const mapStateToProps = () => ({});
const mapDispatchToProps = { setGiftTypePledge, clearTransaction };

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const PledgePageView = (props: ReduxProps) => {
  const { setGiftTypePledge, clearTransaction } = props;

  React.useEffect(() => {
    setGiftTypePledge();
  }, [setGiftTypePledge]);

  React.useEffect(() => {
    clearTransaction();
  }, [clearTransaction]);

  const Container = ResponsiveContainer(PledgePageDesktop, PledgePageMobile);
  return <Container />;
};

export const PledgePage = connector(PledgePageView);
