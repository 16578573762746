import * as Types from './types';

export function previewEmail(dedicationTypeValue: string): Types.ActionTypes {
  return {
    type: Types.SET_MODAL,
    modal: {
      type: Types.MODAL_TYPE_PREVIEW_EMAIL,
      dedicationTypeValue,
    },
  };
}

export function previewCard(dedicationTypeValue: string): Types.ActionTypes {
  return {
    type: Types.SET_MODAL,
    modal: {
      type: Types.MODAL_TYPE_PREVIEW_CARD,
      dedicationTypeValue,
    },
  };
}

export function alert(title: string, message: string): Types.ActionTypes {
  return {
    type: Types.SET_MODAL,
    modal: {
      type: Types.MODAL_TYPE_ALERT,
      title,
      message,
    },
  };
}

export function clearModal(): Types.ActionTypes {
  return {
    type: Types.SET_MODAL,
    modal: undefined,
  };
}

export function setPaymentModalOpen(open: boolean): Types.ActionTypes {
  return {
    type: Types.SET_PAYMENT_MODAL_OPEN,
    open,
  };
}

export function tooltip(content?: React.ReactChild): Types.ActionTypes {
  return {
    type: Types.SET_MODAL,
    modal: {
      type: Types.MODAL_TYPE_TOOLTIP,
      content,
    },
  };
}
