import * as React from 'react';

import EmbedQueryUnwrapper from '../../wrappers/urlQueryUnwrapper/EmbedQueryUnwrapper';
import { EmbedView } from '../../containers/embed/EmbedView';
import { Switch, Route } from 'react-router';
import { PATH_EMBED, PATH_MINI_EMBED } from '../../../router/Routes';
import { MiniEmbedView } from '../../containers/miniEmbed/MiniEmbed';
import { MiniEmbedQueryUnwrapper } from '../../wrappers/urlQueryUnwrapper/MiniEmbedQueryUnwrapper';

export const EmbedLayout = () => {
  return (
    <div>
      <Switch>
        <Route exact path={PATH_EMBED}>
          <EmbedQueryUnwrapper>
            <EmbedView />
          </EmbedQueryUnwrapper>
        </Route>
        <Route exact path={PATH_MINI_EMBED}>
          <MiniEmbedQueryUnwrapper>
            <MiniEmbedView />
          </MiniEmbedQueryUnwrapper>
        </Route>
      </Switch>
    </div>
  );
};
