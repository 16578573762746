import * as Types from './types';

export const initialState: Types.IStore = {
  givingItems: {},
};

export function embedReducer(
  state = initialState,
  action: Types.ActionTypes
): Types.IStore {
  switch (action.type) {
    case Types.FETCH_GIVING_ITEMS:
      return { ...state, skus: action.skus };
    case Types.UPDATE_SKU_GIVING_ITEMS:
      return {
        ...state,
        givingItems: { ...state.givingItems, sku: action.items },
      };
    case Types.UPDATE_DEPARTMENT_GIVING_ITEMS:
      return {
        ...state,
        givingItems: { ...state.givingItems, department: action.items },
      };
    case Types.UPDATE_DEPARTMENT_CODE:
      return { ...state, departmentCode: action.departmentCode };
    case Types.UPDATE_DEPARTMENT_TITLE:
      return { ...state, departmentTitle: action.departmentTitle };
    case Types.SET_MESSAGE:
      return { ...state, message: action.message };
    case Types.CLEAR_MESSAGE:
      return { ...state, message: undefined };
    case Types.SET_FIXED_HEIGHT:
      return { ...state, fixedHeight: action.fixedHeight };
  }

  return state;
}
