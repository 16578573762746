export const TYPE_OUTSTANDING = 'OUTSTANDING';
export const TYPE_PER_PAYMENT = 'PER_PAYMENT';
export const TYPE_REMAINING = 'REMAINING';
export const TYPE_CUSTOM = 'CUSTOM';

export type IExistingPledgePaymentType =
  | typeof TYPE_OUTSTANDING
  | typeof TYPE_PER_PAYMENT
  | typeof TYPE_REMAINING
  | typeof TYPE_CUSTOM;
