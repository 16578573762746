import React from 'react';

const loadWrapper: (onError?: OnErrorEventHandler) => React.EffectCallback =
  (onError?: OnErrorEventHandler) => () => {
    const script = document.createElement('script');

    script.src = process.env.REACT_APP_WRAPPER_URL || '';
    script.async = true;
    script.noModule = true;
    script.type = 'application/javascript';
    script.onerror = onError || null;

    document.body.appendChild(script);

    const moduleScript = document.createElement('script');

    moduleScript.src = process.env.REACT_APP_WRAPPER_MODULE_URL || '';
    moduleScript.async = true;
    moduleScript.type = 'module';
    moduleScript.onerror = onError || null;

    document.body.appendChild(moduleScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(moduleScript);
    };
  };

export default { loadWrapper };
