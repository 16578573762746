import React from 'react';

import { ISharedInputProps } from '../textInput/InputWrapper';
import {
  useTextFieldOpenListener,
  ITextFieldEventProps,
} from '../../wrappers/InputOpenListener';
import './TextArea.scss';

const Label = (label?: string, id?: string) =>
  label ? (
    <label className='ogp-textarea__label' htmlFor={id}>
      {label}
    </label>
  ) : null;

export const ErrorMessage = (error?: string) =>
  error ? (
    <span className='ogp-textarea__error-msg' role='alert'>
      {error}
    </span>
  ) : null;

const CharacterCount = (currentCount: number, props: IProps) => {
  const baseClassName = 'ogp-textarea__character-count';
  const countOnTopClass = props.countOnTop ? `${baseClassName}--on-top` : '';

  return props.maxLength ? (
    <div className={`${baseClassName} ${countOnTopClass}`}>
      {currentCount}/{props.maxLength}
    </div>
  ) : null;
};

export const TextArea = (props: IProps) => {
  const { rows = 3 } = props;

  const [characterCount, setCharacterCount] = React.useState(0);

  const focusEventProps = useTextFieldOpenListener(props);

  const hasError = props.error ? true : false;
  const errorClass = hasError ? 'ogp-textarea__field--error' : '';

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    setCharacterCount(value.length);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <div className={`ogp-textarea ${props.className || ''}`}>
      {Label(props.label, props.id)}
      <textarea
        {...focusEventProps}
        className={`ogp-textarea__field ${errorClass}`}
        name={props.name}
        rows={rows}
        value={props.value}
        maxLength={props.maxLength}
        onChange={onChange}
        placeholder={props.placeholder}
      ></textarea>
      {CharacterCount(characterCount, props)}
      {ErrorMessage(props.error)}
    </div>
  );
};

export type IProps = ISharedInputProps &
  ITextFieldEventProps & {
    name?: string;
    value?: string;
    maxLength?: number;
    onChange?: (value: string) => void;
    countOnTop?: boolean;

    /**
     * @default 3
     */
    rows?: number;
  };
