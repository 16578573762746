import Downshift from 'downshift';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  buildAddress,
  ISuggestion,
} from '../../../models/smartyStreets/Suggestion';
import { RootState } from '../../../store';
import { setQuery, setSelected } from '../../../store/address/actions';
import * as addressSelectors from '../../../store/address/selectors';
import { classes } from '../../components/dropdown/Dropdown';
import { ISharedInputProps } from '../../components/textInput/InputWrapper';
import { useTextFieldOpenListener } from '../../wrappers/InputOpenListener';

const mapStateToProps = (state: RootState, ownProps: IProps) => {
  const getSuggestionsForKey =
    addressSelectors.getSuggestionsForAddressKeyCreator()(state);
  const getSelectedSuggestionForKey =
    addressSelectors.getSelectedSuggestionForAddressKeyCreator()(state);

  return {
    suggestions: getSuggestionsForKey(ownProps.addressKey),
    selectedSuggestion: getSelectedSuggestionForKey(ownProps.addressKey),
  };
};

const mapDispatchToProps = {
  setQuery,
  setSelected,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const AddressLookupFieldView = (props: IProps & ReduxProps) => {
  const [changedByKeyboard, setChangedByKeyboard] = React.useState(false);
  const [keyboardEntry, setKeyboardEntry] = React.useState('');

  const inputEventProps = useTextFieldOpenListener(props);

  const items = (props.autocompleteEnabled && props.suggestions) || [];
  const inputValue = changedByKeyboard
    ? keyboardEntry
    : props.selectedSuggestion?.street_line;

  const itemToInputValue = (item: ISuggestion | null) =>
    item?.street_line || '';

  const onKeyboardInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const targetValue = event.target.value;

    setKeyboardEntry(targetValue);
    setChangedByKeyboard(true);

    if (props.onChange) {
      props.onChange(targetValue);
    }

    if (props.autocompleteEnabled) {
      props.setQuery(props.addressKey, targetValue);
    }
  };

  const propsClassName = props.className || '';

  return (
    <Downshift
      onSelect={item => {
        if (item) {
          if (!props.autocompleteEnabled) {
            return;
          }

          props.setSelected(props.addressKey, item);
          setChangedByKeyboard(false);

          if (props.onChange) {
            props.onChange(itemToInputValue(item));
          }

          if (props.onSelectSuggestion) {
            props.onSelectSuggestion(item);
          }
        }
      }}
      itemToString={itemToInputValue}
      inputValue={inputValue || ''}
    >
      {({
        getLabelProps,
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        highlightedIndex,
        selectedItem,
      }) => {
        const styleAsOpen = isOpen && props.suggestions?.length;

        const menuOpenClass = styleAsOpen ? classes.menuOpen : '';
        const menuEmptyClass = props.suggestions?.length
          ? ''
          : classes.menuEmpty;

        const inputOpenClass = styleAsOpen ? classes.inputOpen : '';
        const inputErrorClass = props.error ? classes.inputError : '';
        const inputMissingClass =
          props.missing && (!props.valid || props.error !== undefined)
            ? classes.inputMissing
            : '';
        const containerErrorClass = props.error
          ? classes.inputConainerError
          : '';
        const containerValidClass = props.valid
          ? classes.inputContainerVaild
          : '';
        const containerRequiredClass = props.required
          ? classes.inputContainerRequired
          : '';

        return (
          <div className={`${classes.root} ${propsClassName}`}>
            <label {...getLabelProps()} className={classes.label}>
              Address
            </label>
            <div
              className={`${classes.inputContainer} ${containerErrorClass} ${containerValidClass} ${containerRequiredClass}`}
            >
              <input
                {...getInputProps({
                  ...inputEventProps,
                  className: `${classes.input} ${inputOpenClass} ${inputErrorClass} ${inputMissingClass}`,
                  onChange: onKeyboardInputChange,
                  name: props.name,
                  placeholder: props.placeholder,
                })}
              />
            </div>
            <div className={classes.menuContainer}>
              <ul
                {...getMenuProps()}
                className={`${classes.menu} ${menuOpenClass} ${menuEmptyClass}`}
              >
                {isOpen
                  ? items.map((item, index) => {
                      const selected =
                        selectedItem === item ? classes.itemSelected : '';
                      const highlighted =
                        highlightedIndex === index
                          ? classes.itemHighlighted
                          : '';
                      return (
                        <li
                          className={`${classes.item} ${highlighted} ${selected}`}
                          {...getItemProps({
                            key: buildAddress(item),
                            index,
                            item,
                          })}
                        >
                          {item.street_line}, {item.city} {item.state}{' '}
                          {item.zipcode}
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
        );
      }}
    </Downshift>
  );
};

export const AddressLookupField = connector(AddressLookupFieldView);

export type IProps = ISharedInputProps & {
  name?: string;

  autocompleteEnabled?: boolean;
  addressKey?: string; // Used for autocomplete, see store/address/types.ts

  onChange?: (key: string) => void;
  onSelectSuggestion?: (suggestion: ISuggestion) => void;
  onBlur?: (event: any) => void;
};
