import { IGivingItem } from '../../models/GivingItem';

export interface IStore {
  // Parsed from query params
  dark?: boolean;
  sku?: string;
  amountButtons?: number[];

  givingItem?: IGivingItem;
  amount?: number;
}

export const SET_DARK = 'SET_DARK';
export const SET_SKU = 'SET_SKU';
export const SET_AMOUNT_BUTTONS = 'SET_AMOUNT_BUTTONS';
export const SET_GIVING_ITEM = 'SET_GIVING_ITEM';
export const SET_AMOUNT = 'SET_AMOUNT';

interface ISetDark {
  type: typeof SET_DARK;
  dark?: boolean;
}

interface ISetSku {
  type: typeof SET_SKU;
  sku?: string;
}

interface ISetAmountButtons {
  type: typeof SET_AMOUNT_BUTTONS;
  amountButtons?: number[];
}

interface ISetGivingItem {
  type: typeof SET_GIVING_ITEM;
  givingItem?: IGivingItem;
}

interface ISetAmount {
  type: typeof SET_AMOUNT;
  amount?: number;
}

export type ActionTypes =
  | ISetDark
  | ISetSku
  | ISetGivingItem
  | ISetAmountButtons
  | ISetAmount;
