import * as React from 'react';

import { Route } from 'react-router-dom';

import { CheckoutPage } from '../checkout/desktop/CheckoutPage';
import { OGPHomePage } from '../home/ogp/OGPHomePage';

import { Routes } from '../../../router/Routes';

const basePath = Routes.Roots.OGP.path;

export const OGPPageDesktop = () => {
  return (
    <React.Fragment>
      <Route path={basePath + Routes.Sections.Checkout.path}>
        <CheckoutPage />
      </Route>
      <Route
        exact
        path={[
          basePath + Routes.Sections.Frequency.path,
          basePath + Routes.Sections.Amount.path,
          basePath + Routes.Sections.Search.path,
          basePath + Routes.SearchArea.path,
        ]}
      >
        <OGPHomePage />
      </Route>
    </React.Fragment>
  );
};
