import * as React from 'react';
import { ISharedInputProps } from '../textInput/InputWrapper';
import './Radio.scss';

type OnOptionSelected = (item: IRadioOption) => void;
const RenderOption = (
  item: IRadioOption,
  onSelect: OnOptionSelected,
  props: IProps,
  selectedValue?: string
) => {
  const isSelected = item.value === selectedValue;
  const labelSelectedClass = isSelected ? 'ogp-radio__label--selected' : '';
  const brandColorClass = props.brandColor
    ? 'ogp-radio__label--brand-color'
    : '';
  const hideLabelClass = props.styleAsButtons ? 'ogp-radio__label--hide' : '';
  const buttonContainerClass = props.styleAsButtons
    ? 'ogp-radio__option--button-container'
    : '';
  const buttonActiveClass = isSelected ? 'active' : '';

  return (
    <li
      className={`ogp-radio__option ${buttonContainerClass}`}
      key={`${item.value}`}
      onClick={() => onSelect(item)}
    >
      <input
        className='ogp-radio__input'
        name={props.name}
        id={`${props.name}-${item.value}`}
        value={`${item.value}`}
        disabled={item.disabled}
        type='radio'
      ></input>
      <label
        className={`ogp-radio__label  ${brandColorClass} ${labelSelectedClass} ${hideLabelClass}`}
        htmlFor={`${item.value}`}
      >
        {item.label}
      </label>
      {props.styleAsButtons ? (
        <div
          className={`ut-btn--secondary btn--small corner--pill ogp-radio__button ${buttonActiveClass}`}
        >
          {item.label}
        </div>
      ) : null}
    </li>
  );
};

export const Radio = (props: IProps) => {
  const className = props.className || '';
  const [selectedValue, setSelectedValue] = React.useState<string | undefined>(
    props.value
  );
  const styleAsButtonClass = props.styleAsButtons
    ? 'ogp-radio--style-buttons'
    : '';

  const singleLineClass = props.singleLine ? 'ogp-radio--single-line' : '';

  React.useEffect(() => setSelectedValue(props.value), [props.value]);

  const onSelect = (item: IRadioOption) => {
    setSelectedValue(item.value);
    if (props.onChange) {
      props.onChange(item.value);
    }
  };

  return (
    <ul
      className={`ogp-radio ${styleAsButtonClass} ${singleLineClass} ${className}`}
    >
      {props.options.map(item =>
        RenderOption(item, onSelect, props, selectedValue)
      )}
    </ul>
  );
};

export interface IRadioOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export type IProps = ISharedInputProps & {
  options: IRadioOption[];
  onChange?: (value: string) => void;
  value?: any;
  name?: string;
  brandColor?: boolean;
  styleAsButtons?: boolean;
  singleLine?: boolean;
};
