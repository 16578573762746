import React from 'react';

import { IPledge } from '../models/Pledge';

import { getPledgeFrequencyObject } from './dataSets/frequencies';

const milliSecondsInDay = 24 * 60 * 60 * 1000;
export const pledgeIsOverdue: (p: IPledge) => boolean = (pledge: IPledge) => {
  const dueDate = new Date(pledge.dateDue);
  const dayAfterDue = new Date(dueDate.getTime() + milliSecondsInDay);
  const today = new Date();

  return today.getTime() >= dayAfterDue.getTime();
};

export const getEarliestDuePledge = (pledges: IPledge[]) => {
  let minTime = Infinity;
  let minPledge: IPledge | undefined;

  pledges.forEach(pledge => {
    const dueDate = new Date(pledge.dateDue);
    const dueTime = dueDate.getTime();

    if (dueTime < minTime) {
      minPledge = pledge;
      minTime = dueTime;
    }
  });

  return minPledge;
};

export const formatDate = (dateString?: string) => {
  if (!dateString) return dateString;

  const date = new Date(dateString);
  return date.toLocaleDateString();
};

export const formatPledgeFrequencyString = (
  durationInYears: number,
  frequencyKey?: string,
  addBoldTags?: boolean
) => {
  const frequency = getPledgeFrequencyObject(frequencyKey);
  if (!frequency) {
    return;
  }

  const yearsString = durationInYears === 1 ? 'year' : 'years';

  const tryWrapBold = (str?: string) => {
    if (!str || !addBoldTags) {
      return str;
    }

    return <b>{str}</b>;
  };

  return (
    <React.Fragment>
      {frequency?.label || ''} for {tryWrapBold(`${durationInYears}`)}{' '}
      {yearsString}
    </React.Fragment>
  );
};
