import * as Types from './types';

const initialState: Types.IStore = {
  lookups: {},
};

function withAddressType(addressKey: string, state: Types.IStore) {
  return {
    addressKey,
    lookup: state.lookups[addressKey] || {},
  };
}

export function addressReducer(state = initialState, action: Types.ActionType) {
  switch (action.type) {
    case Types.SET_QUERY: {
      const { addressKey, lookup } = withAddressType(action.addressKey, state);
      return {
        ...state,
        lookups: {
          ...state.lookups,
          [addressKey]: {
            ...lookup,
            query: action.query,
          },
        },
      };
    }
    case Types.SET_SUGGESTIONS: {
      const { addressKey, lookup } = withAddressType(action.addressKey, state);

      return {
        ...state,
        lookups: {
          ...state.lookups,
          [addressKey]: {
            ...lookup,
            suggestions: action.suggestions,
          },
        },
      };
    }
    case Types.SET_SELECTED: {
      const { addressKey, lookup } = withAddressType(action.addressKey, state);
      return {
        ...state,
        lookups: {
          ...state.lookups,
          [addressKey]: { ...lookup, selectedSuggestion: action.selected },
        },
      };
    }
    case Types.SET_VERIFIED: {
      const { addressKey, lookup } = withAddressType(action.addressKey, state);
      return {
        ...state,
        lookups: {
          ...state.lookups,
          [addressKey]: {
            ...lookup,
            verified: action.verified,
          },
        },
      };
    }
  }

  return state;
}
