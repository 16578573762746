import useComponentSize from '@rehooks/component-size';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/layout/actions';
import * as selectors from '../../../store/layout/selectors';

export function useFooterWidth(): number {
  const footerWidth = useSelector(selectors.getFooterWidth);

  return footerWidth;
}
export function useFooterWidthSetter<T = any>(ref: React.RefObject<T>): void {
  const dispatch = useDispatch();

  const currentWidth = useSelector(selectors.getFooterWidth);
  const { width } = useComponentSize(ref);

  useEffect(() => {
    if (width !== currentWidth) {
      dispatch(actions.setFooterWidth(width));
    }
  }, [width, currentWidth, dispatch]);
}
