export const DEPARTMENT = 'department';
export const DEPARTMENT_CODE = 'dept';
export const CART = 'cart';
export const SKUS = 'skus';

export const SKU = 'sku';
export const AMOUNT_BUTTONS = 'amounts';
export const DARK = 'dark';

export const REFERRER = 'referrer';

// If set, the two pages of the embeddable widget will size to the largest one, so as not to push content down when changing pages
// See EmbedAmountView.tsx, EmbedQueryUnwrapper.tsx
export const FIXED_HEIGHT = 'fixed';

// These are used alongside LegacyKeys as part of gift codings
export const SOLICIT_YEAR = 'solicityear';
export const MAIL_CODE = 'mailcode';

export const LegacyKeys = {
  MENU: 'menu',
  MENU1: 'menu1',
  AMOUNT: 'amount',
  EID: 'eid',
  FREQUENCY: 'frequency',
  DURATION: 'duration',
  COMMENTS: 'comments',
  RECOG: 'recog',
  RECOG_NAME: 'recogname',
  PLEDGE_ID: 'pledgeid',

  // CODINGS
  CODE_SOLICIT: 'solicit',
  CODE_PROGRAM: 'program',
  CODE_SRCCSU: 'srccsu',
  CODE_SRCSUB: 'srcsub',
  CODE_SOURCE: 'source',
  CODE_ENDOWMENT: 'endowment',
  CODE_RESPONSE: 'response',
};
