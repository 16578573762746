import './PledgeView.scss';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../../store';
import * as establishPledgeActions from '../../../store/cart/pledge/actions';
import * as cartSelectors from '../../../store/cart/pledge/selectors';
import {
  setExistingPledgeComments,
  setExistingPledgeCustomPaymentAmount,
  setExistingPledgeEnableAutopay,
  setExistingPledgeID,
  setExistingPledgeIDKnown,
  setExistingPledgeIsLoading,
  setExistingPledgeNoIDPaymentAmount,
  setExistingPledgePaymentType,
  togglePaymentAmount,
  clearExistingPerPayments,
  clearPledge,
  setPledgeType,
} from '../../../store/pledge/actions';
import * as existingPledgeSelectors from '../../../store/pledge/existingPledge/selectors';
import * as pledgeSelectors from '../../../store/pledge/selectors';
import { PledgePaymentView } from './pledgePayment/PledgePaymentView';

const mapStateToProps = (state: RootState) => {
  const summaryIsVisibleCreator = pledgeSelectors.getSummaryVisibleCreator();
  const amountForPledgeIDCreator =
    existingPledgeSelectors.getCustomAmountForPledgeIDCreator();
  const customAmountValidForPledgeIDCreator =
    existingPledgeSelectors.getCustomAmountIsValidForPledgeIDCreator();

  return {
    pledgeType: pledgeSelectors.getPledgeType(state),
    summaryIsVisible: summaryIsVisibleCreator(state),

    establishPledgeBuilders: cartSelectors.getBuilders(state),
    establishPledgeAttributes: cartSelectors.getSharedAttributes(state),
    establishPledgeTotalAmount: cartSelectors.getTotalPayment(state),

    existingPledgeID: existingPledgeSelectors.getPledgeID(state),
    existingPledgeIDKnown: existingPledgeSelectors.getIDKnown(state),
    existingPledges: existingPledgeSelectors.getPledges(state),
    existingPledgeComments: existingPledgeSelectors.getComments(state),
    existingPledgeIsLoading: existingPledgeSelectors.getIsLoading(state),
    existingPledgePaymentType: existingPledgeSelectors.getPaymentType(state),
    existingPledgeNoIDPaymentAmount:
      existingPledgeSelectors.getNoIDPaymentAmount(state),
    existingPledgeAutopayPossible:
      pledgeSelectors.getPledgeAutopayPossible(state),
    existingPledgeAutopayEnabled:
      existingPledgeSelectors.getAutopayEnabled(state),
    existingPledgeOutstandingAmount:
      existingPledgeSelectors.getOutstandingAmount(state),
    existingPledgeRemainingAmount:
      existingPledgeSelectors.getRemainingAmount(state),
    existingPledgeCustomAmountTotal:
      existingPledgeSelectors.getCustomAmountTotal(state),
    existingPledgePaymentValid:
      existingPledgeSelectors.paymentValidForKnownID(state),
    selectedPayments: existingPledgeSelectors.getGiftsForPerPayment(state),
    customAmountForExistingPledgeID: amountForPledgeIDCreator(state),
    customAmountValidForExistingPledgeID:
      customAmountValidForPledgeIDCreator(state),
  };
};

const mapDispatchToProps = {
  setPledgeType,
  setExistingPledgeComments,
  setExistingPledgeIsLoading,
  setExistingPledgeID,
  setExistingPledgeIDKnown,
  setExistingPledgeNoIDPaymentAmount,
  setExistingPledgeEnableAutopay,
  setExistingPledgePaymentType,
  togglePaymentAmount,
  clearExistingPerPayments,
  setExistingPledgeCustomPaymentAmount,
  clearPledge,
  setEstablishPledgeGiftAmount: establishPledgeActions.updateAmount,
  setEstablishPledgeGiftDuration: establishPledgeActions.updateDuration,
  setEstablishPledgeGiftFrequency: establishPledgeActions.updateFrequency,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export type IProps = ReduxProps & {
  scrollToPaymentAmountButtons?: boolean;
  // Extra props here
};

const PledgeViewComponent = (props: IProps) => {
  return (
    <div>
      <h3 className='ut-h3 pb--20'>Your Pledge to UT</h3>
      <p className='pb--40'>
        {`Pledges allow you to show support to UT now and pay your gift over time.
        Need help? Contact us at `}
        <a href='mailto:pledges@austin.utexas.edu' className='ut-link--cta'>
          pledges@austin.utexas.edu
        </a>
        .
      </p>
      <PledgePaymentView {...props} />{' '}
    </div>
  );
};

export const PledgeView = connector(PledgeViewComponent);
