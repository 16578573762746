import './WebLayout.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import { Routes } from '../../../router/Routes';
import { RootState } from '../../../store';
import { Footer } from '../../components/footer/footer';
import { Header } from '../../components/header/header';
import { Navigation } from '../../components/header/navigation';
import ScrollToTop from '../../components/scrollToTop/ScrollToTop';
import { Sidebar } from '../../components/sidebar/sidebar';
import { ModalPresenter } from '../../containers/modal/ModalPresenter';
import { useFooterWidth } from '../../effects/layout/FooterWidth';
import useScreenWidth, { ViewType } from '../../effects/layout/ScreenWidth';
import { ConfirmationPage } from '../../pages/confirmation/ConfirmationPage';
import { OGPPage } from '../../pages/ogp/OGPPage';
import { PledgePage } from '../../pages/pledge/PledgePage';
import { WindowTo404Redirect } from '../../router/redirects/WindowTo404Redirect';

const WebLayoutView = () => {
  const appElement = React.useRef<HTMLDivElement>(null);

  const footerWidth = useFooterWidth();
  const [sidebarVisible, updateSidebarVisible] = React.useState(false);

  const toggleSidebar = () => {
    updateSidebarVisible(!sidebarVisible);
  };

  const getSubPages = (basePage: string): string[] => {
    return [
      basePage,
      basePage + Routes.Sections.Checkout.path,
      basePage + Routes.Sections.Frequency.path,
      basePage + Routes.Sections.Amount.path,
      basePage + Routes.Sections.Search.path,
      basePage + Routes.SearchArea.path,
    ];
  };

  const location = useLocation();
  const screenWidth = useScreenWidth();

  const isDesktop = screenWidth === ViewType.Desktop;
  const isCheckout = location.pathname.includes(Routes.Sections.Checkout.path);

  const hideHeader = isDesktop && isCheckout;

  return (
    <div className='ogp-web-layout' ref={appElement}>
      <ModalPresenter
        htmlOpenClassName='html--lock-scroll'
        appElement={appElement}
      />
      <ScrollToTop>
        <div
          className={'ogp-web-layout' + (sidebarVisible ? ' sidebar-open' : '')}
        >
          <Navigation
            onToggleSidebar={toggleSidebar}
            sidebarVisible={sidebarVisible}
            forceScrolledState={hideHeader}
          />
          <Header hidden={hideHeader} />
          <div className='ogp-app-container'>
            <Switch>
              <Route exact path={getSubPages(Routes.Roots.OGP.path)}>
                <OGPPage />
              </Route>

              <Route exact path={Routes.Roots.Confirmation.path}>
                <ConfirmationPage />
              </Route>

              <Route exact path={getSubPages(Routes.Roots.Pledge.path)}>
                <PledgePage />
              </Route>

              <Route path='*'>
                <WindowTo404Redirect />
              </Route>
            </Switch>
          </div>
        </div>
      </ScrollToTop>
      <Sidebar sidebarVisible={sidebarVisible} />

      <Footer sidebarVisible={sidebarVisible} width={footerWidth} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => state;
export const WebLayout = connect(mapStateToProps)(WebLayoutView);
