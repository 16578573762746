import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { ISuggestion } from '../../../../models/smartyStreets/Suggestion';
import { RootState } from '../../../../store';
import * as addressSelectors from '../../../../store/address/selectors';
import {
  countriesAsOptions,
  defaultProvinceForCountryValue,
  unitedStates,
} from '../../../../util/dataSets/countries';
import {
  provinceLabelForCountry,
  provincesForCountry,
} from '../../../../util/dataSets/provinces';
import { CheckoutField } from '../../../../util/fieldNames/CheckoutFieldNames';
import {
  IInputFieldGroupProps,
  InputFieldGroup,
} from '../../../wrappers/InputFieldGroup';
import {
  HookFormAddressLookupField,
  HookFormDropdown,
  HookFormTextInput,
} from '../../textInput/HookFormWrapper';
import { LineBreak } from '../DonorView/DonorFields';
import { createEffects } from './util/AddressFieldsEffects';

const defaultCountry = unitedStates?.value;

const mapStateToProps = (state: RootState, ownProps: IAddressFieldsProps) => {
  const getPrefilledValuesForKey =
    addressSelectors.getPrefilledFormValuesForAddressKeyCreator()(state);
  return {
    prefilledFormValues: getPrefilledValuesForKey(ownProps.addressLabelPrefix),
  };
};

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export type IProps = IAddressFieldsProps & ReduxProps & IInputFieldGroupProps;
export interface IAddressFieldsProps {
  addressLabelPrefix?: string;
  showMissing: boolean;
  line1: CheckoutField;
  line2: CheckoutField;
  city: CheckoutField;
  stateOrProvince: CheckoutField;
  postalCode: CheckoutField;
  country: CheckoutField;
}

const AddressFieldsView = InputFieldGroup((props: IProps) => {
  const {
    addressLabelPrefix,
    showMissing,
    line1,
    line2,
    city,
    stateOrProvince,
    postalCode,
    country,
    prefilledFormValues,
  } = props;

  const form = useFormContext();
  const { watch, setValue, triggerValidation } = form;

  const getAddressLabelWithPrefix = (label: string) =>
    addressLabelPrefix?.length ? `${addressLabelPrefix} ${label}` : label;

  const countryName = watch(country.name);
  const countryIsUnitedStates = countryName === unitedStates?.value;

  const provinceOptions = provincesForCountry(countryName) || [];
  const showProvinceField = provinceOptions.length > 0;

  const provinceLabel = provinceLabelForCountry(countryName);
  const zipCodeLabel =
    countryName === 'United States' ? 'Zip Code' : 'Postal Code';

  const state = watch(stateOrProvince.name);

  const stateObject = { value: state, name: state };

  const setCountry = (name: string) => {
    setValue(country.name, name, true);
  };

  const onAutocompleteSuggestionSelect = (address: ISuggestion) => {
    setValue(city.name, address.city, true);
    setValue(stateOrProvince.name, address.state, true);
    setValue(postalCode.name, address.zipcode, true);
  };

  const updateStateLabel = () => {
    setValue(
      stateOrProvince.name,
      defaultProvinceForCountryValue(countryName) || '',
      true
    );
  };

  const effects = createEffects(props, form);
  useEffect(effects.registerCountry, []);
  useEffect(updateStateLabel, [stateOrProvince.name, countryName, setValue]);
  useEffect(effects.fillAutocompleteValues, [
    JSON.stringify(prefilledFormValues),
  ]);

  return (
    <>
      {props.injectControlledInput({
        as: HookFormAddressLookupField,
        nameOrSuffix: line1.name,
        required: line1.required,
        rules: { required: line1.required },
        missing: showMissing,
        className: 'column is-full',
        label: getAddressLabelWithPrefix('Address'),
        placeholder: 'Address',
        addressKey: addressLabelPrefix,
        onSelectSuggestion: onAutocompleteSuggestionSelect,
        autocompleteEnabled: countryIsUnitedStates,
      })}
      {props.injectControlledInput({
        as: HookFormTextInput,
        nameOrSuffix: line2.name,
        className: 'column is-full',
        label: 'Apartment, suite, etc. (Optional)',
        placeholder: 'Apartment, suite, etc. (Optional)',
      })}
      {props.injectControlledInput({
        as: HookFormTextInput,
        nameOrSuffix: city.name,
        required: city.required,
        missing: showMissing,
        rules: { required: city.required },
        className: 'column is-full',
        label: 'City',
        placeholder: 'City',
      })}
      {
        <HookFormDropdown
          name={country.name}
          className='column is-one-third-fullhd is-two-thirds'
          label='Country/Region'
          placeholder='Country'
          errors={props.errors}
          onBlur={_ => triggerValidation(country.name)}
          options={countriesAsOptions}
          onChange={setCountry}
          initialValue={defaultCountry}
        />
      }
      {showProvinceField
        ? props.injectControlledInput({
            as: HookFormDropdown,
            nameOrSuffix: stateOrProvince.name,
            className: 'column',
            placeholder: provinceLabel,
            label: provinceLabel,
            options: provinceOptions,
            rules: { required: stateOrProvince.required },
            missing: props.showMissing,
            required: stateOrProvince.required,
            value: stateObject,
          })
        : null}
      {props.injectControlledInput({
        as: HookFormTextInput,
        nameOrSuffix: postalCode.name,
        rules: { required: postalCode.required },
        required: postalCode.required,
        missing: showMissing,
        className: 'column is-narrow',
        label: zipCodeLabel,
        placeholder: zipCodeLabel,
      })}

      <LineBreak />
    </>
  );
});

export const AddressFields = connector(AddressFieldsView);
