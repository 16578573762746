import { yes, no } from '../../../assets/values/yesNo.json';

export const companyMatchingOptions = [
  no,
  { ...yes, label: 'Yes, this gift qualifies for a company match' },
];

export const isOrganizationOptions = [
  { ...no, label: 'Individual' },
  { ...yes, label: 'Organization' },
];

export const billingIsShippingOptions = [
  { ...yes, label: 'Same as the address above' },
  { ...no, label: 'Different billing address' },
];
