export const CART_EXPIRATION_TIME_MS = 1000 * 60 * 60 * 24;

export interface IExpirationState {
  cartTimeStamp?: Date;
}

export const SET_CART_TIME_STAMP = 'SET_CART_TIME_STAMP';

interface ISetCartTimeStamp {
  type: typeof SET_CART_TIME_STAMP;
  timeStamp?: Date;
}

export type ActionType = ISetCartTimeStamp;
