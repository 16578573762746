export interface ILegacyURLData {
  menu: string;
  amount?: string;
  eid?: string;
  frequency?: 'MONTHLY' | 'ANNUAL';
  duration?: number;
  comments?: string;
  recog?: 'MEM' | 'HON';
  recogname?: string;
  pledgeid?: string;

  codings?: { [key: string]: string };
}

export const skuForMenu = (menu: string) => `${menu}`;
