import * as Types from './types';
import { GIFT_TYPE_SINGLE } from '../../models/GiftType';

export const initialState: Types.IAppState = {
  giftType: GIFT_TYPE_SINGLE,
  footerWidth: 0.0,
};

export function appReducer(
  state = initialState,
  action: Types.ActionType
): Types.IAppState {
  switch (action.type) {
    case Types.SET_GIFT_TYPE:
      return { ...state, giftType: action.giftType };
  }

  return state;
}
