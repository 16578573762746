import { IGivingItem } from '../../models/GivingItem';

export interface IEmbedMessage {
  title?: string;
  message: any;
}

export interface IStore {
  givingItems: {
    sku?: IGivingItem[];
    department?: IGivingItem[];
  };

  departmentCode?: string;

  departmentTitle?: string;
  skus?: string[];

  message?: IEmbedMessage;

  fixedHeight?: boolean;
}

export const FETCH_GIVING_ITEMS = 'FETCH_GIVING_ITEMS';
export const UPDATE_SKU_GIVING_ITEMS = 'UPDATE_GIVING_ITEMS';
export const UPDATE_DEPARTMENT_GIVING_ITEMS = 'UPDATE_DEPARTMENT_GIVING_ITEMS';
export const UPDATE_DEPARTMENT_CODE = 'UPDATE_DEPARTMENT_CODE';
export const UPDATE_DEPARTMENT_TITLE = 'UPDATE_DEPARTMENT_TITLE';
export const SET_FIXED_HEIGHT = 'SET_FIXED_HEIGHT';
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

interface IFetchGivingItem {
  type: typeof FETCH_GIVING_ITEMS;
  skus: string[];
}

interface IUpdateSkuGivingItems {
  type: typeof UPDATE_SKU_GIVING_ITEMS;
  items: IGivingItem[];
}

interface IUpdateDepartmentGivingItems {
  type: typeof UPDATE_DEPARTMENT_GIVING_ITEMS;
  items: IGivingItem[];
}

interface IUpdateDepartmentCode {
  type: typeof UPDATE_DEPARTMENT_CODE;
  departmentCode: string;
}

interface IUpdateDepartmentTitle {
  type: typeof UPDATE_DEPARTMENT_TITLE;
  departmentTitle: string;
}

interface ISetMessage {
  type: typeof SET_MESSAGE;
  message: IEmbedMessage;
}

interface ISetFixedHeight {
  type: typeof SET_FIXED_HEIGHT;
  fixedHeight: boolean;
}

interface IClearMessage {
  type: typeof CLEAR_MESSAGE;
}

export type ActionTypes =
  | IFetchGivingItem
  | IUpdateSkuGivingItems
  | IUpdateDepartmentGivingItems
  | IUpdateDepartmentCode
  | IUpdateDepartmentTitle
  | ISetMessage
  | IClearMessage
  | ISetFixedHeight;
