import * as Types from './types';
import { IGivingItem } from '../../models/GivingItem';

export function fetchGivingItems(skus: string[]): Types.ActionTypes {
  return {
    type: Types.FETCH_GIVING_ITEMS,
    skus,
  };
}

export function updateSkuGivingItems(items: IGivingItem[]): Types.ActionTypes {
  return {
    type: Types.UPDATE_SKU_GIVING_ITEMS,
    items,
  };
}

export function updateDepartmentGivingItems(
  items: IGivingItem[]
): Types.ActionTypes {
  return {
    type: Types.UPDATE_DEPARTMENT_GIVING_ITEMS,
    items,
  };
}

export function updateDepartmentCode(
  departmentCode: string
): Types.ActionTypes {
  return {
    type: Types.UPDATE_DEPARTMENT_CODE,
    departmentCode,
  };
}

export function updateDepartmentTitle(
  departmentTitle: string
): Types.ActionTypes {
  return {
    type: Types.UPDATE_DEPARTMENT_TITLE,
    departmentTitle,
  };
}

export function setMessage(message: Types.IEmbedMessage): Types.ActionTypes {
  return {
    type: Types.SET_MESSAGE,
    message,
  };
}

export function clearMessage(): Types.ActionTypes {
  return {
    type: Types.CLEAR_MESSAGE,
  };
}

export function setFixedHeight(fixedHeight: boolean): Types.ActionTypes {
  return {
    type: Types.SET_FIXED_HEIGHT,
    fixedHeight,
  };
}
