import React from 'react';
import { useSelector } from 'react-redux';
import {
  getDark,
  getGivingItem,
  getAmountButtons,
} from '../../../store/miniEmbed/selectors';
import CurrencyInputView from 'react-currency-input-field';

import { FormatCurrency } from '../../../util/NumberFormat';

import useComponentSize from '@rehooks/component-size';
import { buildCartFromMiniEmbedOptions } from '../../../util/serialization/SerializeCart';
import { checkoutLink } from '../../../util/EmbedLinks';
import { errorForCurrencyInput } from '../../../util/ValidationUtils';
import { getURLData } from '../../../store/legacy/selectors';

import './MiniEmbed.scss';

const baseClassName = 'mini-embed';

export const MiniEmbedView = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { height } = useComponentSize(containerRef);
  React.useEffect(() => {
    window.top.postMessage({ height: `${height}px` }, '*');
  }, [height]);

  const dark = useSelector(getDark);
  const amounts = useSelector(getAmountButtons);
  const givingItem = useSelector(getGivingItem);
  const urlData = useSelector(getURLData);

  const [customAmountFieldValue, setCustomAmountFieldValue] = React.useState<
    string | undefined
  >();
  const [customAmountError, setCustomAmountError] = React.useState<string>();

  const [amount, setAmount] = React.useState<number | undefined>();
  const [showingCustomField, setShowingCustomField] = React.useState(false);

  const formValid = amount && amount > 0;
  const cart = givingItem
    ? buildCartFromMiniEmbedOptions(givingItem, amount)
    : undefined;

  const darkClassName = dark ? `${baseClassName}--dark` : '';
  const submitClassName = `${baseClassName}__make-gift`;
  const submitActiveClassName = formValid ? `active` : '';

  const customInputContainerClassName = `${baseClassName}__custom-input-container`;
  const customInputClosedClassName = showingCustomField
    ? ''
    : `${customInputContainerClassName}--closed`;

  const onBestGiftClicked = () => {
    setShowingCustomField(true);

    if (customAmountFieldValue) {
      setAmount(parseFloat(customAmountFieldValue));
    }
  };

  const onCustomInputUpdated = (stringValue: string | undefined) => {
    setCustomAmountFieldValue(stringValue);

    let amount = parseFloat(stringValue || '');
    const customAmountError = errorForCurrencyInput(amount);

    setCustomAmountError(customAmountError);
    if (customAmountError) {
      amount = 0;
    }

    setAmount(amount);
  };

  const clearCustomInputField = () => {
    if (parseFloat(customAmountFieldValue || '') === amount) {
      setAmount(undefined);
    }

    setShowingCustomField(false);
  };

  const createAmountButton = (buttonAmount: number) => {
    const onClick = () => {
      setAmount(buttonAmount);
      setShowingCustomField(false);
    };

    const activeClass = buttonAmount === amount ? 'active' : '';

    return (
      <button
        className={`${baseClassName}__amount-button ${activeClass}`}
        onClick={onClick}
      >
        {FormatCurrency(buttonAmount, true, true)}
      </button>
    );
  };

  return (
    <div className={`${baseClassName} ${darkClassName}`} ref={containerRef}>
      <div className={`${baseClassName}__amount-buttons`}>
        {amounts?.map(createAmountButton)}
      </div>

      <div
        className={`${customInputContainerClassName} ${customInputClosedClassName}`}
      >
        <CurrencyInputView
          className={`${baseClassName}__custom-input`}
          onValueChange={value => onCustomInputUpdated(value)}
          prefix='$'
          placeholder='$'
        />

        <div className={`${baseClassName}__custom-input-error`}>
          {customAmountError}
        </div>

        {showingCustomField ? (
          <button
            className={`${baseClassName}__custom-input-clear`}
            onClick={clearCustomInputField}
          ></button>
        ) : null}
      </div>

      <a
        className={`button ${submitClassName} ${submitActiveClassName}`}
        href={checkoutLink(cart, document.referrer, urlData)}
        target='blank'
      >
        Make a Gift Today
      </a>

      {!showingCustomField ? (
        <div>
          <button
            className={`${baseClassName}__custom-gift`}
            onClick={onBestGiftClicked}
          >
            Or give my best gift
          </button>
        </div>
      ) : null}
    </div>
  );
};
