import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  honorary,
  memorial,
  none,
} from '../../../../../assets/values/dedicationTypes.json';
import { no, yes } from '../../../../../assets/values/yesNo.json';
import {
  billingIsShippingOptions,
  companyMatchingOptions,
  isOrganizationOptions,
} from '../../../../util/dataSets/donorFormOptions';
import FormKeys from '../../../../util/fieldNames/CheckoutFieldNames';
import { emailRegex } from '../../../../util/ValidationUtils';
import { useWatchedField } from '../../../effects/WatchedField';
import { InfoTooltip } from '../../infoTooltip/InfoTooltip';
import {
  HookFormCheckbox,
  HookFormRadio,
  HookFormTelephoneInput,
  HookFormTextArea,
  HookFormTextInput,
} from '../../textInput/HookFormWrapper';
import { AddressFields } from '../AddressFields/AddressFields';
import { NameFields } from '../NameFields/NameFields';
import { NotificationFields } from '../NotificationFields/NotificationFields';
import './DonorFields.scss';
import { CompanyMatchingTooltipData } from './micros/CompanyMatchingTooltipData';

export interface IProps {
  onPreviewEmail: (dedicationType: string) => void;
  onPreviewCard: (dedicationType: string) => void;
  honoreeSectionEnabled?: boolean;
  defaultValues: any;
  showMissingFields: boolean;
}

const dedicationTypes: { value: string }[] = [none, honorary, memorial];

const scaledPaddingBottom = (value: number) =>
  `donor-fields__padding--bottom-${value}`;

export const DonorFields = (props: IProps) => {
  const form = useFormContext();
  const {
    onPreviewEmail,
    onPreviewCard,
    honoreeSectionEnabled,
    defaultValues,
    showMissingFields,
  } = props;
  const { errors, control, setError, clearError, setValue } = form;

  const [loaded, setLoaded] = React.useState(false);

  if (!loaded) {
    setLoaded(true);
  }

  const companyMatchingKey = FormKeys.fields.donorIsCompanyMatching;
  const watchCompanyMatching = useWatchedField(
    companyMatchingKey.name,
    defaultValues[companyMatchingKey.name] || no.value
  );

  const dedicationTypeKey = FormKeys.fields.dedicationType;
  const dedicationNameKey = FormKeys.fields.dedicationName;

  const watchDedicationType = useWatchedField(
    dedicationTypeKey.name,
    dedicationTypes[0].value
  );
  const showHonoreeField = watchDedicationType.stringValue !== none.value;

  const isOrganizationKey = FormKeys.fields.donorIsOrganization;
  const watchIsOrganization = useWatchedField(
    isOrganizationKey.name,
    defaultValues[isOrganizationKey.name] || no.value
  );
  const isOrganization = watchIsOrganization.booleanValue;

  const isJointGiftKey = FormKeys.fields.giftIsJointGift;
  const watchIsJointGift = useWatchedField(
    isJointGiftKey.name,
    defaultValues[isJointGiftKey.name] || no.value
  );

  const billingIsShippingKey = FormKeys.fields.donorBillingIsShipping;
  const watchBillingIsShipping = useWatchedField(
    billingIsShippingKey.name,
    defaultValues[billingIsShippingKey.name] || yes.value
  );

  const phoneKey = FormKeys.fields.donorPhone;
  const onPhoneValidChanged = (valid: boolean, value?: string) => {
    if (valid) {
      clearError(phoneKey.name);
    } else {
      const errorMessage =
        value && value.length ? 'Not a valid phone number' : undefined;

      setError(phoneKey.name, phoneKey, errorMessage);
    }
  };

  const phoneCountryKey = FormKeys.fields.donorPhoneCountry;
  const onPhoneCountryChanged = (countryCode: string) => {
    setValue(phoneCountryKey.name, countryCode, true);
  };

  React.useEffect(() => {
    form.register({ name: phoneCountryKey.name });
  }, [form, phoneCountryKey.name]);

  return (
    <div id='donor-form'>
      <div className='columns is-multiline'>
        <div
          className={`columns is-multiline column is-full ${scaledPaddingBottom(
            1
          )}`}
        >
          <div className='column is-full pb--20'>
            <h3 className='donor-fields__title pb--20'>
              Are you making this gift on behalf of an organization?
            </h3>
            <Controller
              name={isOrganizationKey.name}
              as={HookFormRadio}
              options={isOrganizationOptions}
              defaultValue={watchIsOrganization.defaultValue}
              control={control}
              className={`${isOrganization ? scaledPaddingBottom(3) : ''}`}
              required={isOrganizationKey.required}
            />
            {isOrganization ? (
              <Controller
                as={HookFormTextInput}
                name={FormKeys.fields.donorOrganizationName.name}
                label='Organization Name'
                placeholder='Organization Name'
                control={control}
                errors={errors}
                missing={showMissingFields}
                rules={{
                  required: FormKeys.fields.donorOrganizationName.required,
                }}
                required={FormKeys.fields.donorOrganizationName.required}
              />
            ) : null}
          </div>
          <Controller
            as={HookFormTextInput}
            control={control}
            className='column is-full'
            label='Email'
            placeholder='Email'
            type='email'
            name={FormKeys.fields.donorEmail.name}
            errors={errors}
            missing={showMissingFields}
            rules={{
              required: FormKeys.fields.donorEmail.required,
              pattern: {
                value: emailRegex,
                message: 'Not a valid email address',
              },
            }}
            required={FormKeys.fields.donorEmail.required}
          />
          {!isOrganization ? (
            <>
              <NameFields
                title={FormKeys.fields.donorNameTitle}
                given={FormKeys.fields.donorNameGiven}
                family={FormKeys.fields.donorNameFamily}
                context={form}
                showMissing={showMissingFields}
              />
              <div className='column is-full d--f'>
                <Controller
                  name={isJointGiftKey.name}
                  defaultValue={watchIsJointGift.defaultValue}
                  as={HookFormCheckbox}
                  control={control}
                  onChangeName='onChangeAsString'
                  label='Is this a joint gift?'
                  required={isJointGiftKey.required}
                />
              </div>
            </>
          ) : null}
          {watchIsJointGift.booleanValue ? (
            <NameFields
              fieldLabelPrefix='Partner'
              title={FormKeys.fields.partnerNameTitle}
              given={FormKeys.fields.partnerNameGiven}
              family={FormKeys.fields.partnerNameFamily}
              context={form}
              showMissing={showMissingFields}
            />
          ) : null}
          <AddressFields
            addressLabelPrefix='Street'
            context={form}
            line1={FormKeys.fields.donorAddressLine1}
            line2={FormKeys.fields.donorAddressLine2}
            city={FormKeys.fields.donorCity}
            stateOrProvince={FormKeys.fields.donorStateOrProvince}
            postalCode={FormKeys.fields.donorPostalCode}
            country={FormKeys.fields.donorCountry}
            showMissing={showMissingFields}
          />
          <Controller
            name={phoneKey.name}
            as={HookFormTelephoneInput}
            control={control}
            onChangeName='onPhoneNumberChanged'
            onValidChanged={onPhoneValidChanged}
            onCountryCodeChanged={onPhoneCountryChanged}
            label={'Phone'}
            className='column is-half'
            missing={showMissingFields}
            errors={errors}
            rules={{
              required: phoneKey.required,
            }}
            required={phoneKey.required}
          />
          <Controller
            name={FormKeys.fields.donorUtEid.name}
            control={control}
            as={HookFormTextInput}
            className='column'
            label='UT EID (optional)'
            placeholder='UT EID'
            required={FormKeys.fields.donorUtEid.required}
          />
        </div>
        <div className={`column is-full ${scaledPaddingBottom(3)}`}>
          <h3 className={`donor-fields__title ${scaledPaddingBottom(2)}`}>
            Billing Address
          </h3>
          <p className={`${scaledPaddingBottom(3)}`}>
            Select the address that matches your card.
          </p>
          <Controller
            as={HookFormRadio}
            control={control}
            options={billingIsShippingOptions}
            name={billingIsShippingKey.name}
            defaultValue={watchBillingIsShipping.defaultValue}
            required={billingIsShippingKey.required}
          />
        </div>
        {!watchBillingIsShipping.booleanValue ? (
          <AddressFields
            addressLabelPrefix='Billing'
            context={form}
            showMissing={showMissingFields}
            line1={FormKeys.fields.billingAddressLine1}
            line2={FormKeys.fields.billingAddressLine2}
            city={FormKeys.fields.billingCity}
            stateOrProvince={FormKeys.fields.billingStateOrProvince}
            postalCode={FormKeys.fields.billingPostalCode}
            country={FormKeys.fields.billingCountry}
          />
        ) : null}
        <div className='column is-full mobile-only'>
          <h3 className='donor-fields__title pb--20'>Gift Comments</h3>
          <Controller
            as={HookFormTextArea}
            control={control}
            name={FormKeys.fields.giftRemarks.name}
            className=''
            maxLength={120}
            required={FormKeys.fields.giftRemarks.required}
          />
        </div>
        <div
          className={`donor-fields__divider column is-full ${scaledPaddingBottom(
            3
          )} `}
        />
        {honoreeSectionEnabled ? (
          <div className={`column is-full ${scaledPaddingBottom(2)}`}>
            <h3 className='donor-fields__title pb--30'>
              Is this gift in honor or memory of someone?
            </h3>
            <Controller
              name={dedicationTypeKey.name}
              as={HookFormRadio}
              control={control}
              options={dedicationTypes}
              value={watchDedicationType.stringValue}
              required={dedicationTypeKey.required}
            />
            {showHonoreeField && (
              <>
                <div className='columns'>
                  <Controller
                    name={dedicationNameKey.name}
                    as={HookFormTextInput}
                    label={'Honoree Name or Organization'}
                    placeholder={'First and Last Name or Organization'}
                    className='column'
                    missing={showMissingFields}
                    control={control}
                    errors={errors}
                    rules={{ required: dedicationNameKey.required }}
                    required={dedicationNameKey.required}
                  />
                </div>
                <NotificationFields
                  context={form}
                  showMissing={showMissingFields}
                  onPreviewEmail={() =>
                    onPreviewEmail(watchDedicationType.stringValue)
                  }
                  onPreviewCard={() =>
                    onPreviewCard(watchDedicationType.stringValue)
                  }
                  defaultValues={defaultValues}
                />
              </>
            )}
          </div>
        ) : null}

        <div className={`column is-full ${scaledPaddingBottom(4)}`}>
          <h3 className={`donor-fields__title ${scaledPaddingBottom(3)}`}>
            Does this gift qualify for a company match?
            <InfoTooltip tooltipContent={<CompanyMatchingTooltipData />} />
          </h3>
          <Controller
            name={companyMatchingKey.name}
            value={watchCompanyMatching.stringValue}
            defaultValue={watchCompanyMatching.defaultValue}
            as={HookFormRadio}
            control={control}
            options={companyMatchingOptions}
            className={scaledPaddingBottom(2)}
            singleLine={true}
            required={companyMatchingKey.required}
          />
          {watchCompanyMatching.booleanValue && (
            <Controller
              as={HookFormTextInput}
              name={FormKeys.fields.donorMatchingName.name}
              label='Company Name'
              placeholder='Company Name'
              control={control}
              missing={showMissingFields}
              errors={errors}
              rules={{ required: FormKeys.fields.donorMatchingName.required }}
              required={FormKeys.fields.donorMatchingName.required}
            />
          )}
        </div>
      </div>
      <input type='submit' id='submit-form' style={{ display: 'none' }} />
    </div>
  );
};

export const LineBreak = () => <br className='donor-fields__line-break' />;
