import React from 'react';
import { AddressLookupField } from '../../containers/addressLookup/AddressLookupField';
import { Checkbox } from '../checkbox/Checkbox';
import { Dropdown, IProps as DropdownProps } from '../dropdown/Dropdown';
import { Radio } from '../radio/Radio';
import { TextArea } from '../textArea/TextArea';
import { ISharedInputProps as IInputProps } from './InputWrapper';
import { PhoneInput } from './PhoneInput';
import { TextInput } from './TextInput';

interface IWrapperProps {
  name?: string;
  value?: any;
  errors?: any; // Error dictionary indexed by field name. Example: {"email": {"message": "A valid email is required."}}
  error?: any; // Overrides error pulled from errors object
  valid?: boolean; // Overrides any errors
  missing?: boolean;
}

export const HookFormInputField =
  <IProps extends any>(Component: React.ComponentType<IProps & IInputProps>) =>
  (props: IProps & IWrapperProps) => {
    const { errors, name, error, missing = false } = props;

    let message = errors && name ? props.errors[name]?.message : undefined;

    if (error) {
      message = props.error?.message;
    }

    // Only display "valid" state if an errors dictionary is given.
    const shouldCheckForErrors = props.value && props.errors;
    const valid = props.valid || (shouldCheckForErrors && error === undefined);

    return (
      <Component {...props} error={message} valid={valid} missing={missing} />
    );
  };

export const HookFormTextInput = HookFormInputField(TextInput);
export const HookFormTelephoneInput = HookFormInputField(PhoneInput);
export const HookFormAddressLookupField =
  HookFormInputField(AddressLookupField);
export const HookFormCheckbox = HookFormInputField(Checkbox);
export const HookFormDropdown = HookFormInputField<DropdownProps>(Dropdown);
export const HookFormRadio = HookFormInputField(Radio);
export const HookFormTextArea = HookFormInputField(TextArea);
