import * as Types from './types';

export const initialState: Types.IReferrerState = {};

export function referrerReducer(
  state = initialState,
  action: Types.ActionType
): Types.IReferrerState {
  switch (action.type) {
    case Types.SET_REFERRER:
      return { ...state, referrer: action.referrer };
  }

  return state;
}
