import * as React from 'react';
import { connect } from 'react-redux';

import { appendBasePath, Routes } from '../../../router/Routes';
import { RootState } from '../../../store';
import * as pledgeSelectors from '../../../store/pledge/selectors';
import { ITabBarItem } from '../../components/tabBar/TabBar';
import { MobileNav } from './MobileNav';

const mapStateToProps = (state: RootState) => {
  return {
    checkoutViewEnabled: pledgeSelectors.getPledgeGiftValid(state),
  };
};

export interface IProps {
  basePath: string;
  homePageTitle: string;
  checkoutViewEnabled: boolean | 0;
}

const PledgeMobileNavView = (props: IProps) => {
  const appendPledgePath = appendBasePath(props.basePath);

  const tabBarItems: ITabBarItem[] = [
    {
      label: props.homePageTitle,
      value: appendPledgePath(Routes.Sections.Search.path),
      styleHighlighted: true,
    },
    {
      label: Routes.Sections.Checkout.label,
      value: appendPledgePath(Routes.Sections.Checkout.path),
      disabled: !props.checkoutViewEnabled,
    },
  ];

  return (
    <MobileNav basePath={props.basePath} items={tabBarItems} styleNarrow />
  );
};

const connector = connect(mapStateToProps);
export const PledgeMobileNav = connector(PledgeMobileNavView);
