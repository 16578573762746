import React from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store';
import { getGiftType } from '../../../../store/app/selectors';
import { getSerializedCartParams } from '../../../../store/cart/selectors';
import { previewCard, previewEmail } from '../../../../store/modal/actions';
import { setFormData, setFormValid } from '../../../../store/payment/actions';
import {
  getDefaultPaymentFormValues,
  getFormHonoreeSectionEnabled,
  getShowMissingFields,
} from '../../../../store/payment/selectors';
import { getTransactionProcessing } from '../../../../store/transaction/selectors';
import { DonorFields } from '../../../components/forms/DonorView/DonorFields';
import { CheckoutToConfirmationRedirect } from '../../../router/redirects/CheckoutToConfirmationRedirect';
import CartQueryUnwrapper from '../../../wrappers/urlQueryUnwrapper/CartQueryUnwrapper';

const mapStateToProps = (state: RootState) => {
  return {
    defaultValues: getDefaultPaymentFormValues(state),
    giftType: getGiftType(state),
    transactionProcessing: getTransactionProcessing(state),
    serializedCart: getSerializedCartParams(state),
    honoreeSectionEnabled: getFormHonoreeSectionEnabled(state),
    showMissingFields: getShowMissingFields(state),
  };
};

const mapDispatchToProps = {
  setFormData,
  setFormValid,
  previewCard,
  previewEmail,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type IProps = ReduxProps & {};

const CheckoutFormMobileComponent = (props: IProps) => {
  const {
    setFormData,
    setFormValid,
    defaultValues,
    previewCard,
    previewEmail,
    honoreeSectionEnabled,
    showMissingFields,
  } = props;

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  });

  const formValues = form.watch();
  const stringifiedValues = JSON.stringify(formValues);
  const isValid = form.formState.isValid;

  React.useEffect(() => {
    setFormData(JSON.parse(stringifiedValues));
  }, [stringifiedValues, setFormData]);

  React.useEffect(() => {
    setFormValid(isValid);
  }, [isValid, setFormValid]);

  // Block manual submission of form
  const onFormSubmit = (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
  };

  return (
    <>
      <CartQueryUnwrapper>
        <CheckoutToConfirmationRedirect />
        <form
          onSubmit={onFormSubmit}
          className='columns is-marginless'
          id='donor-form'
        >
          <FormContext {...form}>
            <DonorFields
              onPreviewCard={previewCard}
              onPreviewEmail={previewEmail}
              honoreeSectionEnabled={honoreeSectionEnabled}
              defaultValues={defaultValues}
              showMissingFields={showMissingFields}
            />
          </FormContext>
        </form>
      </CartQueryUnwrapper>
    </>
  );
};

export const CheckoutFormMobile = connector(CheckoutFormMobileComponent);
