export interface IStore {
  modal?: IModalData;
  paymentModalOpen: boolean;
}

export const MODAL_TYPE_PREVIEW_EMAIL = 'MODAL_TYPE_PREVIEW_EMAIL';
export const MODAL_TYPE_PREVIEW_CARD = 'MODAL_TYPE_PREVIEW_CARD';
export const MODAL_TYPE_ALERT = 'MODAL_TYPE_ALERT';
export const MODAL_TYPE_TOOLTIP = 'MODAL_TYPE_TOOLTIP'; // Mobile only

export interface IPreviewEmailModal {
  type: typeof MODAL_TYPE_PREVIEW_EMAIL;
  dedicationTypeValue: string;
}

export interface IPreviewCardModal {
  type: typeof MODAL_TYPE_PREVIEW_CARD;
  dedicationTypeValue: string;
}

export interface ITooltipModal {
  type: typeof MODAL_TYPE_TOOLTIP;
  content?: React.ReactChild;
}

export interface IAlertModal {
  type: typeof MODAL_TYPE_ALERT;
  title: string;
  message: string;
}

type IModalData =
  | IPreviewEmailModal
  | IPreviewCardModal
  | IAlertModal
  | ITooltipModal;

export const SET_MODAL = 'SET_MODAL';
export const SET_PAYMENT_MODAL_OPEN = 'SET_PAYMENT_MODAL_OPEN';
export interface ISetModal {
  type: typeof SET_MODAL;
  modal?: IModalData;
}

export interface ISetPaymentModalOpen {
  type: typeof SET_PAYMENT_MODAL_OPEN;
  open: boolean;
}

export type ActionTypes = ISetModal | ISetPaymentModalOpen;
