// These SCSS imports MUST come before component imports, so components can override bulma & other global styles
import './style/global-style.scss';
import './App.scss';
import 'resize-observer-polyfill';

import * as React from 'react';
import DocumentMeta from 'react-document-meta';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { WebLayout } from './views/layouts/web/WebLayout';
import { EmbedLayout } from './views/layouts/embed/EmbedLayout';
import { PATH_EMBED, PATH_MINI_EMBED } from './router/Routes';

const meta = {
  link: {
    rel: {
      stylesheet: [process.env.REACT_APP_WRAPPER_CSS_URL],
    },
  },
};

export default () => (
  <DocumentMeta {...meta}>
    <Router>
      <Switch>
        <Route path={[PATH_EMBED, PATH_MINI_EMBED]}>
          <EmbedLayout />
        </Route>
        <Route path={'*'}>
          <WebLayout />
        </Route>
      </Switch>
    </Router>
  </DocumentMeta>
);
