import * as React from 'react';
import { Link } from 'react-router-dom';

import {
  GiftType,
  GIFT_TYPE_PLEDGE,
  GIFT_TYPE_SINGLE,
} from '../../../models/GiftType';
import { Routes } from '../../../router/Routes';

import './SwitchGiftTypeLink.scss';

const PledgeLink = (props: IProps) => (
  <p className={`switch-gift ${props.className || ''}`}>
    {`Making or paying a pledge? `}
    <Link className='switch-gift__link' to={Routes.Roots.Pledge.path}>
      Go to Pledge Pay
    </Link>
    .
  </p>
);

const SingleGiftLink = (props: IProps) => (
  <p className={`switch-gift ${props.className || ''}`}>
    {`Making a one-time gift or a new pledge? `}
    <Link className='switch-gift__link' to={Routes.Roots.OGP.path}>
      Go to UT Online Giving
    </Link>
    .
  </p>
);

export const SwitchGiftTypeLink = (props: IProps) => {
  switch (props.giftType) {
    case GIFT_TYPE_SINGLE:
      return <SingleGiftLink {...props} />;
    case GIFT_TYPE_PLEDGE:
      return <PledgeLink {...props} />;
  }
};

export interface IProps {
  giftType: GiftType;
  className?: string;
}
