import { useState, useEffect } from 'react';
import * as breakpoints from '../../../style/breakpoints.json';

export enum ViewType {
  Desktop,
  Mobile,
}

const ViewTypeForScreenWidth = (width: number) => {
  if (width >= breakpoints.mobile) {
    return ViewType.Desktop;
  }

  return ViewType.Mobile;
};

function getScreenWidth() {
  const { innerWidth: width } = window;
  return ViewTypeForScreenWidth(width);
}

export default function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());

  useEffect(() => {
    function handleResize() {
      setScreenWidth(getScreenWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenWidth;
}
