import './FrequencyPageMobile.scss';

import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import GiftFrequencies from '../../../../../assets/values/singleGiftFrequencies.json';
import { GIFT_TYPE_SINGLE } from '../../../../models/GiftType';
import { Routes } from '../../../../router/Routes';
import { RootState } from '../../../../store';
import * as cartSelectors from '../../../../store/cart/selectors';
import * as singleGiftActions from '../../../../store/cart/singleGift/actions';
import {
  getDuration,
  getDurations,
  getFrequency,
} from '../../../../store/cart/singleGift/selectors';
import { getGiftCountString } from '../../../../util/StringUtils';
import { Dropdown } from '../../../components/dropdown/Dropdown';
import { Footer } from '../../../components/footer/footer';
import { bottomButtonClassName } from '../../../containers/giftSummary/mobile/MobileGiftSummary';
import { FrequencyNoCartRedirect } from '../../../router/redirects/FrequencyNoCartRedirect';

const baseClassName = 'mob-frequency-page';

const mapStateToProps = (state: RootState) => {
  const getGiftCount =
    cartSelectors.getNumberOfGiftsForGiftTypeCreator(GIFT_TYPE_SINGLE);

  return {
    giftCount: getGiftCount(state),
    frequency: getFrequency(state),
    durations: getDurations(state),
    duration: getDuration(state),
  };
};

const mapDispatchToProps = {
  updateFrequency: singleGiftActions.updateFrequency,
  updateDuration: singleGiftActions.updateDuration,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type IProps = ConnectedProps<typeof connector>;

const FrequencyPageMobileView = (props: IProps) => {
  const frequencyButton = (frequency: { label: string; value: string }) => {
    const isActive = frequency.value === props.frequency?.value;
    const onClick = () => {
      props.updateFrequency(frequency.value);
      props.updateDuration(-1);
    };
    return (
      <button
        onClick={onClick}
        className={`ut-btn--secondary corner--pill ${baseClassName}__button ${
          isActive ? 'active' : ''
        }`}
      >
        {frequency.label}
      </button>
    );
  };

  const recurringSelected =
    props.frequency?.value === GiftFrequencies.monthly.value ||
    props.frequency?.value === GiftFrequencies.annually.value;

  const durations = props.durations || [];
  // eslint-disable-next-line
  const dropdownValue = durations.find(item => props.duration == item.value);

  return (
    <>
      <FrequencyNoCartRedirect />
      <div className={`${baseClassName}__content`}>
        <div className={`${baseClassName}__body`}>
          <div className={`${baseClassName}__button-group`}>
            <div className={`${baseClassName}__title`}>Select Frequency</div>
            <div className={`${baseClassName}__gift-count`}>
              {getGiftCountString(props.giftCount)}
            </div>
            <div className={`${baseClassName}__tool-tip`}>
              Give a one-time gift today, or we can continue your support
              automatically until you tell us to stop.
            </div>

            {frequencyButton(GiftFrequencies.oneTime)}
            {frequencyButton(GiftFrequencies.monthly)}
            {frequencyButton(GiftFrequencies.annually)}

            {recurringSelected ? (
              <div className='cart__frequency'>
                <div
                  className={`cart__frequency-recurring${
                    props.frequency?.value === GiftFrequencies.oneTime.value
                      ? '--hidden'
                      : ''
                  }`}
                >
                  <div className='d--f pb--10'>
                    <p className='cart__frequency-title'>
                      For how many payments?
                    </p>
                  </div>

                  <Dropdown
                    value={dropdownValue?.label || undefined}
                    className={`pb--40 pledge-establish__duration-dropdown`}
                    options={durations}
                    strictValues
                    onChange={(option: string) => {
                      const newValue = durations.find(
                        item => option === item.label
                      );

                      props.updateDuration(newValue?.value || undefined);
                    }}
                    placeholder='Until I Cancel'
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <Footer small />
      </div>
    </>
  );
};

const NextButtonView = (props: IProps) => {
  const history = useHistory();

  const onButtonClick = () => {
    history.push(Routes.Sections.Amount.path);
  };

  const buttonDisabled =
    props.frequency === undefined ||
    (props.frequency === GiftFrequencies.monthly &&
      props.duration === undefined) ||
    (props.frequency === GiftFrequencies.annually &&
      props.duration === undefined);

  return (
    <button
      className={`${bottomButtonClassName} ${
        buttonDisabled ? 'btn--disabled' : ''
      } ut-btn corner--pill w--100 `}
      onClick={onButtonClick}
    >
      Select an Amount
    </button>
  );
};

export const FrequencyPageMobile = connector(FrequencyPageMobileView);
export const NextButton = connector(NextButtonView);
