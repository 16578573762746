export interface BackgroundSolidClasses {
  menu: string;
  container: string;
}

const backgroundSolidThresholds: { [size: number]: BackgroundSolidClasses } = {
  30: {
    menu: 'menu_scrolled--pledge-mobile',
    container: 'container_bg--pledge-mobile',
  },
  120: {
    menu: 'menu_scrolled',
    container: 'container_bg',
  },
};

export const classesForYPosition = (
  yPosition: number,
  forceScrolledState?: boolean
) => {
  return Object.keys(backgroundSolidThresholds)
    .map(n => parseInt(n, 10))
    .filter(threshold => threshold <= yPosition || forceScrolledState)
    .map(threshold => backgroundSolidThresholds[threshold]);
};
