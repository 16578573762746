import { FormContextValues } from 'react-hook-form';
import { unitedStates } from '../../../../../util/dataSets/countries';
import { IProps } from '../AddressFields';

const defaultCountry = unitedStates?.value;

const registerCountry = (props: IProps, form: FormContextValues) => {
  const { register, setValue } = form;

  return () => {
    const countryFieldName = props.getCompleteFieldName(props.country.name);

    const setCountry = (name: string) => {
      setValue(countryFieldName, name, true);
    };

    register({ name: countryFieldName }, { required: true });
    setCountry(defaultCountry);
  };
};

const fillAutocompleteValues = (props: IProps, form: FormContextValues) => {
  const { setValue } = form;

  return () => {
    const valuesDict = props.prefilledFormValues;

    if (valuesDict) {
      Object.keys(valuesDict).forEach(key => {
        const value = valuesDict[key];
        if (value) {
          setValue(props.getCompleteFieldName(key), value, true);
        }
      });
    }
  };
};

export const createEffects = (props: IProps, form: FormContextValues) => ({
  fillAutocompleteValues: fillAutocompleteValues(props, form),
  registerCountry: registerCountry(props, form),
});
