import React from 'react';

import { connect, ConnectedProps } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { unwrapCartFromURLParams } from '../../../util/serialization/SerializeCart';
import { RootState } from '../../../store';
import { rootForGiftType } from '../../../router/Routes';
import * as pledgeSelectors from '../../../store/pledge/existingPledge/selectors';
import * as cartSelectors from '../../../store/cart/selectors';
import * as appSelectors from '../../../store/app/selectors';

const mapStateToProps = (state: RootState) => {
  const giftType = appSelectors.getGiftType(state);
  const cartGifts = cartSelectors.getAllCompletedGifts(state)[giftType];
  const pledgeGifts = pledgeSelectors.getGiftsForPayment(state);

  const giftCount = cartGifts.length + pledgeGifts.length;
  return {
    giftCount,
    giftType,
  };
};

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const CheckoutNoCartRedirectView = (props: ReduxProps) => {
  const URLParams = new URLSearchParams(useLocation().search);
  const URLCart = unwrapCartFromURLParams(URLParams);

  return !props.giftCount && !URLCart ? (
    <Redirect to={rootForGiftType(props.giftType)?.path} />
  ) : null;
};

export const CheckoutNoCartRedirect = connector(CheckoutNoCartRedirectView);
