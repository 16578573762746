import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore as _createStore,
} from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import addressSaga from '../../app/store/address/sagas';
import embedSaga from '../../app/store/embed/sagas';
import pledgeSaga from '../../app/store/pledge/sagas';
import searchSaga from '../../app/store/search/sagas';
import { addressReducer } from './address/reducers';
import { appReducer, initialState as initialAppState } from './app/reducers';
import appSaga from './app/sagas';
import { cartReducer, initialState as initialCartState } from './cart/reducers';
import { embedReducer } from './embed/reducers';
import {
  expirationReducer,
  initialState as initialExpirationState,
} from './expiration/reducers';
import expirationSaga from './expiration/sagas';
import { layoutReducer } from './layout/reducers';
import { legacyReducer } from './legacy/reducers';
import legacySaga from './legacy/sagas';
import { miniEmbedReducer } from './miniEmbed/reducers';
import miniEmbedSaga from './miniEmbed/sagas';
import { modalReducer } from './modal/reducers';
import { paymentReducer } from './payment/reducers';
import {
  initialState as initialPledgeState,
  pledgeReducer,
} from './pledge/reducers';
import { referrerReducer } from './referrer/reducers';
import { searchReducer } from './search/reducers';
import { transactionReducer } from './transaction/reducers';

const getSchemaVersion = () => {
  const schemaVersion = parseInt(
    process.env.REACT_APP_SCHEMA_VERSION || '',
    10
  );
  if (!isNaN(schemaVersion)) {
    return schemaVersion;
  }

  return -1;
};

const rootReducer = combineReducers({
  app: appReducer,
  address: addressReducer,
  search: searchReducer,
  cart: cartReducer,
  modal: modalReducer,
  transaction: transactionReducer,
  pledge: pledgeReducer,
  payment: paymentReducer,
  embed: embedReducer,
  layout: layoutReducer,
  legacy: legacyReducer,
  miniEmbed: miniEmbedReducer,
  referrer: referrerReducer,
  expiration: expirationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'app', 'pledge', 'expiration'],
  version: getSchemaVersion(),
  migrate: (state: any = {}) => {
    const persist = state._persist;
    if (!persist || persist.version !== getSchemaVersion()) {
      return Promise.resolve({
        ...state,
        app: initialAppState,
        cart: initialCartState,
        pledge: initialPledgeState,
        expiration: initialExpirationState,
      });
    }

    return Promise.resolve(state);
  },
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : null || compose;

  const store = _createStore(
    persistedReducer,
    composeEnhancers
      ? composeEnhancers(applyMiddleware(sagaMiddleware))
      : applyMiddleware(sagaMiddleware)
  );

  sagaMiddleware.run(searchSaga);
  sagaMiddleware.run(pledgeSaga);
  sagaMiddleware.run(appSaga);
  sagaMiddleware.run(addressSaga);
  sagaMiddleware.run(embedSaga);
  sagaMiddleware.run(miniEmbedSaga);
  sagaMiddleware.run(legacySaga);
  sagaMiddleware.run(expirationSaga);

  const persistor = persistStore(store);
  return { store, persistor };
};
