import { GIFT_TYPE_SINGLE } from '../../../models/GiftType';
import { IGivingItem } from '../../../models/GivingItem';
import * as Types from '../types';

export function addGivingItem(value: IGivingItem): Types.ActionTypes {
  return {
    type: Types.ADD_GIVING_ITEM,
    giftType: GIFT_TYPE_SINGLE,
    value,
  };
}

export function removeGivingItem(sku: string): Types.ActionTypes {
  return {
    type: Types.REMOVE_GIVING_ITEM,
    giftType: GIFT_TYPE_SINGLE,
    sku,
  };
}

export function updateAmount(sku: string, amount?: number): Types.ActionTypes {
  return {
    type: Types.UPDATE_SINGLE_GIFT_AMOUNT,
    sku,
    amount,
  };
}

export function updateAllAmounts(amount?: number): Types.ActionTypes {
  return {
    type: Types.UPDATE_ALL_SINGLE_GIFT_AMOUNTS,
    amount,
  };
}

export function updateFrequency(frequency?: string): Types.ActionTypes {
  return {
    type: Types.UPDATE_GIFT_FREQUENCY,
    giftType: GIFT_TYPE_SINGLE,
    frequency,
  };
}

export function updateDuration(duration?: number): Types.ActionTypes {
  return {
    type: Types.UPDATE_GIFT_END_DATE,
    giftType: GIFT_TYPE_SINGLE,
    duration,
  };
}
