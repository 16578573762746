import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PledgeView } from '../../../../containers/pledge/PledgeView';
import { SwitchGiftTypeLink } from '../../../../components/switchGiftTypeLink/SwitchGiftTypeLink';
import { Footer } from '../../../../components/footer/footer';

import { Routes } from '../../../../../router/Routes';
import { GIFT_TYPE_SINGLE } from '../../../../../models/GiftType';
import { getPledgeGiftValid } from '../../../../../store/pledge/selectors';

import { bottomButtonClassName } from '../../../../containers/giftSummary/mobile/MobileGiftSummary';

import './PledgeHomePage.scss';

const baseClassName = 'mob-pledge-home-page';

export const PledgeHomePageMobile = () => {
  return (
    <div>
      <div className={`${baseClassName}__pledge-view-container`}>
        <SwitchGiftTypeLink giftType={GIFT_TYPE_SINGLE} className='pb--30' />
        <PledgeView scrollToPaymentAmountButtons />
        <Footer small />
      </div>
    </div>
  );
};

export const NextButton = () => {
  const history = useHistory();
  const isValid = useSelector(getPledgeGiftValid);

  const onClick = () => {
    history.push(Routes.Sections.Checkout.path);
  };

  const buttonDisabledClass = isValid ? '' : 'btn--disabled';

  return (
    <button
      className={`${bottomButtonClassName} ${buttonDisabledClass} ut-btn corner--pill w--100 `}
      onClick={onClick}
    >
      Checkout
    </button>
  );
};
