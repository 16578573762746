import React from 'react';
import { IGivingItem } from '../../../../../models/GivingItem';

import './EmbedGivingItem.scss';

const baseClassName = 'embed-giving-item';

export const EmbedGivingItem = (props: IProps) => {
  const activeButtonClass = props.isInCart ? 'active' : '';
  const buttonText = `${props.isInCart ? 'Remove from' : 'Add to'} cart`;
  return (
    <div className={`${baseClassName}`}>
      <div className={`${baseClassName}__info`}>
        <div className={`${baseClassName}__name`}>
          {props.givingItem.givingItemSignature.name}
        </div>
        <div className={`${baseClassName}__description`}>
          {props.givingItem.givingItemSignature.description}
        </div>
      </div>
      <button
        className={`ut-btn--circle ${activeButtonClass}`}
        title={buttonText}
        onClick={props.onButtonClick}
      ></button>
    </div>
  );
};

export interface IProps {
  givingItem: IGivingItem;
  onButtonClick?: () => void;
  isInCart?: boolean;
}
