import API from '../API';
import { IGivingItemCollectionResponse } from '../../models/api/GivingItemCollectionResponse';
import { IDisplayFacet } from '../../store/search/types';
import { IGivingItemResponse } from '../../models/api/GivingItemResponse';

const getFacetsString = (facetTerms?: IDisplayFacet[]) => {
  if (!facetTerms || !facetTerms.length) {
    return undefined;
  }
  return `${facetTerms.map(term => term.value).join(',')}`;
};

export function fetchGivingItemsFromQuery(
  query?: string,
  queryFacets?: IDisplayFacet[],
  departmentCode?: string,
  featuredArea?: string,
  size?: number,
  abortSignal?: AbortSignal
): Promise<IGivingItemCollectionResponse> {
  const params = [
    {
      name: 'searchText',
      value: query,
    },
    {
      name: 'facetTerms',
      value: getFacetsString(queryFacets),
    },
  ];

  if (departmentCode) {
    params.push({ name: 'departmentCode', value: departmentCode });
  }

  if (featuredArea && !query) {
    params.push({ name: 'sku', value: featuredArea });
  }

  params.push({ name: 'size', value: `${size ? size : 'all'}` });

  return API.execute<IGivingItemCollectionResponse>('givingItems', params, {
    method: 'GET',
    signal: abortSignal,
  });
}

export function fetchGivingItemsFromDepartment(
  departmentCode?: string,
  department?: string
): Promise<IGivingItemCollectionResponse> {
  const name = departmentCode ? 'departmentCode' : 'department';
  const value = departmentCode ? departmentCode : department;
  const params = [
    {
      name,
      value,
    },
  ];

  return API.execute<IGivingItemCollectionResponse>('givingItems', params, {
    method: 'GET',
  });
}

export function fetchGivingItem(sku: string): Promise<IGivingItemResponse> {
  return API.execute<IGivingItemResponse>(`givingItems/${sku}`, [], {
    method: 'GET',
  });
}
