import { IGivingItem } from './GivingItem';

export const FacetKeys = {
  prioritized: 'prioritized',
  scholarships: 'scholarships',
  programs: 'programs',
  faculty: 'faculty',
  health: 'health',
  pledge: 'pledges',
};

export interface IFacet {
  facetTerm: string;
  givingItems: IGivingItem[];
}
