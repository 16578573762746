import { IDisplayFacet } from '../../store/search/types';
import { FacetKeys } from '../../models/Facet';

export const SearchFieldPlaceholder = (facet?: IDisplayFacet) => {
  if (!facet) {
    return '';
  }

  switch (facet.value) {
    case FacetKeys.scholarships:
    case FacetKeys.programs:
      return `Search all ${facet.label.toLocaleLowerCase()}`;
    case FacetKeys.faculty:
    case FacetKeys.health:
      return `Search all ${facet.label.toLocaleLowerCase()} opportunities`;
    default:
      return 'Search giving options by keyword';
  }
};
