import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IPledgeGiftBuilder } from '../../../../../models/GiftBuilder';
import { RootState } from '../../../../../store';
import * as cartSelectors from '../../../../../store/cart/pledge/selectors';
import { createSummaryClasses } from '../../../../../util/classes/MobileSummaryClassNames';
import { getPledgeFrequencyObject } from '../../../../../util/dataSets/frequencies';
import { FormatCurrency } from '../../../../../util/NumberFormat';
import { baseClassName } from '../MobilePledgeSummary';

const mapStateToProps = (state: RootState) => ({
  builders: cartSelectors.getBuilders(state),
  attributes: cartSelectors.getSharedAttributes(state),
  totalAmount: cartSelectors.getTotalPayment(state),
  recurringAmount: cartSelectors.getRecurringAmount(state),
});

const connector = connect(mapStateToProps);
type IProps = ConnectedProps<typeof connector>;

const MobilePledgeSummaryEstablishView = (props: IProps) => {
  return <>{props.builders.map(renderGift(props))}</>;
};

const renderGift =
  (props: IProps) => (builder: IPledgeGiftBuilder, index: number) => {
    const summaryClasses = createSummaryClasses(baseClassName);

    return (
      <div className={`${summaryClasses.gift}`} key={`${index}`}>
        <div className={`${summaryClasses.giftAmount}`}>
          {FormatCurrency(props.attributes.amount)}
        </div>
        <div className={`${summaryClasses.giftInfo}`}>
          <p className={`${summaryClasses.giftName}`}>
            {builder.givingItem.givingItemSignature.name}
          </p>
          <div className={`${summaryClasses.giftFrequency}`}>
            {getPledgeFrequencyObject(props.attributes.frequency)?.label}
          </div>
        </div>
      </div>
    );
  };

export const MobilePledgeSummaryEstablish = connector(
  MobilePledgeSummaryEstablishView
);
