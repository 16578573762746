import * as React from 'react';
import { usePrevious } from '../../effects/Previous';
import useComponentSize from '@rehooks/component-size';

import './TabBar.scss';

type OnItemSelected = (item: ITabBarItem) => void;

const baseClassName = 'ogp-tab-bar';

const tabBarClassName = (props: IProps, item: ITabBarItem) => {
  const itemClassName = `${baseClassName}__item`;

  return [
    itemClassName,
    props.styleSearchMobile ? `${itemClassName}--search-mobile` : '',
    item.value === props.value ? `${itemClassName}--selected` : '',
    item.styleHighlighted ? `${itemClassName}--highlighted` : '',
    item.disabled ? `${itemClassName}--disabled` : '',
  ]
    .filter(str => str.length)
    .join(' ');
};

const TabBarItem = (
  props: IProps,
  item: ITabBarItem,
  onSelect: OnItemSelected
) => {
  return (
    <button
      className={tabBarClassName(props, item)}
      onClick={() => onSelect(item)}
      key={item.value}
    >
      {item.label}
    </button>
  );
};

const getSelectBorderStyle = (
  props: IProps,
  width: number,
  previousValue?: string
) => {
  const getIndex = (value?: string) =>
    (props.options || [])?.findIndex(option => option.value === value);

  const selectedIndex = getIndex(props.value);
  const lastSelectedIndex = getIndex(previousValue);

  if (
    selectedIndex !== undefined &&
    selectedIndex >= 0 &&
    props.options?.length
  ) {
    const barWidth = width / props.options.length;
    const isFirstTab = selectedIndex <= 0 || lastSelectedIndex <= 0;

    const transition =
      props.hideFirstTabAnimation && isFirstTab ? 'left 0s' : undefined;

    return {
      left: `${selectedIndex * barWidth}px`,
      width: `${barWidth}px`,
      transition,
    };
  } else {
    return {
      display: 'none',
    };
  }
};

export const TabBar = (props: IProps) => {
  const componentRef = React.useRef<HTMLDivElement>(null);
  const { width } = useComponentSize(componentRef);

  const previousValue = usePrevious(props.value);

  const onSelect = (item: ITabBarItem) => {
    props.onSelect(item.value);
  };

  const propsClassName = ` ${props.className}` || '';

  const classMobile = props.styleSearchMobile
    ? ` ${baseClassName}--search-mobile`
    : '';

  const classHidden = props.hidden ? ` ${baseClassName}--hidden` : '';

  const contentClassName = `${baseClassName}__content`;
  const narrowClassName = props.styleNarrow
    ? `${contentClassName}--narrow`
    : '';

  return (
    <div
      className={`${baseClassName}${classMobile}${propsClassName}${classHidden}`}
    >
      <div
        className={`${contentClassName} ${narrowClassName}`}
        ref={componentRef}
      >
        {props.options?.map((item: ITabBarItem) =>
          TabBarItem(props, item, onSelect)
        )}
        {!props.styleSearchMobile ? (
          <div
            className={`${baseClassName}__selected-border`}
            style={getSelectBorderStyle(props, width, previousValue)}
          />
        ) : null}
      </div>
    </div>
  );
};

export interface ITabBarItem {
  label: string;
  value: string;
  disabled?: boolean;
  styleHighlighted?: boolean;
}

export interface IProps {
  options?: ITabBarItem[];
  value?: string;
  className?: string;
  styleNarrow?: boolean;
  styleSearchMobile?: boolean;
  hidden?: boolean;
  hideFirstTabAnimation?: boolean;
  onSelect: (value: string) => void;
}
