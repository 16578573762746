export interface ILayoutState {
  footerWidth: number;
  headerHeight: number;
  navHeight: number;

  textFieldOpen: boolean;
}

export const SET_FOOTER_WIDTH = 'SET_FOOTER_WIDTH';
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';
export const SET_NAV_HEIGHT = 'SET_NAV_HEIGHT';
export const SET_TEXT_FIELD_OPEN = 'SET_TEXT_FIELD_OPEN';

interface ISetFooterWidth {
  type: typeof SET_FOOTER_WIDTH;
  footerWidth: number;
}

interface ISetHeaderHeight {
  type: typeof SET_HEADER_HEIGHT;
  headerHeight: number;
}

interface ISetNavHeight {
  type: typeof SET_NAV_HEIGHT;
  navHeight: number;
}

interface ISetTextFieldOpen {
  type: typeof SET_TEXT_FIELD_OPEN;
  open: boolean;
}

export type ActionType =
  | ISetFooterWidth
  | ISetHeaderHeight
  | ISetNavHeight
  | ISetTextFieldOpen;
