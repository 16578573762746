import { useFormContext } from 'react-hook-form';
import { yes } from '../../../assets/values/yesNo.json';

export function useWatchedField(name: string, defaultValue?: string) {
  const { watch } = useFormContext();

  const stringValue = watch(name, defaultValue);
  const booleanValue = stringValue === yes.value;

  return { defaultValue, stringValue, booleanValue };
}
