import { GiftType } from '../../models/GiftType';

export interface IAppState {
  giftType: GiftType;
  footerWidth: number;
}

export const SET_GIFT_TYPE = 'SET_GIFT_TYPE';

interface ISetGiftType {
  type: typeof SET_GIFT_TYPE;
  giftType: GiftType;
}

export type ActionType = ISetGiftType;
