import { call, take, fork, put, select } from 'redux-saga/effects';
import * as Types from './types';
import * as GivingItemEndpoint from '../../api/endpoints/GivingItemEndpoint';
import { IGivingItemResponse } from '../../models/api/GivingItemResponse';
import { setGivingItems } from './actions';
import { setCartLegacyData } from '../cart/actions';
import { getMenu, getURLData, getGivingItems } from './selectors';
import { skuForMenu } from '../../models/LegacyURLData';
import { alertAPIError } from '../../util/APIUtils';

function* fetchGivingItem(menu: string) {
  try {
    const response: IGivingItemResponse = yield call(
      GivingItemEndpoint.fetchGivingItem,
      skuForMenu(menu)
    );

    const givingItems = response.item ? [response.item] : undefined;

    yield put(setGivingItems(givingItems));
  } catch (error) {
    // tslint:disable-next-line:no-console
    yield put(alertAPIError());
  }
}

function* watchMenuSaga() {
  while (true) {
    const lastMenu = yield select(getMenu);
    yield take(Types.SET_URL_DATA);
    const nextMenu = yield select(getMenu);

    if (lastMenu !== nextMenu) {
      yield fork(fetchGivingItem, nextMenu);
    }
  }
}

function* watchGivingItemSaga() {
  while (true) {
    yield take(Types.SET_ITEMS);
    const data = yield select(getURLData);
    const givingItems = yield select(getGivingItems);

    if (data && givingItems) {
      yield put(setCartLegacyData(data, givingItems));
    }
  }
}

export default function* legacySaga() {
  yield fork(watchMenuSaga);
  yield fork(watchGivingItemSaga);
}
