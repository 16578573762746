import React from 'react';
import SidebarLinks from '../../../../assets/values/SidebarLinks.json';
import { Accordion, IChildren } from './accordion';

import './sidebar.scss';
export const Sidebar = (props: IProps) => {
  const getSidebarLinks = () => {
    const sidebarLinks = SidebarLinks.map((link: ILink) => {
      if (link.children) {
        return (
          <React.Fragment key={link.title}>
            <Accordion title={link.title} children={link.children} />
          </React.Fragment>
        );
      } else {
        return (
          <li className='sidebar_links-link' key={link.title}>
            <span
              className={link.external ? 'sidebar_links-external' : ''}
            ></span>
            <a href={link.link}>{link.title}</a>
          </li>
        );
      }
    });
    return sidebarLinks;
  };
  return (
    <div
      className={
        'sidebar_container' +
        (props.sidebarVisible
          ? ' sidebar_container-open'
          : ' sidebar_container-closed')
      }
    >
      <div className='sidebar_search'>
        <form
          role='search'
          method='get'
          className='sidebar_search-form'
          id='sidebar_search-form'
          action={process.env.REACT_APP_SITE_ROOT}
        >
          <input
            type='search'
            className='sidebar_search-field'
            id='sidebar_search-field'
            placeholder='Search …'
            name='s'
            title='Search for:'
          />
          <button type='submit' className='sidebar_search-button'></button>
        </form>
      </div>

      <div className='sidebar_links'>
        <ul className='sidebar_list'>
          {getSidebarLinks()}
          <li className='give-now-button corner--pill'>
            <a
              target='_blank'
              href='https://giving.utexas.edu'
              rel='noopener noreferrer'
            >
              Give Now
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

interface IProps {
  sidebarVisible: boolean;
}

interface ILink {
  title: string;
  link: string;
  external: boolean;
  children?: IChildren[];
}
