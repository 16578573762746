import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store';
import { getGiftType } from '../../../store/app/selectors';
import {
  GiftType,
  GIFT_TYPE_SINGLE,
  GIFT_TYPE_PLEDGE,
} from '../../../models/GiftType';
import { useHeaderHeightSetter } from '../../effects/layout/HeaderHeight';

import './header.scss';

const mapStateToProps = (state: RootState) => {
  return { giftType: getGiftType(state) };
};

const connector = connect(mapStateToProps);

type IProps = ConnectedProps<typeof connector> & {
  hidden?: boolean;
};

const getGiftClassName = (giftType: GiftType) => {
  const baseClass = 'header-container';

  switch (giftType) {
    case GIFT_TYPE_SINGLE:
      return `${baseClass}--ogp`;
    case GIFT_TYPE_PLEDGE:
      return `${baseClass}--pledge`;
  }
};

const HeaderView = (props: IProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  useHeaderHeightSetter(containerRef);

  const isPledge = props.giftType === GIFT_TYPE_PLEDGE;
  const mobilePledgeContent = isPledge ? (
    <h1 className='ut-h2 header__pledge-mobile-title'>Pledge Pay</h1>
  ) : null;

  const titleContent = isPledge ? (
    'Pledge Pay'
  ) : (
    <React.Fragment>
      What Starts Here
      <br /> Starts With You
    </React.Fragment>
  );

  const bodyContent = isPledge ? (
    'Thank you for your ongoing support of The University of Texas at Austin.'
  ) : (
    <React.Fragment>
      Give now to support students, attract top faculty, transform health care
      and solve global problems.
    </React.Fragment>
  );

  const containerClass = `header-container`;
  const containerHiddenClass = props.hidden ? `${containerClass}--hidden` : '';

  return (
    <div
      ref={containerRef}
      className={`${containerClass} ${containerHiddenClass} ${getGiftClassName(
        props.giftType
      )}`}
    >
      {!props.hidden ? (
        <>
          {mobilePledgeContent}
          <div className='header-content'>
            <div className='header-divider'></div>
            <h1 className='header-title'> {titleContent}</h1>
            <p className='header-body'>{bodyContent}</p>
          </div>
        </>
      ) : null}
    </div>
  );
};

export const Header = connector(HeaderView);
