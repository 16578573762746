import { useSelect, UseSelectProps } from 'downshift';
import * as React from 'react';
import { useTextFieldOpenListener } from '../../wrappers/InputOpenListener';
import { ISharedInputProps } from '../textInput/InputWrapper';
import './Dropdown.scss';

const baseClassName = `ogp-dropdown`;

export const classes = {
  root: baseClassName,
  label: `${baseClassName}__label`,

  input: `${baseClassName}__input`,
  inputOpen: `${baseClassName}__input--open`,
  inputError: `${baseClassName}__input--error`,
  inputMissing: `${baseClassName}__input--missing`,
  inputStylePlaceholder: `${baseClassName}__input--light-text`,

  inputContainer: `${baseClassName}__input-container`,

  menu: `${baseClassName}__menu`,
  menuOpen: `${baseClassName}__menu--open`,
  menuEmpty: `${baseClassName}__menu--empty`,
  menuContainer: `${baseClassName}__menu-container`,

  arrowContainer: `${baseClassName}__arrow-container`,
  arrow: `${baseClassName}__arrow`,
  arrowOpen: `${baseClassName}__arrow--open`,

  item: `${baseClassName}__item`,
  itemSelected: `${baseClassName}__item--selected`,
  itemHighlighted: `${baseClassName}__item--highlighted`,

  // Only used in AddressLookupField (don't show error/valid/required icon on regular dropdown)
  inputConainerError: `${baseClassName}__input-container--error-icon`,
  inputContainerVaild: `${baseClassName}__input-container--valid-icon`,
  inputContainerRequired: `${baseClassName}__input-container--required-icon`,
};

export const Dropdown = (props: IProps) => {
  const { strictValues = false } = props;
  const inputEventProps = useTextFieldOpenListener(props);
  /* useSelect obliterates the initialValue if the "selectedItem" key is
    even specified. So this method is to conditionally add the selectedItem if
    it exists. */

  const getSelectProps = () => {
    const dict: UseSelectProps<any> = {
      items: props.options.map(opt => opt.label),
      onSelectedItemChange: option => {
        if (props.onChange) {
          props.onChange(option.selectedItem || '');
        }
      },
      initialSelectedItem: props.initialValue,
    };

    if (props.value) {
      dict.selectedItem = props.value;
    }

    return dict;
  };
  const {
    isOpen,
    getLabelProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    selectedItem: selected,
  } = useSelect(getSelectProps());

  let selectedItem = selected;

  const propsClassName = props.className || '';
  const menuOpenClass = isOpen ? classes.menuOpen : '';
  const menuEmptyClass = props.options.length ? '' : classes.menuEmpty;

  const inputOpenClass = isOpen ? classes.inputOpen : '';
  const arrowOpenClass = isOpen ? classes.arrowOpen : '';

  if (
    strictValues &&
    props.options.find(item => {
      return item.value === selectedItem;
    }) !== undefined
  ) {
    selectedItem = undefined;
  }

  const inputErrorClass = props.error ? classes.inputError : '';
  const inputStylePlaceholder = !selectedItem
    ? classes.inputStylePlaceholder
    : '';

  return (
    <div className={`${classes.root} ${propsClassName}`} onBlur={props.onBlur}>
      {props.label ? (
        <label {...getLabelProps()} className={classes.label}>
          {props.label}
        </label>
      ) : null}
      <button
        type='button'
        {...getToggleButtonProps({
          onBlur: props.onBlur,
        })}
        tabIndex={0}
        className={classes.inputContainer}
      >
        <div
          className={`${classes.input} ${inputOpenClass} ${inputErrorClass} ${inputStylePlaceholder}`}
        >
          {selectedItem || props.placeholder}
        </div>
        <div className={classes.arrowContainer}>
          <div className={`${classes.arrow} ${arrowOpenClass}`}></div>
        </div>
      </button>
      <div className={classes.menuContainer}>
        <ul
          className={`${classes.menu} ${menuOpenClass} ${menuEmptyClass}`}
          {...getMenuProps(inputEventProps)}
        >
          {isOpen
            ? props.options.map((item, index) => {
                const selectedClassName =
                  selectedItem === item.label ? classes.itemSelected : '';
                const highlighted =
                  highlightedIndex === index ? classes.itemHighlighted : '';
                return (
                  <li
                    key={`${item}${index}`}
                    className={`${classes.item} ${highlighted} ${selectedClassName}`}
                    {...getItemProps({
                      item: item.label,
                      index,
                    })}
                  >
                    {item.label}
                  </li>
                );
              })
            : null}
        </ul>
      </div>
    </div>
  );
};

export type DropdownOption = { label: string; value: any };

export type IProps = ISharedInputProps & {
  options: DropdownOption[];
  value?: string;
  initialValue?: string;
  strictValues?: boolean;

  disabled?: boolean;

  onChange?: (key: string) => void;
  onBlur?: (event: any) => void;
};
