import React from 'react';

import './TooltipModal.scss';
import { useOutsideAlerter } from '../../effects/OutsideAlerter';

export interface IProps {
  content?: React.ReactChild;
  onClose?: () => void;
}

export const TooltipModal = (props: IProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  useOutsideAlerter(containerRef, () => {
    if (props.onClose) {
      props.onClose();
    }
  });

  return (
    <div className='tooltip-modal' ref={containerRef}>
      {props.content}
      <button
        className='corner--pill tooltip-modal__close'
        onClick={props.onClose}
      >
        Close
      </button>
    </div>
  );
};

export const windowClassName = 'tooltip-modal__window';
