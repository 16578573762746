import React from 'react';
import Modal from 'react-modal';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../../store';
import { getModal, getPaymentModalOpen } from '../../../store/modal/selectors';
import { getTransactionProcessing } from '../../../store/transaction/selectors';

import * as Types from '../../../store/modal/types';
import { clearModal } from '../../../store/modal/actions';

import {
  PreviewNotificationModal,
  windowClassName as previewWindowClassName,
  TYPE_EMAIL,
  TYPE_CARD,
} from '../../modals/PreviewNotificationModal/PreviewNotificationModal';
import {
  AlertModal,
  windowClassName as alertWindowClassName,
} from '../../modals/AlertModal/AlertModal';
import {
  TooltipModal,
  windowClassName as tooltipWindowClassName,
} from '../../modals/TooltipModal/TooltipModal';
import {
  TransactionModal,
  windowClassName as transactionWindowClassName,
} from '../../modals/TransactionModal/TransactionModal';

import './ModalPresenter.scss';

const mapStateToProps = (state: RootState) => {
  return {
    paymentModalOpen: getPaymentModalOpen(state),
    modal: getModal(state),
    transactionProcessing: getTransactionProcessing(state),
  };
};

const mapDispatchToProps = { clearModal };
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export type IProps = ReduxProps & {
  htmlOpenClassName?: string;
  appElement: React.RefObject<HTMLElement>;
};

const modalContent = (props: IProps) => {
  if (props.transactionProcessing) {
    return {
      className: transactionWindowClassName,
      content: <TransactionModal />,
    };
  }

  switch (props.modal?.type) {
    case Types.MODAL_TYPE_PREVIEW_EMAIL:
      return {
        className: previewWindowClassName,
        content: (
          <PreviewNotificationModal
            dedicationTypeValue={props.modal.dedicationTypeValue}
            previewType={TYPE_EMAIL}
            onClose={props.clearModal}
          />
        ),
      };
    case Types.MODAL_TYPE_PREVIEW_CARD:
      return {
        className: previewWindowClassName,
        content: (
          <PreviewNotificationModal
            dedicationTypeValue={props.modal.dedicationTypeValue}
            previewType={TYPE_CARD}
            onClose={props.clearModal}
          />
        ),
      };
    case Types.MODAL_TYPE_ALERT:
      return {
        className: alertWindowClassName,
        content: (
          <AlertModal
            title={props.modal.title}
            message={props.modal.message}
            onClose={props.clearModal}
          />
        ),
      };
    case Types.MODAL_TYPE_TOOLTIP:
      return {
        className: tooltipWindowClassName,
        content: (
          <TooltipModal
            content={props.modal.content}
            onClose={props.clearModal}
          />
        ),
      };
  }
  return {};
};

const ModalPresenterComponent = (props: IProps) => {
  const { content, className } = modalContent(props);

  const hasContent = props.modal !== undefined || props.transactionProcessing;
  const isOpen = hasContent || props.paymentModalOpen;
  const paymentModalOpenClass = props.paymentModalOpen
    ? 'ogp-modal__overlay--payment-open'
    : '';

  return (
    <div>
      <Modal
        appElement={props.appElement.current || undefined}
        className={`modal__window ${className}`}
        isOpen={isOpen}
        htmlOpenClassName={props.htmlOpenClassName}
        overlayClassName={`ogp-modal__overlay ${paymentModalOpenClass}`}
      >
        {content}
      </Modal>
    </div>
  );
};

export const ModalPresenter = connector(ModalPresenterComponent);
