import React from 'react';

import { CartView } from '../../../containers/cart/CartView';
import { SearchView } from '../../../containers/search/ogp/SearchView';
import { useFooterWidthSetter } from '../../../effects/layout/FooterWidth';
import LegacyURLUnwrapper from '../../../wrappers/urlQueryUnwrapper/LegacyURLUnwrapper';

import './OGPHomePage.scss';

export const OGPHomePage = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  useFooterWidthSetter(containerRef);

  return (
    <LegacyURLUnwrapper>
      <div className='columns is-marginless'>
        <div className='column ogp-home__container--search' ref={containerRef}>
          <SearchView />
        </div>
        <div className='column ogp-home__container--cart'>
          <CartView />
        </div>
      </div>
    </LegacyURLUnwrapper>
  );
};
