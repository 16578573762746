import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { IGift } from '../../../../models/Gift';
import { Routes } from '../../../../router/Routes';
import { RootState } from '../../../../store';
import * as cartSelectors from '../../../../store/cart/singleGift/selectors';
import { getShowMissingFields } from '../../../../store/payment/selectors';
import FormKeys from '../../../../util/fieldNames/CheckoutFieldNames';
import { FormatCurrency } from '../../../../util/NumberFormat';
import { PaymentButton } from '../../../components/paymentButton/PaymentButton';
import { HookFormTextArea } from '../../../components/textInput/HookFormWrapper';
import { useFixedRightSide } from '../../../effects/layout/fixedRightSide/FixedRightSide';
import MissingFieldsView from '../../missingFields/MissingFieldsView';
import './GiftSummaryView.scss';

const mapStateToProps = (state: RootState) => {
  const getItemWithSkuCreator = cartSelectors.getGivingItemWithSkuCreator();
  return {
    gifts: cartSelectors.getCompletedGifts(state),
    itemCount: cartSelectors.getItemCount(state),
    total: cartSelectors.getTotalAmount(state),
    frequency: cartSelectors.getFrequency(state),
    duration: cartSelectors.getDuration(state),
    durations: cartSelectors.getDurations(state),
    getItemWithSku: getItemWithSkuCreator(state),
    showMissingFields: getShowMissingFields(state),
  };
};

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export type IProps = ReduxProps & RouteComponentProps & {};

const GiftSummaryViewComponent = (props: IProps) => {
  const { className: stickyTopClass } = useFixedRightSide();

  const [loaded, setLoaded] = React.useState(false);

  if (!loaded) {
    setLoaded(true);
  }

  const form = useFormContext();

  const RenderGift = (gift: IGift) => {
    const giftName = props.getItemWithSku(gift.givingItemSku)
      .givingItemSignature.name;

    return (
      <div className='d--f jc--sb' key={gift.givingItemSku}>
        <p className='gift-summary__item-name'>{giftName}</p>
        <p className='gift-summary__item-amount'>
          {FormatCurrency(gift.amount)}
        </p>
      </div>
    );
  };

  const durationLabel = props.duration
    ? props.durations?.find(element => element.value === props.duration)?.label
    : undefined;

  return (
    <div className='gift-summary__container'>
      <div className={`gift-summary ${stickyTopClass}`}>
        <div className='gift-summary__header'>
          <h4 className='gift-summary__title'>Gift Summary</h4>
          <div className='gift-summary__item-count'>{props.itemCount}</div>
        </div>
        <div className='gift-summary__gifts'>{props.gifts.map(RenderGift)}</div>
        <div className='gift-summary__frequency'>
          <div className='gift-summary__frequency__item'>
            <p>
              Frequency:&nbsp;
              <span className='gift-summary__frequency-value'>
                {props.frequency?.label}
              </span>
            </p>
          </div>
          {props.frequency && props.frequency.value !== 'onetime' ? (
            <div className='gift-summary__frequency__item'>
              <p>
                Duration:&nbsp;
                <span className='gift-summary__frequency-value'>
                  {durationLabel}
                </span>
              </p>
            </div>
          ) : null}
        </div>
        <div className='gift-summary__total'>
          <h5 className='ut-h5'>Total</h5>
          <h5 className='ut-h5'>{FormatCurrency(props.total)}</h5>
        </div>
        <div className='gift-summary__footer'>
          <h4 className='gift-summary__comments'>Gift Comments</h4>
          <Controller
            as={HookFormTextArea}
            control={form.control}
            name={FormKeys.fields.giftRemarks.name}
            className=''
            maxLength={120}
            countOnTop
            required={FormKeys.fields.giftRemarks.required}
          />

          <MissingFieldsView
            form={form}
            showMissing={props.showMissingFields}
          />

          {props.frequency && props.frequency.value !== 'onetime' && (
            <div className='automatic-payment-acknowledgement'>
              <p className='acknowledgement'>
                By clicking "Proceed to Payment," you confirm that your pledged
                donation will automatically process and your credit card will
                automatically be charged the donation amount according to the
                payment schedule you have selected.
              </p>
              <p className='acknowledgement'>
                You will continue to be charged until your payment schedule is
                complete or you cancel your pledge.
              </p>
            </div>
          )}
        </div>

        <div className='d--f jc--sb ai--c'>
          <Link to={Routes.Sections.Search.path} className='ut-link--secondary'>
            Go back
          </Link>
          <PaymentButton />
        </div>
      </div>
    </div>
  );
};

export const GiftSummaryView = connector(withRouter(GiftSummaryViewComponent));
