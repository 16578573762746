import * as Types from './types';

export const initialState: Types.IStore = {};

export function miniEmbedReducer(
  state = initialState,
  action: Types.ActionTypes
): Types.IStore {
  switch (action.type) {
    case Types.SET_DARK:
      return { ...state, dark: action.dark };
    case Types.SET_AMOUNT_BUTTONS:
      return { ...state, amountButtons: action.amountButtons };
    case Types.SET_SKU:
      return { ...state, sku: action?.sku };
    case Types.SET_AMOUNT:
      return { ...state, amount: action.amount };
    case Types.SET_GIVING_ITEM:
      return { ...state, givingItem: action.givingItem };
  }
  return state;
}
