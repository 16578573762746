import { isNumber } from 'util';

import singleGiftFrequenciesJSON from '../../../assets/values/singleGiftFrequencies.json';
import { GIFT_TYPE_SINGLE } from '../../models/GiftType';
import { IGivingItem } from '../../models/GivingItem';
import { ILegacyURLData } from '../../models/LegacyURLData';
import {
  IStore as ICartStore,
  SkusToSingleGiftBuilders,
} from '../../store/cart/types';
import { CART } from '../../util/URLKeys';

function isCartStore(object: any): object is ICartStore {
  return 'builders' in object && 'attributes' in object;
}

interface IEmbedCartProps {
  amount?: number;
  frequencyValue?: string;
  givingItems: IGivingItem[];
}

export const buildCartFromEmbedOptions: (
  props: IEmbedCartProps
) => ICartStore | undefined = props => {
  if (!props.amount || !props.frequencyValue || !props.givingItems.length) {
    return undefined;
  }

  const singleGiftBuilders: SkusToSingleGiftBuilders = props.givingItems.reduce(
    (dict, givingItem) => ({
      ...dict,
      [givingItem.givingItemSignature.sku]: {
        type: GIFT_TYPE_SINGLE,
        amount: props.amount,
        givingItem,
      },
    }),
    {}
  );
  return {
    builders: {
      SINGLE: singleGiftBuilders,
      PLEDGE: {},
    },
    attributes: {
      SINGLE: {
        frequency: props.frequencyValue,
      },
      PLEDGE: {},
    },
  };
};

export const buildCartFromMiniEmbedOptions = (
  givingItem: IGivingItem,
  amount?: number
) => {
  const embedCartProps: IEmbedCartProps = {
    givingItems: [givingItem],
    frequencyValue: singleGiftFrequenciesJSON.oneTime.value,
    amount,
  };

  return buildCartFromEmbedOptions(embedCartProps);
};

export const buildCartFromLegacyData: (
  data: ILegacyURLData,
  items: IGivingItem[]
) => ICartStore | undefined = (data, items) => {
  const amountNumber = parseFloat(data.amount || '');

  const singleGiftBuilders: SkusToSingleGiftBuilders = items.reduce(
    (dict, givingItem) => ({
      ...dict,
      [givingItem.givingItemSignature.sku]: {
        type: GIFT_TYPE_SINGLE,
        amount: isNumber(amountNumber) ? amountNumber : undefined,
        givingItem,
      },
    }),
    {}
  );

  return {
    builders: {
      SINGLE: singleGiftBuilders,
      PLEDGE: {},
    },
    attributes: {
      SINGLE: { frequency: data.frequency, duration: data.duration },
      PLEDGE: {},
    },
  };
};

export const createCartURLParams = (cart: ICartStore) => {
  const serializedCart = btoa(escape(JSON.stringify(cart)));

  const params = new URLSearchParams();
  params.append(CART, serializedCart);
  return params;
};

export const unwrapCartFromURLParams = (params: URLSearchParams) => {
  try {
    const cartData = params.get(CART);

    if (cartData) {
      const cartDataString = unescape(atob(cartData));
      const newCart = JSON.parse(cartDataString);

      if (isCartStore(newCart)) {
        return newCart;
      }
    }
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
  }
};
