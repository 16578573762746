import shortid from 'shortid';
import * as Types from './types';

const generateID = () => shortid.generate();

const initialState: Types.IPaymentState = {
  formIsValid: false,
  showMissingFields: false,
  uniqueID: generateID(),
};

export function paymentReducer(state = initialState, action: Types.ActionType) {
  switch (action.type) {
    case Types.SET_FORM_DATA:
      return { ...state, formData: action.formData };
    case Types.RESET_FORM_DATA:
      return { ...state, formData: undefined, showMissingFields: false };
    case Types.SET_FORM_VALID:
      return { ...state, formIsValid: action.valid };
    case Types.REFRESH_UNIQUE_ID:
      return { ...state, uniqueID: generateID() };
    case Types.SET_FORM_MISSING_FIELDS:
      return { ...state, showMissingFields: action.showMissingFields };
  }

  return state;
}
