import React, { useEffect, useState } from 'react';
import loadingSpinner from '../../../../../assets/images/loading-spinner.gif';
import { IGivingItem } from '../../../../models/GivingItem';
import { MINIMIZED_ITEM_COUNT } from '../../../../store/search/types';
import { SearchFieldPlaceholder } from '../../../../util/copy/SearchCopy';
import { UnwrapEventWithCallback } from '../../../components/textInput/InputWrapper';
import { TextInput } from '../../../components/textInput/TextInput';
import './SearchView.scss';
import { connectSearchProps, createOnClick, IProps } from './SearchViewProps';

const RenderItemButton = (props: IProps, item: IGivingItem) => {
  const inCart = props.isItemInCart(item.givingItemSignature.sku);
  const buttonActiveClass = inCart ? 'active' : '';

  if (!props.styleMobile) {
    return (
      <div className='search-view__item-add'>
        <button
          onClick={createOnClick(props, item)}
          className={`ut-btn--secondary btn--small w--100 corner--pill ${buttonActiveClass}`}
        >
          {inCart ? 'Added' : 'Add'}
        </button>
      </div>
    );
  } else {
    return (
      <button
        onClick={createOnClick(props, item)}
        className={`ut-btn--circle ${buttonActiveClass}`}
      />
    );
  }
};

const RenderGivingItem = (props: IProps) => (item: IGivingItem) => {
  const { sku, name, description } = item.givingItemSignature;

  const mobileClassModifier = props.styleMobile ? '--mobile' : '';

  return (
    <div
      className={`search-view__item${mobileClassModifier}`}
      key={`${sku}-${name}`}
    >
      <div className='search-view__item-info'>
        <h5 className='search-view__item-title'>{name}</h5>
        <p className={`search-view__item-description${mobileClassModifier}`}>
          {description}
        </p>
      </div>
      {RenderItemButton(props, item)}
    </div>
  );
};

const RenderEmptyView = (query: string) => (
  <p className='search-view__empty'>
    We couldn't find a match for "{query}". Please try another search.
  </p>
);

const LoadingView = () => (
  <div className='search-view__loading'>
    <img className='search-view__loading-spinner' src={loadingSpinner} alt='' />
  </div>
);

const SearchViewComponent = (props: IProps) => {
  const [selectedFacetValue, _setSelectedFacetValue] = useState<string>();

  useEffect(() => {
    if (props.queryFacets && props.queryFacets.length) {
      _setSelectedFacetValue(props.queryFacets[0].value);
    }
  }, [props.queryFacets]);

  const selectedFacetObject = props.queryFacets?.find(
    facet => facet.value === selectedFacetValue
  );

  const allGivingItems = props.getItemsForFacetName(selectedFacetValue);
  const firstGivingItems = allGivingItems?.slice(0, MINIMIZED_ITEM_COUNT);

  const givingItems = props.showMore ? allGivingItems : firstGivingItems;
  const givingItemViews = givingItems?.map(item =>
    RenderGivingItem({ ...props })(item)
  );

  const showEmptyView =
    (!allGivingItems || allGivingItems?.length === 0) && !props.loading;
  const mobileClassModifier = props.styleMobile ? '--mobile' : '';

  return (
    <div className={`search-view${mobileClassModifier}`}>
      <div className='search-view__title' />
      <div className={`search-view__input-container${mobileClassModifier}`}>
        <TextInput
          styleSearch
          value={props.query}
          onChange={UnwrapEventWithCallback(value => {
            props.setSearchQuery(value || '');
          })}
          placeholder={SearchFieldPlaceholder(selectedFacetObject)}
        />
      </div>
      <div className='search-view__subtitle'>
        Use the search bar above to explore giving opportunities that interest
        you. Type “other” if you can’t find what you’d like to support.
      </div>

      <div className='search-view__giving-items-container'>
        {!showEmptyView ? givingItemViews : RenderEmptyView(props.query)}
        {props.loading ? <LoadingView /> : null}
        {!props.showMore &&
        (allGivingItems?.length || 0) > MINIMIZED_ITEM_COUNT ? (
          <button
            className='search-view__show-more ut-btn--secondary corner--pill'
            onClick={() => props.setShowMore(true)}
          >
            More Funds
          </button>
        ) : null}
      </div>
    </div>
  );
};

export const SearchView = connectSearchProps(SearchViewComponent);
