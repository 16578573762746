import React from 'react';
import ReactTooltip from 'react-tooltip';

import { tooltip } from '../../../store/modal/actions';
import { setMessage } from '../../../store/embed/actions';
import { useDispatch } from 'react-redux';

import './InfoTooltip.scss';

export const InfoTooltip = (props: IProps) => {
  const className = props.className || '';

  const dispatch = useDispatch();
  const onMobileTooltipClick = () => {
    if (props.isEmbedded) {
      dispatch(setMessage({ title: '', message: props.tooltipContent }));
    } else {
      dispatch(tooltip(props.tooltipContent));
    }
  };

  return (
    <div className={`info-tooltip ${className}`}>
      <button
        className='info-tooltip__button info-tooltip__button--large'
        data-tip={props.tooltipContent}
      >
        ?
      </button>
      <button
        className={`info-tooltip__button info-tooltip__button--mobile`}
        onClick={onMobileTooltipClick}
      >
        ?
      </button>
      <ReactTooltip
        multiline
        effect='solid'
        delayHide={250}
        clickable
        className='info-tooltip__tooltip'
      >
        {props.tooltipContent}
      </ReactTooltip>
    </div>
  );
};

export interface IProps {
  tooltipContent?: React.ReactChild;
  className?: string;

  // OGP Widget only
  isEmbedded?: boolean;
}
