import * as Types from './types';

export const initialState: Types.IExpirationState = {};

export function expirationReducer(
  state = initialState,
  action: Types.ActionType
) {
  switch (action.type) {
    case Types.SET_CART_TIME_STAMP: {
      return {
        ...state,
        cartTimeStamp: action.timeStamp,
      };
    }
  }

  return state;
}
