import { take, fork, put, select, delay } from 'redux-saga/effects';
import * as Types from './types';
import * as CartTypes from '../cart/types';
import { clearCart } from '../cart/actions';
import { setCartTimeStamp } from './actions';
import { getCartTimeStamp } from './selectors';

const CHECK_TIME_STAMP_INTERVAL_MS = 1000 * 60;

function* updateCartTimeStamp() {
  const date = new Date();
  yield put(setCartTimeStamp(date));
}

function* checkCartChange() {
  while (true) {
    yield take([
      CartTypes.ADD_GIVING_ITEM,
      CartTypes.REMOVE_GIVING_ITEM,
      CartTypes.CLEAR_CART,
      CartTypes.UPDATE_SINGLE_GIFT_AMOUNT,
      CartTypes.UPDATE_ALL_SINGLE_GIFT_AMOUNTS,
      CartTypes.UPDATE_GIFT_FREQUENCY,
      CartTypes.UPDATE_GIFT_END_DATE,
    ]);

    yield fork(updateCartTimeStamp);
  }
}

function* checkTimeStamps() {
  const now = new Date();

  const cartTimeStampString = yield select(getCartTimeStamp);
  const cartTimeStamp = new Date(cartTimeStampString);
  if (
    now.getTime() - cartTimeStamp?.getTime() >
    Types.CART_EXPIRATION_TIME_MS
  ) {
    yield put(clearCart());
  }
}

function* checkTimeStampTimer() {
  while (true) {
    yield delay(CHECK_TIME_STAMP_INTERVAL_MS);
    yield fork(checkTimeStamps);
  }
}

export default function* expirationSaga() {
  yield fork(checkCartChange);
  yield fork(checkTimeStampTimer);
}
