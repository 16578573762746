import { createSelector } from 'reselect';
import { RootState } from '..';

import { IGivingItem } from '../../models/GivingItem';

export const getSearchState = (state: RootState) => state.search;

export const getQuery = createSelector([getSearchState], state => state.query);
export const getSearchQuery = createSelector(
  [getSearchState],
  state => state.searchQuery
);

export const getLoading = createSelector(
  [getSearchState],
  state => state.loading
);
export const getDepartment = createSelector(
  [getSearchState],
  state => state.department
);
export const getDepartmentCode = createSelector(
  [getSearchState],
  state => state.departmentCode
);
export const getFeaturedArea = createSelector(
  [getSearchState],
  state => state.featuredArea
);
export const getQueryFacets = createSelector(
  [getSearchState],
  state => state.queryFacets
);
export const getShowMore = createSelector(
  [getSearchState],
  state => state.showMore
);
export const getFacets = createSelector(getSearchState, state => state.facets);
export const isQueryValid = createSelector(
  [getQuery],
  query => query.length >= 3 || query.length === 0
);

export const getGivingItemError = createSelector(
  [getSearchState],
  state => state.givingItemError
);

export const getAllGivingItems = createSelector([getFacets], facets => {
  return facets?.reduce<IGivingItem[]>((givingItems, facet) => {
    const notAlreadyIncluded = (item: IGivingItem) =>
      !givingItems.find(
        testItem =>
          testItem.givingItemSignature.sku === item.givingItemSignature.sku
      );
    return givingItems.concat(facet.givingItems.filter(notAlreadyIncluded));
  }, []);
});

const getFacetWithNameCreator = () =>
  createSelector(
    [getFacets],
    facets => (facetName?: string) =>
      facets?.find(facet => facet.facetTerm === facetName)
  );

export const getGivingItemsForFacetNameCreator = () =>
  createSelector(
    [getFacetWithNameCreator()],
    getFacetWithName => (name?: string) => getFacetWithName(name)?.givingItems
  );
