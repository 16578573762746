import { createCartURLParams } from './serialization/SerializeCart';
import { IStore as ICartStore } from '../store/cart/types';
import { DEPARTMENT_CODE as departmentKey } from './URLKeys';
import { Routes } from '../router/Routes';
import { ILegacyURLData } from '../models/LegacyURLData';

export const checkoutLink = (
  cart?: ICartStore,
  referrer?: string,
  urlData?: ILegacyURLData
) => {
  if (cart) {
    const urlParams = createCartURLParams(cart);

    let queryString = urlParams.toString();

    if (referrer) {
      queryString = `${queryString}&referrer=${referrer}`;
    }

    if (urlData?.codings?.solicit) {
      queryString = `${queryString}&solicit=${urlData.codings.solicit}`;
    }

    return `${process.env.REACT_APP_SITE_ROOT}/checkout?${queryString}`;
  }
};

export const viewAllLink = (departmentCode?: string) => {
  const queryString = departmentCode
    ? `?${departmentKey}=${departmentCode}`
    : '';
  return `${Routes.Roots.OGP.path}${queryString}`;
};
