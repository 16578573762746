import * as React from 'react';

import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { RootState } from '../../../store';
import { Routes } from '../../../router/Routes';
import * as selectors from '../../../store/transaction/selectors';

const mapStateToProps = (state: RootState) => ({
  transaction: selectors.getTransaction(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const CheckoutToConfirmationRedirectView = (props: ReduxProps) =>
  props.transaction ? <Redirect to={Routes.Roots.Confirmation.path} /> : null;

export const CheckoutToConfirmationRedirect = connector(
  CheckoutToConfirmationRedirectView
);
