import provincesJSON from '../../../assets/values/provinces.json';

const provinceObjectForCountry = (countryName: string) =>
  provincesJSON.find(province => province.name === countryName);

export const provincesForCountry = (countryName: string) =>
  provinceObjectForCountry(countryName)?.provinces?.map(name => ({
    value: name,
    label: name,
  }));

export const provinceLabelForCountry = (countryName: string) =>
  provinceObjectForCountry(countryName)?.provinceName || 'Province';
