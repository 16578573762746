export const StripNonNumericCharacters = (input?: string) =>
  input ? input.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') : input;

export const HasNonNumericCharacters = (input?: string) =>
  input ? !/^\d*\.?\d*$/.test(input) : false;

export const MakeTitleCase = (input?: string) => {
  if (!input) {
    return input;
  }

  const str = input.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
};

export const getGiftCountString = (count: number) => {
  const fundString = count > 1 ? 'funds' : 'fund';
  return `${count} ${fundString} selected`;
};

export const getDurationInYearsString = (years?: number) => {
  if (!years) return '';

  const yearString = years > 1 ? 'years' : 'year';
  return `${years} ${yearString}`;
};
