import * as Types from './types';
import { ITransactionResponse } from '../../models/api/TransactionResponse';

export function setTransactionProcessing(
  processing: boolean
): Types.ActionType {
  return {
    type: Types.SET_TRANSACTION_PROCESSING,
    processing,
  };
}

export function updateTransaction(
  transactionResponse?: ITransactionResponse
): Types.ActionType {
  return {
    type: Types.UPDATE_TRANSACTION,
    transactionResponse,
  };
}

export function clearTransaction(): Types.ActionType {
  return {
    type: Types.UPDATE_TRANSACTION,
  };
}
