import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import useComponentSize from '@rehooks/component-size';

import { EmbedItemsPage } from './pages/items/EmbedItemsPage';
import { EmbedAmountPage } from './pages/amount/EmbedAmountPage';

import { RootState } from '../../../store';
import {
  getMessage,
  getGivingItems,
  getDepartmentCode,
  getFixedHeight,
} from '../../../store/embed/selectors';
import { setMessage, clearMessage } from '../../../store/embed/actions';
import { IGivingItem } from '../../../models/GivingItem';
import { viewAllLink } from '../../../util/EmbedLinks';
import { EmbedErrorPresenter } from './micros/messagePresenter/EmbedMessagePresenter';
import { buildCartFromEmbedOptions } from '../../../util/serialization/SerializeCart';

import './EmbedView.scss';

const PAGE_ITEMS = 'PAGE_ITEMS';
const PAGE_AMOUNT = 'PAGE_AMOUNT';
type EmbedPage = typeof PAGE_ITEMS | typeof PAGE_AMOUNT;

const MAX_VISIBLE_GIFTS = 4;

const mapStateToProps = (state: RootState) => {
  return {
    givingItems: getGivingItems(state)?.slice(0, MAX_VISIBLE_GIFTS),
    error: getMessage(state),
    departmentCode: getDepartmentCode(state),
    fixedHeight: getFixedHeight(state),
  };
};

const mapDispatchToProps = {
  clearError: clearMessage,
  setError: setMessage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type IProps = ConnectedProps<typeof connector> & {
  // Additional props here
};

export const baseClassName = 'embed-view';

const EmbedViewComponent = (props: IProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { height } = useComponentSize(containerRef);
  useEffect(() => {
    window.top.postMessage({ height: `${height}px` }, '*');
  }, [height]);

  const [currentPage, setCurrentPage] = React.useState<EmbedPage>(PAGE_ITEMS);

  const [selectedItems, setSelectedItems] = React.useState<IGivingItem[]>([]);

  const [amount, setAmount] = React.useState<number | undefined>();

  const [frequencyValue, setFrequencyValue] = React.useState<
    string | undefined
  >();

  const cart = buildCartFromEmbedOptions({
    amount,
    frequencyValue,
    givingItems: selectedItems,
  });

  return (
    <React.Fragment>
      <EmbedErrorPresenter
        message={props.error}
        clearMessage={props.clearError}
      />
      <div className={`${baseClassName}`} ref={containerRef}>
        <div className={`${baseClassName}__content`}>
          <div className={`${baseClassName}__page-frame`}>
            <EmbedItemsPage
              givingItems={props.givingItems || []}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              onCartFullError={createOnCartFullError(props)}
              nextButtonEnabled={selectedItems.length > 0}
              onNextButtonPressed={() => setCurrentPage(PAGE_AMOUNT)}
              hidden={currentPage !== PAGE_ITEMS}
            />
            <EmbedAmountPage
              frequencyValue={frequencyValue}
              setFrequencyValue={setFrequencyValue}
              amount={amount}
              setAmount={setAmount}
              cart={cart}
              onBackPressed={() => setCurrentPage(PAGE_ITEMS)}
              hidden={currentPage !== PAGE_AMOUNT}
              fixedHeight={props.fixedHeight}
            />
          </div>
          <a
            target='blank'
            className={`${baseClassName}__all-funds`}
            href={viewAllLink(props.departmentCode)}
          >
            Want more options? Search all funds.
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

const createOnCartFullError = (props: IProps) => () =>
  props.setError({
    title: 'Gift Maximum Exceeded',
    message: 'The maximum number of gifts is three.',
  });

export const EmbedView = connector(EmbedViewComponent);
