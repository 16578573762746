import React from 'react';
import titles from '../../../../../assets/values/titles.json';
import { CheckoutField } from '../../../../util/fieldNames/CheckoutFieldNames';
import { minLengthRegex } from '../../../../util/ValidationUtils';
import {
  IInputFieldGroupProps,
  InputFieldGroup,
} from '../../../wrappers/InputFieldGroup';
import {
  HookFormDropdown,
  HookFormTextInput,
} from '../../textInput/HookFormWrapper';

export interface INameFieldsProps {
  fieldLabelPrefix?: string;
  hideTitle?: boolean;
  singleLine?: boolean;
  showMissing: boolean;
  title?: CheckoutField;
  given: CheckoutField;
  family: CheckoutField;
}

export const NameFieldsView = (
  props: IInputFieldGroupProps & INameFieldsProps
) => {
  const {
    title,
    given,
    family,
    hideTitle = false,
    injectControlledInput,
    singleLine,
    showMissing,
  } = props;

  const getLabelWithPrefix = (label: string) =>
    props.fieldLabelPrefix?.length
      ? `${props.fieldLabelPrefix} ${label}`
      : label;

  const titleControl = hideTitle
    ? null
    : injectControlledInput({
        as: HookFormDropdown,
        nameOrSuffix: title?.name,
        options: titles,
        className: 'column is-one-quarter',
        label: 'Title (optional)',
        placeholder: 'None',
        required: title?.required,
      });

  const inputs = singleLine ? (
    injectControlledInput({
      as: HookFormTextInput,
      nameOrSuffix: given.name,
      label: getLabelWithPrefix('Name'),
      className: 'column',
      rules: {
        required: given.required,
        pattern: {
          value: minLengthRegex,
          message: 'Name is too short.',
        },
      },
      placeholder: 'Name',
      missing: showMissing,
      required: given.required,
    })
  ) : (
    <>
      {titleControl}
      {injectControlledInput({
        as: HookFormTextInput,
        nameOrSuffix: given.name,
        label: getLabelWithPrefix('First Name'),
        className: 'column',
        rules: {
          required: given.required,
          pattern: {
            value: minLengthRegex,
            message: 'Name is too short.',
          },
        },
        placeholder: 'First Name',
        missing: showMissing,
        required: given.required,
      })}
      {injectControlledInput({
        as: HookFormTextInput,
        nameOrSuffix: family.name,
        className: 'column',
        label: getLabelWithPrefix('Last name'),
        rules: {
          required: family.required,
          pattern: {
            value: minLengthRegex,
            message: 'Last name is too short.',
          },
        },
        missing: showMissing,
        required: family.required,
        placeholder: 'Last Name',
      })}
    </>
  );

  return inputs;
};

export const NameFields = InputFieldGroup(NameFieldsView);
