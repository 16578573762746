import { createSelector } from 'reselect';
import { RootState } from '..';

export const getEmbedState = (state: RootState) => state.embed;

const getSkus = createSelector(getEmbedState, state => state.skus);

export const getDepartmentTitle = createSelector(
  [getEmbedState],
  state => state.departmentTitle
);

export const getDepartmentCode = createSelector(
  [getEmbedState],
  state => state.departmentCode
);

const getGivingItemsDict = createSelector(
  getEmbedState,
  state => state.givingItems
);

const getSkuGivingItems = createSelector(getGivingItemsDict, dict => dict?.sku);
const getDepartmentGivingItems = createSelector(
  getGivingItemsDict,
  dict => dict.department
);

export const getGivingItems = createSelector(
  [getSkus, getDepartmentCode, getSkuGivingItems, getDepartmentGivingItems],
  (skus, departmentCode, skuItems, departmentItems) => {
    if (skus && skus.length > 0) {
      return skuItems;
    } else if (departmentCode) {
      return departmentItems;
    }
  }
);

export const getMessage = createSelector(
  [getEmbedState],
  state => state.message
);

export const getFixedHeight = createSelector(
  [getEmbedState],
  state => state.fixedHeight
);
