import * as React from 'react';
import { connect } from 'react-redux';

import { ITabBarItem } from '../../components/tabBar/TabBar';

import * as cartSelectors from '../../../store/cart/singleGift/selectors';
import { RootState } from '../../../store';
import { appendBasePath, Routes } from '../../../router/Routes';

import { MobileNav } from './MobileNav';

const mapStateToProps = (state: RootState) => {
  return {
    amountViewEnabled: cartSelectors.getFrequency(state) !== undefined,
    checkoutViewEnabled: cartSelectors.getCartIsValid(state),
  };
};

export interface IProps {
  basePath: string;
  amountViewEnabled: boolean;
  checkoutViewEnabled: boolean;
}

const OGPMobileNavView = (props: IProps) => {
  const appendOGPPath = appendBasePath(props.basePath);

  const tabBarItems: ITabBarItem[] = [
    {
      label: Routes.Sections.Search.label,
      value: appendOGPPath(Routes.Sections.Search.path),
      styleHighlighted: true,
    },
    {
      label: Routes.Sections.Frequency.label,
      value: appendOGPPath(Routes.Sections.Frequency.path),
    },
    {
      label: Routes.Sections.Amount.label,
      value: appendOGPPath(Routes.Sections.Amount.path),
      disabled: !props.amountViewEnabled,
    },
    {
      label: Routes.Sections.Checkout.label,
      value: appendOGPPath(Routes.Sections.Checkout.path),
      disabled: !props.checkoutViewEnabled,
    },
  ];

  return <MobileNav basePath={props.basePath} items={tabBarItems} />;
};

const connector = connect(mapStateToProps);
export const OGPMobileNav = connector(OGPMobileNavView);
