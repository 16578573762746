import * as Types from './types';

const initialState: Types.IStore = {};

export function legacyReducer(state = initialState, action: Types.ActionTypes) {
  switch (action.type) {
    case Types.SET_URL_DATA:
      return { ...state, urlData: action.urlData };
    case Types.SET_ITEMS:
      return { ...state, givingItems: action.givingItems };
  }
  return state;
}
