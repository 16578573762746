import { call, take, fork, put, select } from 'redux-saga/effects';
import * as Types from './types';
import * as GivingItemEndpoint from '../../api/endpoints/GivingItemEndpoint';
import { IGivingItemResponse } from '../../models/api/GivingItemResponse';
import { alertAPIError } from '../../util/APIUtils';
import { setGivingItem } from './actions';
import { getSku } from './selectors';

function* fetchGivingItem(sku: string) {
  try {
    const response: IGivingItemResponse = yield call(
      GivingItemEndpoint.fetchGivingItem,
      sku
    );

    yield put(setGivingItem(response.item));
  } catch (error) {
    yield put(alertAPIError());
  }
}

function* watchSkuSaga() {
  while (true) {
    const lastSku = yield select(getSku);
    yield take(Types.SET_SKU);
    const newSku = yield select(getSku);

    if (lastSku !== newSku) {
      yield fork(fetchGivingItem, newSku);
    }
  }
}

export default function* miniEmbedSaga() {
  yield fork(watchSkuSaga);
}
