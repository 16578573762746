import * as Types from './types';
import { IGivingItem } from '../../models/GivingItem';

export function setDark(dark?: boolean): Types.ActionTypes {
  return {
    type: Types.SET_DARK,
    dark,
  };
}
export function setSku(sku?: string): Types.ActionTypes {
  return {
    type: Types.SET_SKU,
    sku,
  };
}
export function setAmountButtons(amountButtons?: number[]): Types.ActionTypes {
  return {
    type: Types.SET_AMOUNT_BUTTONS,
    amountButtons,
  };
}
export function setGivingItem(givingItem?: IGivingItem): Types.ActionTypes {
  return {
    type: Types.SET_GIVING_ITEM,
    givingItem,
  };
}
export function setAmount(amount?: number): Types.ActionTypes {
  return {
    type: Types.SET_AMOUNT,
    amount,
  };
}
