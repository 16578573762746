import React from 'react';
import { CheckoutForm } from '../../../containers/checkoutForm/desktop/CheckoutForm';
import { CheckoutNoCartRedirect } from '../../../router/redirects/CheckoutNoCartRedirect';
import { CheckoutToConfirmationRedirect } from '../../../router/redirects/CheckoutToConfirmationRedirect';
import CartQueryUnwrapper from '../../../wrappers/urlQueryUnwrapper/CartQueryUnwrapper';
import ReferrerQueryUnwrapper from '../../../wrappers/urlQueryUnwrapper/ReferrerQueryUnwrapper';

export const CheckoutPage = () => {
  return (
    <>
      <ReferrerQueryUnwrapper>
        <CartQueryUnwrapper>
          <CheckoutNoCartRedirect />
          <CheckoutToConfirmationRedirect />
          <CheckoutForm />
        </CartQueryUnwrapper>
      </ReferrerQueryUnwrapper>
    </>
  );
};
