import React from 'react';
import { IGivingItemSignature } from '../../../../models/GivingItemSignature';
import {
  NumberWithCommas,
  FormatCurrency,
} from '../../../../util/NumberFormat';
import { errorForCurrencyInput } from '../../../../util/ValidationUtils';
import { CurrencyInput } from '../../../components/textInput/CurrencyInput';
import './GiftBuilderView.scss';

export const GiftBuilderView = (props: IProps) => {
  const rootRef = React.createRef<HTMLDivElement>();

  const [customAmountError, setCustomAmountError] = React.useState<string>();

  const [customAmount, setCustomAmount] = React.useState<
    number | string | undefined
  >(() => {
    const startsWithCustomAmount =
      props.amounts && props.amounts.findIndex(n => n === props.value) < 0;
    return startsWithCustomAmount ? props.value : undefined;
  });

  const onPresetValueUpdated = (amount: number) => () => {
    if (props.onValueChanged) {
      props.onValueChanged(props.givingItemSignature.sku, amount);
    }

    setCustomAmount('');
    setCustomAmountError(undefined);
  };

  const onCustomValueUpdated = (value?: string) => {
    let parsedValue = value ? parseFloat(value) : undefined;
    const errorMsg = errorForCurrencyInput(parsedValue);

    if (errorMsg) {
      parsedValue = undefined;
    }

    if (props.onValueChanged) {
      props.onValueChanged(props.givingItemSignature.sku, parsedValue);
    }

    setCustomAmountError(errorMsg);
    setCustomAmount(value);
  };

  const onDeleteClicked = () => {
    if (props.onRemove) {
      props.onRemove(props.givingItemSignature.sku);
    }
  };

  return (
    <div className={`gift-builder`} ref={rootRef}>
      <div className='gift-builder__header'>
        <p className='gift-builder__name'>{props.givingItemSignature.name}</p>
        <div className='gift-builder__right-container'>
          <p className='gift-builder__amount-value'>
            {FormatCurrency(props.value)}
          </p>
          <button
            className='ut-link--secondary gift-builder__delete'
            onClick={onDeleteClicked}
          />
        </div>
      </div>
      <h6 className='gift-builder__amount-title'>Gift Amount</h6>
      <div className='gift-builder__amounts'>
        {props.amounts?.map(amount => {
          const buttonActive = amount === props.value && !customAmount;

          return (
            <button
              key={`${amount}`}
              onClick={onPresetValueUpdated(amount)}
              className={`ut-btn--secondary corner--pill gift-builder__amount ${
                buttonActive ? 'active' : ''
              }`}
            >
              ${NumberWithCommas(amount)}
            </button>
          );
        })}
      </div>
      <CurrencyInput
        placeholder='My best gift'
        value={customAmount}
        onChange={onCustomValueUpdated}
        error={customAmountError}
        styleCurrency
      />
    </div>
  );
};

export interface IProps {
  amounts?: number[];
  givingItemSignature: IGivingItemSignature;
  value?: number;
  onValueChanged?: (sku: string, value?: number) => void;
  onRemove?: (sku: string) => void;
}
