import './footer.scss';

import React from 'react';

import SmallFooterLinks from '../../../../assets/values/SmallFooterLinks.json';

export const Footer = (props: IProps) => {
  const links = SmallFooterLinks.map(link => {
    return (
      <div key={link.title} className='footer-link'>
        <a href={link.link}>{link.title}</a>
      </div>
    );
  });

  const smallFooterClassName = props.small ? 'footer--small' : '';
  const sidebarClassName = props.sidebarVisible ? 'sidebar-open--slide' : '';

  return (
    <div
      className={`footer columns ${smallFooterClassName} ${sidebarClassName}`}
      style={{ width: props?.width }}
    >
      <div className='footer-container column'>
        <div className='footer-section'>
          <div className='footer-links'>{links}</div>
          <div className='footer-copyright'>
            © University Development Office 2022 | The University of Texas at
            Austin
          </div>
        </div>
      </div>
    </div>
  );
};

interface IProps {
  width?: number;
  sidebarVisible?: boolean;
  small?: boolean;
}
