import { GIFT_TYPE_PLEDGE } from '../../../models/GiftType';
import { IGivingItem } from '../../../models/GivingItem';
import * as Types from '../types';

export function addGivingItem(value: IGivingItem): Types.ActionTypes {
  return {
    type: Types.ADD_GIVING_ITEM,
    giftType: GIFT_TYPE_PLEDGE,
    value,
  };
}

export function removeGivingItem(sku: string): Types.ActionTypes {
  return {
    type: Types.REMOVE_GIVING_ITEM,
    giftType: GIFT_TYPE_PLEDGE,
    sku,
  };
}

export function updateDuration(durationInYears?: number): Types.ActionTypes {
  return {
    type: Types.UPDATE_PLEDGE_DURATION,
    durationInYears,
  };
}

export function updateFrequency(frequency?: string): Types.ActionTypes {
  return {
    type: Types.UPDATE_GIFT_FREQUENCY,
    giftType: GIFT_TYPE_PLEDGE,
    frequency,
  };
}

export function updateAmount(amount?: number): Types.ActionTypes {
  return {
    type: Types.UPDATE_PLEDGE_AMOUNT,
    amount,
  };
}
