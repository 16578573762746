import { IGivingItem } from '../../models/GivingItem';
import {
  ISingleGiftSharedAttributes,
  IPledgeGiftSharedAttributes,
  ISingleGiftBuilder,
  IPledgeGiftBuilder,
} from '../../models/GiftBuilder';

import { GiftType } from '../../models/GiftType';
import { ILegacyURLData } from '../../models/LegacyURLData';

export type SkusToSingleGiftBuilders = { [sku: string]: ISingleGiftBuilder };
export type SkusToPledgeGiftBuilders = { [sku: string]: IPledgeGiftBuilder };

export interface IStore {
  builders: {
    SINGLE: SkusToSingleGiftBuilders;
    PLEDGE: SkusToPledgeGiftBuilders;
  };
  attributes: {
    SINGLE: ISingleGiftSharedAttributes;
    PLEDGE: IPledgeGiftSharedAttributes;
  };
}

export const ADD_GIVING_ITEM = 'ADD_GIVING_ITEM';
export const REMOVE_GIVING_ITEM = 'REMOVE_GIVING_ITEM';
export const UPDATE_GIFT_FREQUENCY = 'UPDATE_GIFT_FREQUENCY';
export const UPDATE_GIFT_END_DATE = 'UPDATE_GIFT_END_DATE';

export const UPDATE_SINGLE_GIFT_AMOUNT = 'UPDATE_SINGLE_GIFT_AMOUNT';
export const UPDATE_ALL_SINGLE_GIFT_AMOUNTS = 'UPDATE_ALL_SINGLE_GIFT_AMOUNTS';

export const UPDATE_PLEDGE_AMOUNT = 'UPDATE_PLEDGE_GIFT_AMOUNT';
export const UPDATE_PLEDGE_DURATION = 'UPDATE_PLEDGE_GIFT_DURATION';

export const CLEAR_CART = 'CLEAR_CART';

export const SET_CART_URL_PARAMS = 'SET_CART_URL_PARAMS';
export const SET_CART_LEGACY_DATA = 'SET_CART_LEGACY_DATA';

export const MAX_CART_SIZE = 3;

interface IAddGift {
  type: typeof ADD_GIVING_ITEM;
  value: IGivingItem;
  giftType: GiftType;
}

interface IRemoveGift {
  type: typeof REMOVE_GIVING_ITEM;
  sku: string;
  giftType: GiftType;
}
interface IUpdateGiftFrequency {
  type: typeof UPDATE_GIFT_FREQUENCY;
  giftType: GiftType;
  frequency?: string;
}

interface IUpdateGiftDuration {
  type: typeof UPDATE_GIFT_END_DATE;
  giftType: GiftType;
  duration?: number;
}

interface IUpdateSingleGiftAmount {
  type: typeof UPDATE_SINGLE_GIFT_AMOUNT;
  sku: string;
  amount?: number;
}

interface IUpdateAllSingleGiftAmounts {
  type: typeof UPDATE_ALL_SINGLE_GIFT_AMOUNTS;
  amount?: number;
}

interface IUpdatePledgeAmount {
  type: typeof UPDATE_PLEDGE_AMOUNT;
  amount?: number;
}

interface IUpdatePledgeDuration {
  type: typeof UPDATE_PLEDGE_DURATION;
  durationInYears?: number;
}

interface ISetCartURLParams {
  type: typeof SET_CART_URL_PARAMS;
  params: URLSearchParams;
}

interface ISetCartLegacyData {
  type: typeof SET_CART_LEGACY_DATA;
  data: ILegacyURLData;
  givingItems: IGivingItem[];
}

interface IClearCart {
  type: typeof CLEAR_CART;
  giftType?: GiftType;
}

export type ActionTypes =
  | IAddGift
  | IRemoveGift
  | IUpdateSingleGiftAmount
  | IUpdateAllSingleGiftAmounts
  | IUpdateGiftFrequency
  | IUpdateGiftDuration
  | IClearCart
  | IUpdatePledgeAmount
  | ISetCartURLParams
  | ISetCartLegacyData
  | IUpdatePledgeDuration;
