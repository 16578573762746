import { put, select, take, fork } from 'redux-saga/effects';

import { SET_GIFT_TYPE } from './types';

import { clearCart } from '../cart/actions';
import { getGiftType } from './selectors';
import { clearPledge } from '../pledge/actions';
import { refreshUniqueID } from '../payment/actions';

import { UPDATE_TRANSACTION } from '../transaction/types';
import { getTransaction } from '../transaction/selectors';

import { setQueryFacetsPledges, setQueryFacetsGifts } from '../search/actions';
import {
  GIFT_TYPE_PLEDGE,
  GIFT_TYPE_SINGLE,
  GiftType,
} from '../../models/GiftType';

function* clear(giftType: GiftType) {
  switch (giftType) {
    case GIFT_TYPE_PLEDGE:
      yield put(clearPledge());
      yield put(clearCart(GIFT_TYPE_PLEDGE));
      return;
    case GIFT_TYPE_SINGLE:
      yield put(clearCart(GIFT_TYPE_SINGLE));
      return;
  }
}

function* updateQueryFacets() {
  const giftType = yield select(getGiftType);

  switch (giftType) {
    case GIFT_TYPE_SINGLE:
      yield put(setQueryFacetsGifts());
      break;
    case GIFT_TYPE_PLEDGE:
      yield put(setQueryFacetsPledges());
      break;
  }
}

function* checkGiftTypeSaga() {
  while (true) {
    const lastGiftType = yield select(getGiftType);
    yield take(SET_GIFT_TYPE);
    const nextGiftType = yield select(getGiftType);

    if (lastGiftType !== nextGiftType) {
      yield fork(clear, lastGiftType);
      yield fork(updateQueryFacets);
    }
  }
}

// Clear cart & pledge upon successful transaction
function* checkTransactionSaga() {
  while (true) {
    const lastTransaction = yield select(getTransaction);
    yield take(UPDATE_TRANSACTION);
    const nextTransaction = yield select(getTransaction);

    if (nextTransaction && nextTransaction !== lastTransaction) {
      yield fork(clear, GIFT_TYPE_SINGLE);
      yield fork(clear, GIFT_TYPE_PLEDGE);
      yield put(refreshUniqueID());
    }
  }
}

function* appSaga() {
  yield fork(checkGiftTypeSaga);
  yield fork(updateQueryFacets);
  yield fork(checkTransactionSaga);
}

export default appSaga;
