export interface ISuggestion {
  street_line: string;
  city: string;
  state: string;
  zipcode: string;
  secondary: string;
  entries?: number;
}

export function buildAddress(suggestion: ISuggestion) {
  let whiteSpace = '';
  if (suggestion.secondary) {
    if (suggestion.entries || 0 > 1) {
      suggestion.secondary += ' (' + suggestion.entries + ' entries)';
    }
    whiteSpace = ' ';
  }
  return (
    suggestion.street_line +
    whiteSpace +
    suggestion.secondary +
    ' ' +
    suggestion.city +
    ', ' +
    suggestion.state +
    ' ' +
    suggestion.zipcode
  );
}
