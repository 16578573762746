import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import * as cartSelectors from '../../../../store/cart/selectors';
import * as selectors from '../../../../store/cart/singleGift/selectors';
import * as singleGiftActions from '../../../../store/cart/singleGift/actions';

import { bottomButtonClassName } from '../../../containers/giftSummary/mobile/MobileGiftSummary';
import { amounts } from '../../../../../assets/values/singleGiftAmounts.json';
import { Footer } from '../../../components/footer/footer';
import { getGiftCountString } from '../../../../util/StringUtils';
import { GIFT_TYPE_SINGLE } from '../../../../models/GiftType';
import { GiftBuilderView } from '../../../containers/cart/GiftBuilder/GiftBuilderView';
import { ISingleGiftBuilder } from '../../../../models/GiftBuilder';
import { RootState } from '../../../../store';
import { Routes } from '../../../../router/Routes';
import { Link, Redirect } from 'react-router-dom';

import './AmountPageMobile.scss';

const baseClassName = 'mob-amount-page';

const mapStateToProps = (state: RootState) => {
  const getGiftCount =
    cartSelectors.getNumberOfGiftsForGiftTypeCreator(GIFT_TYPE_SINGLE);

  return {
    giftBuilders: selectors.getBuilders(state),
    giftCount: getGiftCount(state),
    cartVaild: selectors.getCartIsValid(state),
  };
};

type IProps = ConnectedProps<typeof connector>;

const mapDispatchToProps = {
  updateGiftAmount: singleGiftActions.updateAmount,
  removeGift: singleGiftActions.removeGivingItem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const RenderGivingItem = (props: IProps) => (item: ISingleGiftBuilder) =>
  (
    <React.Fragment key={item.givingItem.givingItemSignature.sku}>
      <GiftBuilderView
        onValueChanged={props.updateGiftAmount}
        onRemove={props.removeGift}
        value={item.amount}
        amounts={amounts}
        givingItemSignature={item.givingItem.givingItemSignature}
      />
    </React.Fragment>
  );

const AmountPageMobileView = (props: IProps) => {
  if (!props.giftCount) {
    return <Redirect to={Routes.Sections.Search.path} />;
  }

  return (
    <React.Fragment>
      {/* <AmountNoCartRedirect /> */}
      <div className={`${baseClassName}__content`}>
        <div className={`${baseClassName}__title`}>Select Gift Amounts</div>
        <div className={`${baseClassName}__gift-count`}>
          {getGiftCountString(props.giftCount)}
        </div>

        {props.giftBuilders.map(RenderGivingItem(props))}

        <Footer small />
      </div>
    </React.Fragment>
  );
};

const NextButtonView = (props: IProps) => {
  const disabled = !props.cartVaild;
  return (
    <Link
      to={Routes.Sections.Checkout.path}
      className={`${bottomButtonClassName} ut-btn corner--pill ${
        disabled ? 'btn--disabled' : ''
      }`}
    >
      Review Your Information
    </Link>
  );
};

export const AmountPageMobile = connector(AmountPageMobileView);
export const NextButton = connector(NextButtonView);
