import * as Types from './types';
import { IFacet } from '../../models/Facet';

export function setQuery(value: string): Types.ActionTypes {
  return {
    type: Types.SET_QUERY,
    value,
  };
}

export function setSearchQuery(value: string): Types.ActionTypes {
  return {
    type: Types.SET_SEARCH_QUERY,
    searchValue: value,
  };
}

export function setDepartment(department?: string): Types.ActionTypes {
  return {
    type: Types.SET_DEPARTMENT,
    department,
  };
}

export function setDepartmentCode(departmentCode?: string): Types.ActionTypes {
  return {
    type: Types.SET_DEPARTMENT_CODE,
    departmentCode,
  };
}

export function setFeaturedArea(area?: string): Types.ActionTypes {
  return {
    type: Types.SET_FEATURED_AREA,
    area,
  };
}

export function setQueryFacetsGifts(): Types.ActionTypes {
  return {
    type: Types.SET_QUERY_FACETS,
    facets: Types.QUERY_FACETS_GIFTS,
  };
}

export function setQueryFacetsPledges(): Types.ActionTypes {
  return {
    type: Types.SET_QUERY_FACETS,
    facets: Types.QUERY_FACETS_PLEDGES,
  };
}

export function fetchGivingItemsSuccess(facets: IFacet[]): Types.ActionTypes {
  return {
    type: Types.FETCH_GIVING_ITEMS_SUCCESS,
    facets,
  };
}

export function fetchGivingItemsFailure(error?: string): Types.ActionTypes {
  return {
    type: Types.FETCH_GIVING_ITEMS_FAILURE,
    error,
  };
}

export function setShowMore(showMore: boolean): Types.ActionTypes {
  return {
    type: Types.SET_SHOW_MORE,
    showMore,
  };
}

export function setLoading(loading: boolean): Types.ActionTypes {
  return {
    type: Types.SET_LOADING,
    loading,
  };
}
