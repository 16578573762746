import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';

import { createStore } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';

import './index.scss';

const store = createStore();

ReactDOM.render(
  <Provider store={store.store}>
    <PersistGate persistor={store.persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
