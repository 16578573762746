import React from 'react';
import { useHeaderHeight } from '../HeaderHeight';
import { useNavHeight } from '../NavHeight';

import './FixedRightSide.scss';

export interface IFixedRightSideProps {
  top: string;
  className: string;
}

export const useFixedRightSide: () => IFixedRightSideProps = () => {
  const [yPos, updateYPos] = React.useState(window.pageYOffset);
  const headerHeightFull = useHeaderHeight();
  const navHeight = useNavHeight();

  const scrolledPastHeader = yPos > headerHeightFull - navHeight;

  React.useLayoutEffect(() => {
    const handleScroll = () => {
      updateYPos(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const top = scrolledPastHeader ? `${navHeight}px` : '0';

  const className = `fixed-right__content ${
    scrolledPastHeader ? 'fixed-right__content--scrolled' : ''
  }`;

  return { top, className };
};
