import React, { useEffect } from 'react';

import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store';

import * as selectors from '../../../store/transaction/selectors';

import './ConfirmationView.scss';
import { FormatCurrency } from '../../../util/NumberFormat';

const mapStateToProps = (state: RootState) => ({
  transaction: selectors.getTransaction(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export type IProps = ReduxProps & {};

const ConfirmationViewComponent = (props: IProps) => {
  const { transaction } = props;

  useEffect(() => {
    if (
      !transaction?.donorEmail ||
      !transaction?.transactionId ||
      !transaction?.ccTotalAmount
    ) {
      return;
    }

    const { donorEmail, transactionId, ccTotalAmount } = transaction;
    window._wds_ae = donorEmail;
    window._wds_oi = transactionId;
    window._wds_oa = ccTotalAmount;

    const scriptTag = document.createElement('script');
    scriptTag.src = '//tags.wdsvc.net/controller.js?id=100409';
    document.body.appendChild(scriptTag);
  });

  if (!props.transaction) {
    return <div className='confirmation'>No Transaction.</div>;
  }

  return (
    <div className='confirmation'>
      <h2 className='ut-h3 confirmation__thanks confirmation__pb--1'>
        <b>Thank you.</b>
      </h2>
      <p className='confirmation__pb--1'>
        Thank you for supporting The University of Texas at Austin.
        <br />
        We'll send an email to <b>{transaction?.donorEmail}</b> confirming the
        details of your contribution.
      </p>
      <div className='confirmation__transaction-info'>
        <h2 className='ut-h3 confirmation__pb--1'>
          <b>Details</b>
        </h2>
        <div>
          <p className='confirmation__transaction-info-value'>
            <b>Your confirmation number is #{transaction?.transactionId}</b>
          </p>
        </div>
        <div>
          <p className='confirmation__transaction-info-value'>
            <b>
              You will be charged {FormatCurrency(transaction?.ccTotalAmount)}
            </b>
          </p>
        </div>
      </div>
      <div className='confirmation__questions'>
        If you have any questions, contact us at{' '}
        <a
          className='ut-link--cta'
          href='https://giving.utexas.edu/contact/'
          rel='noopener noreferrer'
          target='_blank'
        >
          giving@austin.utexas.edu
        </a>
        .
      </div>
      {/* WDS Data Integration Tag */}
      <script>
        var _wds_ae = "{props.transaction?.donorEmail}"; var _wds_oi = "
        {props.transaction?.transactionId}"; var _wds_oa = "
        {props.transaction?.ccTotalAmount}";
      </script>
      {/* WDS Refire Snippet */}
      <script>WDSConfig.runTags([optional_callback_function()]);</script>
      {/* WDS Master Tag */}
      <script
        type='text/javascript'
        src='//tags.wdsvc.net/controller.js?id=100409'
        defer
      ></script>
    </div>
  );
};

export const ConfirmationView = connector(ConfirmationViewComponent);
