import { ISuggestion } from '../../models/smartyStreets/Suggestion';
import { IVerifiedAddress } from '../../models/smartyStreets/VerifiedAddress';

export interface IAddressLookup {
  query?: string;

  suggestions?: ISuggestion[];
  selectedSuggestion?: ISuggestion;

  verified?: IVerifiedAddress;
}

export interface IStore {
  lookups: { [addressKey: string]: IAddressLookup };
}

/* Address key is used by the front-end only to store address
 autocomplete across several sets of fields. They are arbitrary
 but must be unique.
 For example, the AddressLookupField under the honoree address
 would use a different key than one for the billing address. */
export const ADDRESS_KEY_DEFAULT = 'default';

export const SET_QUERY = 'SET_ADDRESS_QUERY';
export const SET_SUGGESTIONS = 'SET_SUGGESTIONS';
export const SET_SELECTED = 'SET_SELECTED';
export const SET_VERIFIED = 'SET_VERIFIED';

interface ISetQuery {
  type: typeof SET_QUERY;
  addressKey: string;
  query?: string;
}

interface ISetSuggestions {
  type: typeof SET_SUGGESTIONS;
  addressKey: string;
  suggestions?: ISuggestion[];
}

interface ISetSelected {
  type: typeof SET_SELECTED;
  addressKey: string;
  selected?: ISuggestion;
}

interface ISetVerified {
  type: typeof SET_VERIFIED;
  addressKey: string;
  verified?: IVerifiedAddress;
}

export type ActionType =
  | ISetQuery
  | ISetSuggestions
  | ISetSelected
  | ISetVerified;
