import API from '../API';
import { IPledgeInformationResponse } from '../../models/api/PledgeInformationResponse';

export function fetchPledgeInformation(
  id: string
): Promise<IPledgeInformationResponse> {
  return API.execute<IPledgeInformationResponse>(`pledges/${id}`, [], {
    method: 'GET',
  });
}
