import { IGift } from '../../models/Gift';

// Add a backslash before all apostrophes
const escapeApostrophes = (str?: string) =>
  str ? str.replaceAll(/'/g, "\\'") : undefined;

export const serializeGiftsForPayment = (gifts: IGift[]) => {
  // console.log(gifts);
  return JSON.stringify(gifts.map(serializeGiftForPayment))
    .replaceAll(/"/g, "'")
    .replaceAll(/\\\\/g, '\\'); // JSON.stringify adds another slash to all our backslashes :(
};

const serializeGiftForPayment = (gift: IGift) => ({
  giftItemSku: gift.givingItemSku,
  giftItemName: escapeApostrophes(gift.givingItemName),
  giftAmount: `${gift.amount}`,
  giftType: gift.giftType,
  giftCodings: gift.codings,
  giftDuration: `${gift.duration}`,
  giftFrequency: gift.frequency,
  giftPledgeId: gift.pledgeId,
  giftPledgeInstallmentNumber: gift.pledgeInstallmentNumber,
  giftStartDate: gift.startDate,
  giftRemarks: escapeApostrophes(gift.remarks),
  giftBenefitAmount: gift.benefitAmount,
});
