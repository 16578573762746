import * as Types from './types';
import { ISuggestion } from '../../models/smartyStreets/Suggestion';
import { IVerifiedAddress } from '../../models/smartyStreets/VerifiedAddress';

export function setQuery(
  addressKey: string = Types.ADDRESS_KEY_DEFAULT,
  query?: string
): Types.ActionType {
  return {
    type: Types.SET_QUERY,
    addressKey,
    query,
  };
}

export function setSuggestions(
  addressKey: string = Types.ADDRESS_KEY_DEFAULT,
  suggestions?: ISuggestion[]
) {
  return {
    type: Types.SET_SUGGESTIONS,
    addressKey,
    suggestions,
  };
}

export function setSelected(
  addressKey: string = Types.ADDRESS_KEY_DEFAULT,
  selected?: ISuggestion
) {
  return {
    type: Types.SET_SELECTED,
    addressKey,
    selected,
  };
}

export function setVerified(
  addressKey: string = Types.ADDRESS_KEY_DEFAULT,
  verified?: IVerifiedAddress
) {
  return {
    type: Types.SET_VERIFIED,
    addressKey,
    verified,
  };
}
