import React, { useState, useLayoutEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store';
import { getGiftType } from '../../../store/app/selectors';
import {
  GiftType,
  GIFT_TYPE_SINGLE,
  GIFT_TYPE_PLEDGE,
} from '../../../models/GiftType';

import { classesForYPosition } from './util/NavScrolledClasses';
import NavigationLinks from '../../../../assets/values/NavigationLinks.json';
import logo from '../../../../assets/images/site-logo.svg';
import { useNavHeightSetter } from '../../effects/layout/NavHeight';

import './navigation.scss';

const mapStateToProps = (state: RootState) => {
  return { giftType: getGiftType(state) };
};

const connector = connect(mapStateToProps);

export type IProps = ConnectedProps<typeof connector> & {
  onToggleSidebar: () => void;
  sidebarVisible: boolean;
  forceScrolledState?: boolean;
};

const NavigationView = (props: IProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  useNavHeightSetter(containerRef);

  const [ypos, updateYpos] = useState(window.pageYOffset);

  useLayoutEffect(() => {
    const handleScroll = () => {
      updateYpos(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const getNavLinks = () => {
    const menuScrolledClass = classesForYPosition(
      ypos,
      props.forceScrolledState
    )
      .map(threshold => threshold.menu)
      .join(' ');

    const links = NavigationLinks.map(link => {
      const activeClassName = link.active ? 'active' : '';
      return (
        <li key={link.title} className={`menu-item ${activeClassName}`}>
          <a href={link.link}>{link.title}</a>
        </li>
      );
    });
    return <ul className={`menu ${menuScrolledClass}`}>{links}</ul>;
  };

  const containerClass = () => {
    const containerScrolledClass = classesForYPosition(
      ypos,
      props.forceScrolledState
    )
      .map(threshold => threshold.container)
      .join(' ');
    return `${
      props.sidebarVisible ? ' sidebar-open' : ''
    } ${containerScrolledClass}`;
  };

  return (
    <div
      className={`container ${containerClass()} ${getGiftClassName(
        props.giftType
      )}`}
    >
      <div className='menu_container' ref={containerRef}>
        <div className='menu_logo'>
          <a href='https://giving.utexas.edu'>
            <img src={logo} alt='What Starts Here' id='logo' />
          </a>
        </div>
        <nav className='menu-nav'>{getNavLinks()}</nav>
        <div className='top-navigation'>
          <span
            className={
              'mobile_menu_bar' + (props.sidebarVisible ? ' open' : ' closed')
            }
            onClick={props.onToggleSidebar}
          ></span>
        </div>
      </div>
    </div>
  );
};

const getGiftClassName = (giftType: GiftType) => {
  const baseClass = 'container';

  switch (giftType) {
    case GIFT_TYPE_SINGLE:
      return `${baseClass}--ogp`;
    case GIFT_TYPE_PLEDGE:
      return `${baseClass}--pledge`;
  }
};

export const Navigation = connector(NavigationView);
