import './CartView.scss';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import { amounts } from '../../../../assets/values/singleGiftAmounts.json';
import GiftFrequencies from '../../../../assets/values/singleGiftFrequencies.json';
import { ISingleGiftBuilder } from '../../../models/GiftBuilder';
import { GIFT_TYPE_SINGLE } from '../../../models/GiftType';
import { Routes } from '../../../router/Routes';
import { RootState } from '../../../store';
import { clearCart } from '../../../store/cart/actions';
import * as singleGiftActions from '../../../store/cart/singleGift/actions';
import * as selectors from '../../../store/cart/singleGift/selectors';
import { getSingleGiftInlineFrequency } from '../../../util/dataSets/frequencies';
import { FormatCurrency } from '../../../util/NumberFormat';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { GiftFrequencyTooltip } from '../../components/infoTooltip/freqency/GiftFrequencyTooltip';
import { Radio } from '../../components/radio/Radio';
import { GiftBuilderView } from './GiftBuilder/GiftBuilderView';

const mapStateToProps = (state: RootState) => {
  return {
    giftBuilders: selectors.getBuilders(state),
    itemCount: selectors.getItemCount(state),
    total: selectors.getTotalAmount(state),
    durations: selectors.getDurations(state),
    duration: selectors.getDuration(state),
    frequency: selectors.getFrequency(state),
    cartValid: selectors.getCartIsValid(state),
  };
};

const mapDispatchToProps = {
  updateFrequency: singleGiftActions.updateFrequency,
  updateGiftAmount: singleGiftActions.updateAmount,
  updateDuration: singleGiftActions.updateDuration,
  removeGift: singleGiftActions.removeGivingItem,
  clearCart,
};

const recurringFrequencyOptions = [
  GiftFrequencies.oneTime,
  GiftFrequencies.monthly,
  GiftFrequencies.annually,
];

export type IProps = ReduxProps & {};

const RenderGivingItem = (props: IProps) => (item: ISingleGiftBuilder) =>
  (
    <React.Fragment key={item.givingItem.givingItemSignature.sku}>
      <GiftBuilderView
        onValueChanged={props.updateGiftAmount}
        onRemove={props.removeGift}
        value={item.amount}
        amounts={amounts}
        givingItemSignature={item.givingItem.givingItemSignature}
      />
    </React.Fragment>
  );
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const CartViewComponent = (props: IProps) => {
  const isEmpty = props.itemCount === 0;
  const buttonDisabled = !props.cartValid ? 'btn--disabled' : '';

  let totalFrequencyString = ` ${getSingleGiftInlineFrequency(
    props.frequency
  )} `;
  if (!props.frequency || props.frequency === GiftFrequencies.oneTime) {
    totalFrequencyString = '';
  }

  const durations = props.durations || [];
  // eslint-disable-next-line
  const dropdownValue = durations.find(item => props.duration == item.value);

  return (
    <div className={`cart`}>
      <div className='cart__header'>
        <h3 className='ut-h3'>My Gifts</h3>
        <div className='cart__item-count'>{props.itemCount}</div>
      </div>
      {props.giftBuilders.map(RenderGivingItem(props))}
      {isEmpty ? (
        <div className='cart__empty'>
          <b>Your cart is empty</b>
        </div>
      ) : (
        <>
          <div className='cart__frequency'>
            <div className='d--f pb--10'>
              <p className='cart__frequency-title'>Gift Frequency</p>
              <GiftFrequencyTooltip className='cart__frequency-info' />
            </div>
            <Radio
              className='pb--10'
              options={recurringFrequencyOptions}
              onChange={(option: any) => {
                props.updateFrequency(option);
                props.updateDuration(-1);
              }}
              value={props.frequency?.value || ''}
            />

            <div
              className={`cart__frequency-recurring${
                props.frequency?.value !== GiftFrequencies.monthly.value &&
                props.frequency?.value !== GiftFrequencies.annually.value
                  ? '--hidden'
                  : ''
              }`}
            >
              <div className='d--f pb--10'>
                <p className='cart__frequency-title'>For how long?</p>
              </div>

              <Dropdown
                value={dropdownValue?.label || undefined}
                className={`pb--40 pledge-establish__duration-dropdown`}
                options={durations}
                onChange={(option: string) => {
                  const newValue = durations.find(
                    item => option === item.label
                  );

                  props.updateDuration(newValue?.value || undefined);
                }}
                placeholder='Until I Cancel'
              />
            </div>
          </div>
          <div>
            {props.total ? (
              <div className='cart__total__container'>
                <h4 className='ut-h5'>
                  {totalFrequencyString}Total:
                  <span className='cart__total'>
                    {FormatCurrency(props.total)}
                  </span>
                </h4>
              </div>
            ) : null}
            <div className='cart__footer'>
              <button
                onClick={() => props.clearCart(GIFT_TYPE_SINGLE)}
                className='cart__clear ut-link--secondary'
              >
                Clear cart
              </button>
              <Link
                to={`/${Routes.Sections.Checkout.path}`}
                className={`cart__checkout-button ut-btn corner--pill ${buttonDisabled}`}
              >
                Your Information
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const CartView = connector(CartViewComponent);
