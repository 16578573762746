import * as Types from './types';

export const initialState: Types.IStore = {
  existingPledgeIDKnown: true,
  existingPledgeCustomPaymentAmounts: {},
  existingPledgePerPaymentAmounts: {},
};

export function pledgeReducer(
  state = initialState,
  action: Types.ActionTypes
): Types.IStore {
  switch (action.type) {
    case Types.SET_PLEDGE_TYPE:
      return {
        ...initialState,
        pledgeType: action.pledgeType,
      };
    case Types.CLEAR_PLEDGE:
      return initialState;
    case Types.SET_EXISTING_PLEDGE_ID:
      return { ...state, existingPledgeID: action.id };
    case Types.SET_EXISTING_PLEDGE_ID_KNOWN:
      return {
        ...state,
        existingPledgeIDKnown: action.value,
        existingPledgeResponse: undefined,
        existingPledgeID: undefined,
        existingPledgeComments: undefined,
      };
    case Types.SET_EXISTING_PLEDGE_IS_LOADING:
      return {
        ...state,
        existingPledgeIsLoading: action.isLoading,
      };
    case Types.FETCH_EXISTING_PLEDGE_SUCCESS:
      // TODO: remove the "|| true" when we're no longer mocking
      if (action.id === state.existingPledgeID || true) {
        return {
          ...state,
          existingPledgeResponse: action.pledge,
        };
      }

      break;
    case Types.SET_EXISTING_PLEDGE_NO_ID_PAYMENT_AMOUNT:
      return {
        ...state,
        existingPledgeNoIDPaymentAmount: action.amount,
      };
    case Types.SET_EXISTING_PLEDGE_PAYMENT_TYPE:
      return {
        ...state,
        existingPledgePaymentType: action.paymentType,
      };
    case Types.SET_EXISTING_PLEDGE_CUSTOM_PAYMENT_AMOUNT:
      const { id, amount } = action;
      const idsToAmounts = state.existingPledgeCustomPaymentAmounts;
      return {
        ...state,
        existingPledgeCustomPaymentAmounts: {
          ...idsToAmounts,
          [id]: amount,
        },
      };
    case Types.SET_EXISTING_PLEDGE_PAYMENT_AMOUNT_TYPE:
      const { link, value } = action;
      const { [link]: remove, ...linksToAmountsToggled } =
        state.existingPledgePerPaymentAmounts;
      const linksToAmounts = state.existingPledgePerPaymentAmounts;
      return {
        ...state,
        existingPledgePerPaymentAmounts: linksToAmounts[link]
          ? {
              ...linksToAmountsToggled,
            }
          : {
              ...linksToAmounts,
              [link]: value,
            },
      };
    case Types.CLEAR_EXISTING_PLEDGE_PAYMENT_AMOUNT_TYPE:
      return {
        ...state,
        existingPledgePerPaymentAmounts: {},
      };
    case Types.CLEAR_EXISTING_PLEDGE_CUSTOM_PAYMENTS:
      return {
        ...state,
        existingPledgeCustomPaymentAmounts: {},
      };
    case Types.SET_EXISTING_PLEDGE_ENABLE_AUTOPAY:
      return {
        ...state,
        existingPledgeEnableAutopay: action.value,
      };
    case Types.SET_EXISTING_PLEDGE_COMMENTS:
      return {
        ...state,
        existingPledgeComments: action.comments,
      };
  }
  return state;
}
