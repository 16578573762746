import { RootState } from '..';
import { createSelector } from 'reselect';

const getTransactionState = (state: RootState) => state.transaction;

const getResponse = createSelector(
  [getTransactionState],
  state => state.transactionResponse
);

export const getTransaction = createSelector(
  [getResponse],
  response => response?.transaction
);

export const getTransactionProcessing = createSelector(
  [getTransactionState],
  state => state.transactionProcessing
);
