import React from 'react';
import { Footer } from '../../../components/footer/footer';
import { PaymentButton } from '../../../components/paymentButton/PaymentButton';
import { CheckoutFormMobile } from '../../../containers/checkoutForm/mobile/CheckoutFormMobile';
import { bottomButtonClassName } from '../../../containers/giftSummary/mobile/MobileGiftSummary';
import { CheckoutNoCartRedirect } from '../../../router/redirects/CheckoutNoCartRedirect';
import { CheckoutToConfirmationRedirect } from '../../../router/redirects/CheckoutToConfirmationRedirect';
import CartQueryUnwrapper from '../../../wrappers/urlQueryUnwrapper/CartQueryUnwrapper';
import './CheckoutPageMobile.scss';

const baseClassName = `mob-checkout-page`;

interface IProps {
  form: any;
}

export const CheckoutPageMobile = (props: IProps) => {
  return (
    <>
      <CartQueryUnwrapper>
        <CheckoutToConfirmationRedirect />
        <CheckoutNoCartRedirect />
        <div className={`${baseClassName}__form-container`}>
          <CheckoutFormMobile />
        </div>
        <Footer small />
      </CartQueryUnwrapper>
    </>
  );
};

export const NextButton = () => {
  return (
    <PaymentButton
      className={`${bottomButtonClassName} ${baseClassName}__payment-button-container`}
    />
  );
};
