export interface CheckoutField {
  name: string;
  required: boolean;
  missingText: string;
}

export default {
  fields: {
    donorUtEid: {
      name: 'donor-ut-eid',
      required: false,
      missingText: '',
    },
    donorEmail: {
      name: 'donor-email',
      required: true,
      missingText: 'Email is required.',
    },
    donorBillingIsShipping: {
      name: 'donor-address-billing-is-shipping',
      required: false,
      missingText: '',
    },
    donorPhone: {
      name: 'donor-phone-number',
      required: true,
      missingText: 'Phone is required.',
    },
    donorPhoneCountry: {
      name: 'donor-phone-country',
      required: true,
      missingText: 'Phone country code is required.',
    },
    donorIsOrganization: {
      name: 'donor-is-organization',
      required: false,
      missingText: '',
    },
    donorOrganizationName: {
      name: 'donor-name-organization',
      required: false,
      missingText: '',
    },
    donorIsCompanyMatching: {
      name: 'donor-is-company-matching',
      required: false,
      missingText: '',
    },
    donorMatchingName: {
      name: 'donor-matching',
      required: true,
      missingText: 'Company name is required.',
    },
    honoreeName: {
      name: 'dedication-name-full',
      required: true,
      missingText: 'Honoree name is required.',
    },
    dedicationType: {
      name: 'dedication-type',
      required: false,
      missingText: '',
    },
    dedicationName: {
      name: 'dedication-name-full',
      required: false,
      missingText: '',
    },
    notificationType: {
      name: 'notification-type',
      required: false,
      missingText: '',
    },
    notificationEmail: {
      name: 'notification-email',
      required: false,
      missingText: '',
    },
    notificationMessage: {
      name: 'notification-message',
      required: false,
      missingText: '',
    },
    giftIsJointGift: {
      name: 'gift-is-joint-gift',
      required: false,
      missingText: '',
    },
    giftRemarks: {
      name: 'comments',
      required: false,
      missingText: '',
    },
    giftCodings: {
      name: 'codings',
      required: false,
      missingText: '',
    },
    chargeAutomatically: {
      name: 'charge-automatically',
      required: false,
      missingText: '',
    },
    donorNameGiven: {
      name: 'donor-name-given',
      required: true,
      missingText: 'First name is required.',
    },
    donorNameFamily: {
      name: 'donor-name-family',
      required: true,
      missingText: 'Last name is required.',
    },
    donorNameTitle: {
      name: 'donor-name-title',
      required: false,
      missingText: '',
    },
    donorAddressLine1: {
      name: 'donor-address-line-one',
      required: true,
      missingText: 'Address is required.',
    },
    donorAddressLine2: {
      name: 'donor-address-line-two',
      required: false,
      missingText: '',
    },
    donorCity: {
      name: 'donor-address-city',
      required: true,
      missingText: 'City is required.',
    },
    donorStateOrProvince: {
      name: 'donor-address-state-or-province',
      required: true,
      missingText: 'State or province is required.',
    },
    donorPostalCode: {
      name: 'donor-address-postal-code',
      required: true,
      missingText: 'Zip Code is required.',
    },
    donorCountry: {
      name: 'donor-address-country',
      required: true,
      missingText: 'Country is required.',
    },
    partnerNameGiven: {
      name: 'partner-name-given',
      required: true,
      missingText: 'Partner first name is required.',
    },
    partnerNameFamily: {
      name: 'partner-name-family',
      required: true,
      missingText: 'Partner last name is required.',
    },
    partnerNameTitle: {
      name: 'partner-name-title',
      required: false,
      missingText: '',
    },
    recipientNameGiven: {
      name: 'notification-name-given',
      required: true,
      missingText: 'Recipient first name is required.',
    },
    recipientNameFamily: {
      name: 'notification-name-family',
      required: true,
      missingText: 'Recipient last name is required.',
    },
    billingAddressLine1: {
      name: 'cc-address-line-one',
      required: true,
      missingText: 'Billing address is required.',
    },
    billingAddressLine2: {
      name: 'cc-address-line-two',
      required: false,
      missingText: '',
    },
    billingCity: {
      name: 'cc-address-city',
      required: true,
      missingText: 'Billing city is required.',
    },
    billingStateOrProvince: {
      name: 'cc-address-state-or-province',
      required: true,
      missingText: 'Billing state or province is required.',
    },
    billingPostalCode: {
      name: 'cc-address-postal-code',
      required: true,
      missingText: 'Billing zip code is required.',
    },
    billingCountry: {
      name: 'cc-address-country',
      required: true,
      missingText: 'Billing country is required.',
    },
    notificationAddressLine1: {
      name: 'notification-line-one',
      required: true,
      missingText: 'Notification address is required.',
    },
    notificationAddressLine2: {
      name: 'notification-line-two',
      required: false,
      missingText: '',
    },
    notificationCity: {
      name: 'notification-city',
      required: true,
      missingText: 'Notification city is required.',
    },
    notificationStateOrProvince: {
      name: 'notification-state-or-province',
      required: true,
      missingText: 'Notification state or province is required.',
    },
    notificationPostalCode: {
      name: 'notification-postal-code',
      required: true,
      missingText: 'Notification zip code is required.',
    },
    notificationCountry: {
      name: 'notification-country',
      required: true,
      missingText: 'Notification country is required.',
    },
  },

  // Prefixes: Prepended to a suffix to form a complete field name. Passed into an InputFieldGroup.
  prefixes: {
    notification: {
      notification: 'notification',
    },
  },
};
