import './PledgeSummaryContent.scss';

import React from 'react';

import { useFixedRightSide } from '../../../../effects/layout/fixedRightSide/FixedRightSide';

const renderItem =
  (classModifier: string) =>
  (item: ISummaryItem, index: number = 0) => {
    const baseClassName = 'pledge-summary__item';
    return (
      <div
        key={`${index}`}
        className={`${baseClassName} ${baseClassName}--${classModifier}`}
      >
        <p className={`${baseClassName}-title`}>{item.title}</p>
        <p className={`${baseClassName}-value`}>{item.value}</p>
      </div>
    );
  };

const ContentForItems = (props: IProps) => {
  if (!props.items) {
    return <p className='pledge-summary__empty'>Your cart is empty</p>;
  }

  return (
    <React.Fragment>
      <div className='pledge-summary__items--primary'>
        {props.items.primary?.map(renderItem('primary'))}
      </div>
      <div className='pledge-summary__items--secondary'>
        {props.items.secondary?.map(renderItem('secondary'))}
      </div>
      <div>
        {props.items.final ? renderItem('final')(props.items.final) : null}
      </div>
      <div className='d--f jc--sb'>
        {props.cancelButton}
        {props.submitButton}
      </div>
    </React.Fragment>
  );
};

const PledgeSummaryContentView = (props: IProps) => {
  const { top, className } = useFixedRightSide();

  return (
    <div className={`pledge-summary__content ${className}`} style={{ top }}>
      <div className='pledge-summary__header'>
        <h3 className='ut-h3'>Gift Summary</h3>
      </div>
      {ContentForItems(props)}
      <div className='pledge-summary__footer'>
        In submitting this payment you agree to be automatically charged for any
        future payments for this series.
      </div>
    </div>
  );
};

export const PledgeSummaryContent = PledgeSummaryContentView;

export interface ISummaryItem {
  title: string;
  value?: any;
}

export interface ISummaryItems {
  primary?: ISummaryItem[];
  secondary?: ISummaryItem[];
  final?: ISummaryItem;
}

export interface IProps {
  items?: ISummaryItems;

  submitEnabled?: boolean;
  submitButton?: React.ReactElement;
  cancelButton?: React.ReactElement;
}
