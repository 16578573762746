import { createSelector } from 'reselect';

import { RootState } from '../';
import { no, yes } from '../../../assets/values/yesNo.json';
import { IDedicationRecipient } from '../../models/DedicationRecipient';
import { IGift } from '../../models/Gift';
import { GIFT_TYPE_PLEDGE, GIFT_TYPE_SINGLE } from '../../models/GiftType';
import { getReCAPTCHAKey } from '../../util/APIUtils';
import { TYPE_PER_PAYMENT } from '../../util/dataSets/existingPledgePaymentAmounts';
import FormKeys from '../../util/fieldNames/CheckoutFieldNames';
import SerializeForm from '../../util/serialization/SerializeForm';
import { serializeGiftsForPayment } from '../../util/serialization/SerializeGift';
import { getGiftType } from '../app/selectors';
import { getAllCompletedGifts } from '../cart/selectors';
import {
  getCodingsString,
  getDedication as getLegacyURLDedication,
  getFormFromLegacyData,
} from '../legacy/selectors';
import {
  getComments,
  getGiftsForPayment,
  getGiftsForPerPayment,
  getIDKnown,
  getPaymentType,
} from '../pledge/existingPledge/selectors';
import { getPledgeType } from '../pledge/selectors';
import { PLEDGE_TYPE_ESTABLISH, PLEDGE_TYPE_PAYMENT } from '../pledge/types';
import { getReferrer } from '../referrer/selectors';

const getPaymentState = (state: RootState) => state.payment;

const getFormIsValid = createSelector(
  [getPaymentState],
  state => state.formIsValid
);

export const getShowMissingFields = createSelector(
  [getPaymentState],
  state => state.showMissingFields
);

const getUniqueID = createSelector([getPaymentState], state => state.uniqueID);

const getPaymentGifts = createSelector(
  [
    getGiftType,
    getPledgeType,
    getAllCompletedGifts,
    getGiftsForPayment,
    getGiftsForPerPayment,
    getPaymentType,
  ],
  (
    giftType,
    pledgeType,
    cartGifts,
    pledgePaymentGifts,
    pledgePerPaymentGifts,
    paymentType
  ) => {
    switch (giftType) {
      case GIFT_TYPE_SINGLE:
        return cartGifts.SINGLE;
      case GIFT_TYPE_PLEDGE:
        switch (pledgeType) {
          case PLEDGE_TYPE_PAYMENT:
            if (paymentType === TYPE_PER_PAYMENT) {
              return pledgePerPaymentGifts;
            }
            return pledgePaymentGifts;
          case PLEDGE_TYPE_ESTABLISH:
            return cartGifts.PLEDGE;
        }
    }

    return [] as IGift[];
  }
);

const getDefaultPaymentDedication = createSelector(
  [getPaymentGifts, getLegacyURLDedication],
  (gifts, legacyDedication) => {
    let toReturn: IDedicationRecipient | undefined;
    gifts.forEach(gift => {
      if (gift.defaultDedication) toReturn = gift.defaultDedication;
    });

    if (legacyDedication) {
      toReturn = legacyDedication;
    }

    return toReturn;
  }
);

export const getRawFormData = createSelector(
  [getPaymentState],
  state => state.formData
);

export const getPaymentComments = createSelector(
  [getGiftType, getPledgeType, getIDKnown, getComments],
  (giftType, pledgeType, existingPledgeIDKnown, comments) => {
    if (
      giftType === GIFT_TYPE_PLEDGE &&
      pledgeType === PLEDGE_TYPE_PAYMENT &&
      !existingPledgeIDKnown
    ) {
      return comments;
    }
  }
);

export const getDefaultPaymentFormValues = createSelector(
  [getRawFormData, getDefaultPaymentDedication, getFormFromLegacyData],
  (formData, dedication, legacyData) => ({
    [FormKeys.fields.dedicationName.name]: dedication?.dedicationName,
    [FormKeys.fields.dedicationType.name]: dedication?.dedicationType,
    ...legacyData,
    ...formData, // Feed the existing values back into the form, to retain state when switching views
  })
);

export const getPaymentData = createSelector(
  [
    getPaymentGifts,
    getPaymentComments,
    getRawFormData,
    getUniqueID,
    getCodingsString,
    getFormIsValid,
    getReferrer,
  ],
  (
    paymentGifts,
    paymentComments,
    formData,
    uniqueID,
    codingsString,
    formIsValid,
    referrer
  ) => {
    return {
      ...formData,
      identifier: uniqueID,
      initial: true,
      origin: '1',
      codings: codingsString,
      comments: formData?.comments || paymentComments,
      'cart-string': serializeGiftsForPayment(paymentGifts),
      'form-complete': `${formIsValid ? yes.value : no.value}`,
      'widget-referrer': referrer,
      'captcha-site-key': getReCAPTCHAKey(),
    };
  }
);

export const getSerializedPaymentData = createSelector(
  [getPaymentData],
  paymentData => SerializeForm(paymentData)
);

export const getFormHonoreeSectionEnabled = createSelector(
  [getGiftType, getPledgeType],
  (giftType, pledgeType) => {
    if (giftType === GIFT_TYPE_SINGLE) {
      return true;
    }
    return pledgeType !== PLEDGE_TYPE_PAYMENT;
  }
);
