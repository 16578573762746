import React from 'react';

import { IEmbedMessage } from '../../../../../store/embed/types';

import './EmbedMessagePresenter.scss';

export interface IProps {
  message?: IEmbedMessage;
  clearMessage: () => void;
}

const baseClassName = 'embed-message';

export const EmbedErrorPresenter = (props: IProps) => {
  const { message, clearMessage } = props;
  if (!message) {
    return null;
  }

  return (
    <div className={`${baseClassName}`}>
      <div className={`${baseClassName}__window`}>
        {message.title ? (
          <div className={`${baseClassName}__title-container`}>
            <div className={`${baseClassName}__title`}>{message.title}</div>
          </div>
        ) : null}
        <div className={`${baseClassName}__message-container`}>
          <div className={`${baseClassName}__message`}>{message.message}</div>
        </div>
        <div className={`${baseClassName}__button-container`}>
          <button
            className='ut-btn btn--small corner--pill'
            onClick={clearMessage}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
