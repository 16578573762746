import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Link,
  RouteComponentProps,
  useHistory,
  withRouter,
} from 'react-router-dom';

import { Routes } from '../../../../router/Routes';
import { RootState } from '../../../../store';
import { clearCart } from '../../../../store/cart/actions';
import * as cartSelectors from '../../../../store/cart/pledge/selectors';
import {
  clearPledge,
  clearExistingPerPayments,
} from '../../../../store/pledge/actions';
import * as existingPledgeSelectors from '../../../../store/pledge/existingPledge/selectors';
import * as pledgeSelectors from '../../../../store/pledge/selectors';
import {
  PLEDGE_TYPE_ESTABLISH,
  PLEDGE_TYPE_PAYMENT,
} from '../../../../store/pledge/types';
import {
  formatDate,
  formatPledgeFrequencyString,
  getEarliestDuePledge,
} from '../../../../util/DateUtils';
import { FormatCurrency } from '../../../../util/NumberFormat';
import { PaymentButton } from '../../../components/paymentButton/PaymentButton';
import {
  IProps as ISummaryProps,
  ISummaryItem,
  PledgeSummaryContent,
} from './content/PledgeSummaryContent';

const mapStateToProps = (state: RootState) => {
  const summaryIsVisibleCreator = pledgeSelectors.getSummaryVisibleCreator();
  const getAmountForExisitngPledgeCreator =
    existingPledgeSelectors.getDueTodayAmountForPledgeCreator();
  return {
    pledgeType: pledgeSelectors.getPledgeType(state),
    summaryIsVisible: summaryIsVisibleCreator(state),
    autopayPossible: pledgeSelectors.getPledgeAutopayPossible(state),
    pledgeValid: pledgeSelectors.getPledgeGiftValid(state),

    establishPledgeBuilders: cartSelectors.getBuilders(state),
    establishPledgeAttributes: cartSelectors.getSharedAttributes(state),
    establishPledgeTotalAmount: cartSelectors.getTotalPayment(state),
    establishPledgeRecurringAmount: cartSelectors.getRecurringAmount(state),

    existingPledgeID: existingPledgeSelectors.getPledgeID(state),
    existingPledgeIDKnown: existingPledgeSelectors.getIDKnown(state),
    existingPledges: existingPledgeSelectors.getPledges(state),
    existingPledgePaymentAmount:
      existingPledgeSelectors.getNoIDPaymentAmount(state),
    existingPledgeDueTodayAmount:
      existingPledgeSelectors.getDueTodayTotalAmount(state),
    existingPledgeAutopayEnabled:
      existingPledgeSelectors.getAutopayEnabled(state),
    getDueTodayAmountForExistingPledge:
      getAmountForExisitngPledgeCreator(state),
  };
};

const mapDispatchToProps = {
  clearPledge,
  clearExistingPerPayments,
  clearCart,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export type IProps = ReduxProps &
  RouteComponentProps & {
    checkoutPage?: boolean;
  };

function CreateEstablishPledgeSummary(props: IProps): ISummaryProps {
  const frequency = props.establishPledgeAttributes.frequency;
  const duration = props.establishPledgeAttributes.durationInYears;
  const amount = props.establishPledgeAttributes.amount;

  const secondaryItems: ISummaryItem[] = [];
  if (frequency && duration) {
    secondaryItems.push({
      title: 'Frequency',
      value: formatPledgeFrequencyString(duration, frequency) || '',
    });
  }
  if (props.establishPledgeTotalAmount) {
    secondaryItems.push({
      title: 'Pledge Total',
      value: FormatCurrency(props.establishPledgeTotalAmount) || '',
    });
  }

  let finalItem: ISummaryItem | undefined;
  if (props.establishPledgeRecurringAmount) {
    finalItem = {
      title: 'Due Today',
      value: FormatCurrency(props.establishPledgeRecurringAmount) || '',
    };
  }

  return {
    items: {
      primary: props.establishPledgeBuilders.map(item => ({
        title: item.givingItem.givingItemSignature.name,
        value: FormatCurrency(amount) || '',
      })),
      secondary: secondaryItems,
      final: finalItem,
    },
  };
}

function CreateExistingPledgeSummaryNoID(props: IProps): ISummaryProps {
  return {
    items: {
      primary: [
        {
          title: 'Pledge payment for Other - Specify in Comments',
          value: FormatCurrency(props.existingPledgePaymentAmount) || '',
        },
      ],
      secondary: [
        {
          title: 'Automatic Payments',
          value: 'Disabled',
        },
      ],
      final: {
        title: 'Due Today',
        value: FormatCurrency(props.existingPledgeDueTodayAmount) || '',
      },
    },
  };
}

function CreateExistingPledgeSummaryWithID(props: IProps): ISummaryProps {
  const primaryItems = props.existingPledges
    .filter(pledge => {
      const amount = props.getDueTodayAmountForExistingPledge(pledge);
      return amount && amount > 0;
    })
    .map(pledge => ({
      title: pledge.pledgeTitle || `Pledge Payment for ID-${pledge.id}`,
      value:
        FormatCurrency(props.getDueTodayAmountForExistingPledge(pledge) || 0) ||
        '',
    }));

  const earliestPledge = getEarliestDuePledge(props.existingPledges);
  const secondaryItems = [
    {
      title: 'Next Payment',
      value: formatDate(earliestPledge?.dateDue) || '',
    },
  ];

  if (props.autopayPossible) {
    secondaryItems.push({
      title: 'Automatic Payments',
      value: props.existingPledgeAutopayEnabled ? 'Enabled' : 'Disabled',
    });
  }

  const finalItem = {
    title: 'Total Due',
    value: FormatCurrency(props.existingPledgeDueTodayAmount) || '',
  };
  return {
    items: {
      primary: primaryItems,
      // secondary: secondaryItems,
      final: finalItem,
    },
  };
}

const getSummaryItems = (props: IProps) => {
  if (props.summaryIsVisible(props.checkoutPage || false)) {
    switch (props.pledgeType) {
      case PLEDGE_TYPE_ESTABLISH:
        return CreateEstablishPledgeSummary(props);

      case PLEDGE_TYPE_PAYMENT:
        if (props.existingPledgeIDKnown) {
          return CreateExistingPledgeSummaryWithID(props);
        } else {
          return CreateExistingPledgeSummaryNoID(props);
        }
    }
  }
};

const PledgeSummaryView = (props: IProps) => {
  const history = useHistory();
  const content = getSummaryItems(props);

  const clearCurrentPledge = () => {
    const pledgeid = props.existingPledgeID;
    props.clearExistingPerPayments();
    props.clearCart();

    if (props.checkoutPage) {
      history.push(
        Routes.Roots.Pledge.path +
          Routes.Sections.Search.path +
          '?pledgeid=' +
          pledgeid
      );
    }
  };

  const submitButton = props.checkoutPage ? (
    <PaymentButton />
  ) : (
    <Link
      to={Routes.Roots.Pledge.path + Routes.Sections.Checkout.path}
      className={`ut-btn corner--pill ${
        props.pledgeValid ? '' : 'btn--disabled'
      }`}
    >
      Checkout
    </Link>
  );

  const cancelButton = (
    <button className='ut-link--secondary' onClick={clearCurrentPledge}>
      {props.checkoutPage ? 'Go Back' : 'Remove'}
    </button>
  );

  return (
    <div className='pledge-summary'>
      <PledgeSummaryContent
        cancelButton={cancelButton}
        submitButton={submitButton}
        {...content}
      />
    </div>
  );
};

export const PledgeSummary = connector(withRouter(PledgeSummaryView));
