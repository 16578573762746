import * as React from 'react';
import { useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';
import { appendBasePath, Routes } from '../../../router/Routes';
import { RootState } from '../../../store';
import {
  getDefaultPaymentFormValues,
  getShowMissingFields,
} from '../../../store/payment/selectors';
import { MobileGiftSummaryView } from '../../containers/giftSummary/mobile/MobileGiftSummary';
import { OGPMobileNav } from '../../containers/mobileNav/OGPMobileNav';
import {
  AmountPageMobile,
  NextButton as AmountNextButton,
} from '../../pages/amount/mobile/AmountPageMobile';
import {
  FrequencyPageMobile,
  NextButton as FrequencyNextButton,
} from '../../pages/frequency/mobile/FrequencyPageMobile';
import {
  NextButton as SearchNextButton,
  SearchPageMobile,
} from '../../pages/search/mobile/SearchPageMobile';
import {
  CheckoutPageMobile,
  NextButton as CheckoutNextButton,
} from '../checkout/mobile/CheckoutPageMobile';
import './OGPPageMobile.scss';

const basePath = Routes.Roots.OGP.path;

interface OGPRouterContent {
  checkout: React.ReactNode;
  frequency: React.ReactNode;
  amount: React.ReactNode;
  search: React.ReactNode;
}

const mapStateToProps = (state: RootState) => {
  return {
    defaultValues: getDefaultPaymentFormValues(state),
    showMissingFields: getShowMissingFields(state),
  };
};

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type IProps = ReduxProps & {};

const createOGPMobileRouter = (content: OGPRouterContent) => {
  const appendOGPPath = appendBasePath(basePath);

  return (
    <>
      <Route path={appendOGPPath(Routes.Sections.Checkout.path)}>
        {content.checkout}
      </Route>
      <Route path={appendOGPPath(Routes.Sections.Frequency.path)}>
        {content.frequency}
      </Route>
      <Route path={appendOGPPath(Routes.Sections.Amount.path)}>
        {content.amount}
      </Route>
      <Route
        exact
        path={[
          appendOGPPath(Routes.Sections.Search.path),
          appendOGPPath(Routes.SearchArea.path),
        ]}
      >
        {content.search}
      </Route>
    </>
  );
};

const OGPPageMobileView = (props: IProps) => {
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: props.defaultValues,
  });

  const [summaryheightPx, setSummaryHeightPx] = React.useState(0);
  const contentPaddingBottom = `calc(${summaryheightPx}px + 1rem)`;

  const location = useLocation().pathname.slice(1);
  const showExpandableSummary =
    location === Routes.Sections.Checkout.path ||
    location === Routes.Sections.Amount.path;

  const pageRouterContent = createOGPMobileRouter({
    search: <SearchPageMobile />,
    frequency: <FrequencyPageMobile />,
    amount: <AmountPageMobile />,
    checkout: <CheckoutPageMobile form={form} />,
  });

  const nextButtonRouterContent = createOGPMobileRouter({
    search: <SearchNextButton />,
    frequency: <FrequencyNextButton />,
    amount: <AmountNextButton />,
    checkout: <CheckoutNextButton />,
  });

  return (
    <div className='ogp-page-mobile'>
      <OGPMobileNav basePath={basePath} />

      <div style={{ paddingBottom: contentPaddingBottom }}>
        {pageRouterContent}
      </div>
      <div className='mob-pin-bottom'>
        <MobileGiftSummaryView
          form={form}
          showExpandableSummary={showExpandableSummary}
          nextButton={nextButtonRouterContent}
          onHeightUpdated={setSummaryHeightPx}
        />
      </div>
    </div>
  );
};

export const OGPPageMobile = connector(OGPPageMobileView);
