import * as React from 'react';

import { IStore as ICartStore } from '../../../../../store/cart/types';
import { checkoutLink } from '../../../../../util/EmbedLinks';

import { GiftFrequencyTooltip } from '../../../../components/infoTooltip/freqency/GiftFrequencyTooltip';
import { Radio } from '../../../../components/radio/Radio';

import {
  oneTime,
  monthly,
  annually,
} from '../../../../../../assets/values/singleGiftFrequencies.json';
import { amounts } from '../../../../../../assets/values/singleGiftAmounts.json';

import { CurrencyInput } from '../../../../components/textInput/CurrencyInput';
import './EmbedAmountPage.scss';
import { errorForCurrencyInput } from '../../../../../util/ValidationUtils';
import { useSelector } from 'react-redux';
import { getURLData } from '../../../../../store/legacy/selectors';
import { ILegacyURLData } from '../../../../../models/LegacyURLData';

export interface IProps {
  cart?: ICartStore;

  frequencyValue?: string;
  setFrequencyValue: (frequency?: string) => void;

  amount?: number;
  setAmount: (amount?: number) => void;

  onBackPressed: () => void;

  hidden?: boolean;
  fixedHeight?: boolean;
}

const baseClassName = 'embed-amount-page';
const bottomButtonClass = `${baseClassName}__bottom-button`;
const sharedButtonClasses = 'ut-btn corner--pill';

const initialButtonValue = (amount?: number) =>
  amount && amounts.includes(amount) ? amount : undefined;

const initialCustomValue = (amount?: number) =>
  amount && amounts.includes(amount) ? undefined : amount;

export const EmbedAmountPage = (props: IProps) => {
  const urlData = useSelector(getURLData);

  const [customAmount, setCustomAmount] = React.useState<number | undefined>(
    initialCustomValue(props.amount)
  );
  const [amountButtonValue, setAmountButtonValue] = React.useState<
    number | undefined
  >(initialButtonValue(props.amount));

  const hiddenClass = props.hidden ? `${baseClassName}--hidden` : '';
  const fixedHeightClass = props.fixedHeight
    ? `${baseClassName}--fixed-height`
    : '';

  return (
    <div className={`${baseClassName} ${hiddenClass} ${fixedHeightClass}`}>
      <AmountSection
        customAmount={customAmount}
        buttonAmount={amountButtonValue}
        setCustomAmount={amount => {
          setCustomAmount(amount);
          props.setAmount(amount);
        }}
        setAmountButtonValue={amount => {
          setAmountButtonValue(amount);
          props.setAmount(amount);
        }}
      />
      <FrequencySection
        frequencyValue={props.frequencyValue}
        setFrequencyValue={props.setFrequencyValue}
      />
      <div className={`${baseClassName}__button-container`}>
        <button
          className={`${sharedButtonClasses} ${bottomButtonClass} ut-btn--secondary`}
          onClick={props.onBackPressed}
        >
          Go Back
        </button>
        <CheckoutButton cart={props.cart} urlData={urlData} />
      </div>
    </div>
  );
};

const AmountSection = (props: {
  buttonAmount?: number;
  setAmountButtonValue: (amount?: number) => void;

  customAmount?: number;
  setCustomAmount: (amount?: number) => void;
}) => {
  const [customAmountError, setCustomAmountError] = React.useState<string>();

  const buttonForAmount = (amount: number) => {
    const isSelected = props.buttonAmount === amount;
    const onClick = () => {
      props.setCustomAmount(undefined);
      props.setAmountButtonValue(amount);
    };

    const activeClass = isSelected ? 'active' : '';

    return (
      <button
        key={`${amount}`}
        className={` ${sharedButtonClasses} ${baseClassName}__amount-btn ${activeClass} btn--small ut-btn--secondary`}
        onClick={onClick}
      >
        ${amount}
      </button>
    );
  };

  const onCustomFieldUpdated = (value?: string) => {
    let parsedValue = value ? parseFloat(value) : undefined;
    const customAmountError = errorForCurrencyInput(parsedValue);

    if (customAmountError) {
      parsedValue = undefined;
    }

    setCustomAmountError(customAmountError);

    props.setAmountButtonValue(undefined);
    props.setCustomAmount(parsedValue);
  };

  const { first: firstAmounts, last: lastAmounts } = sliceAmounts();

  return (
    <div className={`${baseClassName}__amount-section`}>
      <div className={`${baseClassName}__amount-title`}>Gift Amount</div>
      <div className={`${baseClassName}__amounts`}>
        {firstAmounts?.map(buttonForAmount)}
        <div className={`${baseClassName}__button-break`}></div>
        {lastAmounts?.map(buttonForAmount)}
      </div>
      <CurrencyInput
        className={`${baseClassName}__custom-amount`}
        onChange={onCustomFieldUpdated}
        placeholder='Custom Amount'
        error={customAmountError}
      />
    </div>
  );
};

const FrequencySection = (props: {
  frequencyValue?: string;
  setFrequencyValue: (frequency?: string) => void;
}) => {
  return (
    <div className={`${baseClassName}__frequency-section`}>
      <div className={`${baseClassName}__frequency-title`}>
        Gift Frequency
        <GiftFrequencyTooltip
          isEmbedded
          className={`${baseClassName}__frequency-tooltip`}
        />
      </div>
      <Radio
        className={`${baseClassName}__frequency-radio`}
        options={[oneTime, monthly, annually]}
        onChange={props.setFrequencyValue}
        value={props.frequencyValue}
      />
    </div>
  );
};

const CheckoutButton = (props: {
  cart?: ICartStore;
  urlData: ILegacyURLData | undefined;
}) => {
  const disabledClass = props.cart ? '' : 'btn--disabled';

  const href = checkoutLink(props.cart, document.referrer, props.urlData);
  return (
    <a
      className={`${sharedButtonClasses} ${disabledClass} ${bottomButtonClass}`}
      href={href}
      target='blank'
    >
      Begin Checkout
    </a>
  );
};

// Splits the giving amounts in two so we can insert the button linebreak
const sliceAmounts = () => {
  const length = amounts.length;
  const halfLength = length / 2;

  try {
    return {
      first: amounts.slice(0, halfLength),
      last: amounts.slice(halfLength, length),
    };
  } catch {
    // Unused
  }
  return {};
};
