import * as Types from './types';

export const initialState: Types.ILayoutState = {
  footerWidth: 0,
  headerHeight: 0,
  navHeight: 0,

  textFieldOpen: false,
};

export function layoutReducer(
  state = initialState,
  action: Types.ActionType
): Types.ILayoutState {
  switch (action.type) {
    case Types.SET_FOOTER_WIDTH:
      return { ...state, footerWidth: action.footerWidth };
    case Types.SET_HEADER_HEIGHT:
      return { ...state, headerHeight: action.headerHeight };
    case Types.SET_NAV_HEIGHT:
      return { ...state, navHeight: action.navHeight };
    case Types.SET_TEXT_FIELD_OPEN:
      return { ...state, textFieldOpen: action.open };
  }

  return state;
}
