import { ITransactionResponse } from '../../models/api/TransactionResponse';

export interface ITransactionState {
  transactionProcessing: boolean;
  transactionResponse?: ITransactionResponse;
}

export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const SET_TRANSACTION_PROCESSING = 'SET_TRANSACTION_PROCESSING';

interface ISetTransactionProcessing {
  type: typeof SET_TRANSACTION_PROCESSING;
  processing: boolean;
}

interface IUpdateTransactionAction {
  type: typeof UPDATE_TRANSACTION;
  transactionResponse?: ITransactionResponse;
}

export type ActionType = ISetTransactionProcessing | IUpdateTransactionAction;
