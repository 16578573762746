import React from 'react';
import FormKeys from '../../../util/fieldNames/CheckoutFieldNames';
import './MissingFieldsView.scss';

interface IProps {
  form: any;
  showMissing: boolean;
}

const baseClassName = 'missing-fields';

const MissingFieldsView = (props: IProps) => {
  const { form, showMissing } = props;
  const { fields } = FormKeys;

  const values = form.getValues();
  const errors = form.errors;
  const missingFields: string[] = [];

  Object.values(fields).forEach(field => {
    if (
      values.hasOwnProperty(field.name) &&
      field.required &&
      (values[field.name] === undefined ||
        values[field.name].length === 0 ||
        errors.hasOwnProperty(field.name))
    ) {
      missingFields.push(field.missingText);
    }
  });

  const hideClassName =
    !showMissing || missingFields.length === 0
      ? ` ${baseClassName}--hidden`
      : '';
  return (
    <div className={`${baseClassName}${hideClassName}`}>
      <ul>
        {missingFields.map(missingField => (
          <li key={missingField}>{missingField}</li>
        ))}
      </ul>
    </div>
  );
};

export default MissingFieldsView;
