import { alert } from '../store/modal/actions';

export const alertAPIError = (message?: string) =>
  alert(
    'An error has occurred.',
    message || 'Please refresh the page and try again.'
  );

export const getReCAPTCHAKey = () => {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === ''
  ) {
    return process.env.REACT_APP_RECAPTCHA_LOCAL || '';
  }

  return process.env.REACT_APP_RECAPTCHA || '';
};
