const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const FormatCurrency = (
  amount?: number,
  showDollarSign: boolean = true,
  stripTrailingZeroes: boolean = false
) => {
  if (amount === undefined) return '';

  let formatted = currencyFormatter.format(amount);
  if (!showDollarSign) {
    formatted = formatted.replace('$', '');
  }

  if (stripTrailingZeroes) {
    formatted = formatted.replace(/\.00/g, '');
  }

  return formatted;
};

export const NumberWithCommas = (amount?: number) =>
  amount !== undefined
    ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : amount;
