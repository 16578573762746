import { createSelector } from 'reselect';
import { RootState } from '..';
import { IDedicationRecipient } from '../../models/DedicationRecipient';
import FormKeys from '../../util/fieldNames/CheckoutFieldNames';

const getLegacyState = (state: RootState) => state.legacy;

export const getURLData = createSelector(
  [getLegacyState],
  state => state.urlData
);

export const getMenu = createSelector([getURLData], data => data?.menu);

export const getGivingItems = createSelector(
  [getLegacyState],
  state => state.givingItems
);

export const getDedication = createSelector([getURLData], data => {
  let toReturn: IDedicationRecipient | undefined;
  if (data?.recog && data?.recogname) {
    toReturn = {
      dedicationName: data.recogname,
      dedicationType: data.recog,
    };
  }

  return toReturn;
});

export const getCodingsString = createSelector([getURLData], data => {
  if (!data || !data.codings || !Object.keys(data.codings).length) {
    return '';
  }

  const allCodingStrings = Object.keys(data.codings).map(
    key => `{'codeType': '${key}', 'codeValue': '${data.codings![key]}'}`
  );

  return `[${allCodingStrings.join(',')}]`;
});

export const getFormFromLegacyData = createSelector(
  [getURLData, getCodingsString],
  data => ({
    [FormKeys.fields.giftRemarks.name]: data?.comments,
    [FormKeys.fields.donorUtEid.name]: data?.eid,
  })
);
