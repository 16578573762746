export interface IReferrerState {
  referrer?: string;
}

export const SET_REFERRER = 'SET_REFRERRER';

interface ISetReferrer {
  type: typeof SET_REFERRER;
  referrer?: string;
}

export type ActionType = ISetReferrer;
