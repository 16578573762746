import { all, call, take, fork, put, select } from 'redux-saga/effects';
import * as Types from './types';
import * as GivingItemEndpoint from '../../api/endpoints/GivingItemEndpoint';
import { IGivingItemResponse } from '../../models/api/GivingItemResponse';
import { notEmpty } from '../../util/ArrayUtils';
import { updateSkuGivingItems, updateDepartmentGivingItems } from './actions';
import { IGivingItemCollectionResponse } from '../../models/api/GivingItemCollectionResponse';
import { getDepartmentTitle, getDepartmentCode } from './selectors';
import { alertAPIError } from '../../util/APIUtils';

function* fetchItemsForDepartment(departmentCode: string) {
  try {
    const response: IGivingItemCollectionResponse = yield call(
      GivingItemEndpoint.fetchGivingItemsFromDepartment,
      departmentCode
    );
    yield put(updateDepartmentGivingItems(response.facets[0].givingItems));
  } catch (error) {
    yield put(alertAPIError());
  }
}

function* fetchGivingItem(sku: string) {
  try {
    const response: IGivingItemResponse = yield call(
      GivingItemEndpoint.fetchGivingItem,
      sku
    );

    return response;
  } catch (error) {
    yield put(alertAPIError());
  }
}

function* watchSkusSaga() {
  while (true) {
    const action = yield take(Types.FETCH_GIVING_ITEMS);
    const skus: string[] = action.skus;
    const responses: IGivingItemResponse[] = yield all(
      skus.map(sku => call(fetchGivingItem, sku))
    );
    const givingItems = responses.map(res => res?.item).filter(notEmpty);

    yield put(updateSkuGivingItems(givingItems));
  }
}

function* watchDepartmentCodeSaga() {
  while (true) {
    const lastDepartmentCode = yield select(getDepartmentCode);
    yield take(Types.UPDATE_DEPARTMENT_CODE);
    const departmentCode = yield select(getDepartmentCode);

    if (departmentCode !== lastDepartmentCode) {
      yield fork(fetchItemsForDepartment, departmentCode);
    }
  }
}

function* watchDepartmentTitleSaga() {
  while (true) {
    const lastDepartmentTitle = yield select(getDepartmentTitle);
    yield take(Types.UPDATE_DEPARTMENT_TITLE);
    const departmentTitle = yield select(getDepartmentTitle);

    if (departmentTitle !== lastDepartmentTitle) {
      yield fork(fetchItemsForDepartment, departmentTitle);
    }
  }
}

export default function* embedSaga() {
  yield fork(watchSkusSaga);
  yield fork(watchDepartmentCodeSaga);
  yield fork(watchDepartmentTitleSaga);
}
