import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store';

import { setSearchQuery, setShowMore } from '../../../../store/search/actions';
import { alert } from '../../../../store/modal/actions';
import * as singleGiftActions from '../../../../store/cart/singleGift/actions';
import * as searchSelectors from '../../../../store/search/selectors';
import * as cartSelectors from '../../../../store/cart/selectors';
import * as singleGiftSelectors from '../../../../store/cart/singleGift/selectors';
import { GIFT_TYPE_SINGLE } from '../../../../models/GiftType';
import { IGivingItem } from '../../../../models/GivingItem';
import { fullCartError } from '../../../../util/copy/CartErrors';

export const createOnClick = (props: IProps, item: IGivingItem) => () => {
  const sku = item.givingItemSignature.sku;
  const inCart = props.isItemInCart(sku);

  if (inCart) {
    props.onRemoveFromCart(sku);
    return;
  }

  if (props.isCartFull) {
    props.alert(fullCartError.title, fullCartError.message);
    return;
  }

  props.onAddToCart(item);
};

const mapStateToProps = (state: RootState) => {
  const isItemInCart = singleGiftSelectors.hasGivingItemWithSkuCreator();
  const getItemsForFacetName =
    searchSelectors.getGivingItemsForFacetNameCreator();
  const isCartFull =
    cartSelectors.getCartIsFullForGiftTypeCreator(GIFT_TYPE_SINGLE);

  return {
    loading: searchSelectors.getLoading(state),
    query: searchSelectors.getSearchQuery(state),
    getItemsForFacetName: getItemsForFacetName(state),
    queryFacets: searchSelectors.getQueryFacets(state),
    facets: searchSelectors.getFacets(state),
    givingItemError: searchSelectors.getGivingItemError(state),
    isItemInCart: isItemInCart(state),
    isCartFull: isCartFull(state),
    showMore: searchSelectors.getShowMore(state),
  };
};

const mapDispatchToProps = {
  setSearchQuery,
  setShowMore,
  alert,
  onAddToCart: singleGiftActions.addGivingItem,
  onRemoveFromCart: singleGiftActions.removeGivingItem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export type IProps = ReduxProps & {
  styleMobile?: boolean;
};

export const connectSearchProps = (component: React.ComponentType<IProps>) =>
  connector(component);
