export interface IPaymentState {
  uniqueID: string;
  formData?: any;
  formIsValid: boolean;
  showMissingFields: boolean;
}

export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_FORM_VALID = 'SET_FORM_VALID';
export const SET_FORM_MISSING_FIELDS = 'SET_FORM_MISSING_FIELDS';
export const REFRESH_UNIQUE_ID = 'REFRESH_UNIQUE_ID';
export const RESET_FORM_DATA = 'RESET_FORM_DATA';

interface ISetFormDataAction {
  type: typeof SET_FORM_DATA;
  formData?: any;
}
interface IResetFormDataAction {
  type: typeof RESET_FORM_DATA;
}

interface ISetFormValidAction {
  type: typeof SET_FORM_VALID;
  valid: boolean;
}

interface ISetShowMissingFieldsAction {
  type: typeof SET_FORM_MISSING_FIELDS;
  showMissingFields: boolean;
}

interface IRefreshUniqueID {
  type: typeof REFRESH_UNIQUE_ID;
}

export type ActionType =
  | ISetFormDataAction
  | ISetFormValidAction
  | ISetShowMissingFieldsAction
  | IRefreshUniqueID
  | IResetFormDataAction;
