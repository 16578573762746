import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Routes } from '../../../router/Routes';
import { TabBar, ITabBarItem } from '../../components/tabBar/TabBar';

import './MobileNav.scss';

export interface IProps {
  basePath: string;
  items: ITabBarItem[];
  className?: string;
  styleNarrow?: boolean;
}

export const MobileNav = (props: IProps) => {
  const location = useLocation();
  const history = useHistory();

  const hideNav =
    location.pathname === props.basePath ||
    location.pathname === Routes.Sections.Search.path;

  const onSelect = (value: string) => {
    history.push(value);
  };

  return (
    <div className='mobile-nav__tab-bar-container'>
      <TabBar
        onSelect={onSelect}
        value={location.pathname}
        options={props.items}
        hidden={hideNav}
        hideFirstTabAnimation
        className={props.className}
        styleNarrow={props.styleNarrow}
      />
    </div>
  );
};
