import React from 'react';

import './AlertModal.scss';

export const AlertModal = (props: IProps) => (
  <div className='modal'>
    <h3 className='modal__header'>{props.title}</h3>
    <div className='modal__content'>
      <p>{props.message}</p>
    </div>
    <div className='modal__button-container'>
      <button
        className='ut-btn btn--small corner--pill modal__button'
        onClick={props.onClose}
      >
        Close
      </button>
    </div>
  </div>
);

export interface IProps {
  title: string;
  message: string;
  onClose: () => void;
}

export const windowClassName = 'alert-modal__window';
