import countriesJSON from '../../../assets/values/countries.json';

export const mapCountriesToOptions = (countries: any[]) =>
  countries.map(country => ({
    label: country.name,
    value: country.name,
  }));

export const countriesAsOptions = mapCountriesToOptions(countriesJSON);

export const defaultProvinceForCountryValue = (value: string) => {
  return value === unitedStates?.value ? 'TX' : undefined;
};

export const unitedStates = (() => {
  return countriesAsOptions.find(item => item.label === 'United States');
})();
