import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ILegacyURLData } from '../../../models/LegacyURLData';
import { setURLData } from '../../../store/legacy/actions';

import {
  setDark,
  setAmountButtons,
  setSku,
} from '../../../store/miniEmbed/actions';

import { AMOUNT_BUTTONS, DARK, LegacyKeys, SKU } from '../../../util/URLKeys';

export interface IProps {
  children: React.ReactNode;
}

export const MiniEmbedQueryUnwrapper = (props: IProps) => {
  const searchString = useLocation().search;
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(searchString);

    const dark = unwrapDarkFromURLParams(queryParams);
    dispatch(setDark(dark));

    const amountButtons = unwrapAmountButtonsFromURLParams(queryParams);
    dispatch(setAmountButtons(amountButtons));

    const sku = unwrapSkuFromURLParams(queryParams);
    dispatch(setSku(sku));

    const urlData = unwrapSolicitFromURLParams(queryParams);
    dispatch(setURLData(urlData));
  }, [searchString, dispatch]);

  return <>{props.children}</>;
};

const unwrapDarkFromURLParams = (params: URLSearchParams) => {
  try {
    const isDarkValue = params.get(DARK) || undefined;
    return isDarkValue === 'true';
  } catch (error) {
    onError(error);
  }
};

const unwrapSkuFromURLParams = (params: URLSearchParams) => {
  try {
    return params.get(SKU) || undefined;
  } catch (error) {
    onError(error);
  }
};

const unwrapAmountButtonsFromURLParams = (params: URLSearchParams) => {
  try {
    const amountsString = params.get(AMOUNT_BUTTONS) || undefined;
    if (amountsString) {
      const amountsAsStrings = amountsString.split(',');
      return amountsAsStrings.map(str => parseFloat(str));
    }
  } catch (error) {
    onError(error);
  }
};

const unwrapSolicitFromURLParams = (params: URLSearchParams) => {
  const unwrap = (key: string) => params.get(key) || undefined;

  try {
    return {
      codings: {
        [LegacyKeys.CODE_SOLICIT]: unwrap(LegacyKeys.CODE_SOLICIT),
      },
    } as ILegacyURLData;
  } catch (error) {
    onError(error);
  }
};

const onError = (error: any) => {
  // tslint:disable-next-line:no-console
  console.error(error);
};
