import {
  call,
  cancel,
  put,
  take,
  select,
  fork,
  delay,
} from 'redux-saga/effects';

import * as Types from './types';
import {
  fetchGivingItemsSuccess,
  setLoading,
  setQuery,
  setShowMore,
} from './actions';

import * as SearchAPI from '../../api/endpoints/GivingItemEndpoint';
import { IGivingItemCollectionResponse } from '../../models/api/GivingItemCollectionResponse';
import {
  getQuery,
  getSearchQuery,
  isQueryValid,
  getQueryFacets,
  getDepartmentCode,
  getFeaturedArea,
} from './selectors';
import { alertAPIError } from '../../util/APIUtils';

function* fetchGivingItems(debounceTime?: number, abortSignal?: AbortSignal) {
  yield put(setShowMore(false));

  const queryValid = yield select(isQueryValid);
  if (!queryValid) {
    return;
  }
  const queryFacets = yield select(getQueryFacets);
  if (!queryFacets) {
    return;
  }
  const departmentCode = yield select(getDepartmentCode);
  const featuredArea = yield select(getFeaturedArea);

  const query = yield select(getQuery);
  try {
    yield put(setLoading(true));

    if (debounceTime) {
      yield delay(debounceTime);
    }

    const response: IGivingItemCollectionResponse = yield call(
      SearchAPI.fetchGivingItemsFromQuery,
      query,
      queryFacets,
      departmentCode,
      featuredArea,
      query.length ? undefined : 5,
      abortSignal
    );

    const currentQuery = yield select(getQuery);
    const currentArea = yield select(getFeaturedArea);
    if (response && query === currentQuery && featuredArea === currentArea) {
      yield put(fetchGivingItemsSuccess(response.facets));
      yield put(setLoading(false));
    }
  } catch (error) {
    yield put(alertAPIError());
    yield put(setLoading(false));
  }
}

function* checkSearchQuerySaga() {
  while (true) {
    const lastQuery = yield select(getSearchQuery);
    yield take(Types.SET_SEARCH_QUERY);
    const newQuery = yield select(getSearchQuery);

    if (lastQuery !== newQuery) {
      yield put(setQuery(newQuery));
    }
  }
}

function* checkQuerySaga() {
  let searchTask;
  let abortController;

  while (true) {
    const lastQuery = yield select(getQuery);
    yield take(Types.SET_QUERY);
    const newQuery = yield select(getQuery);

    if (lastQuery !== newQuery) {
      if (searchTask) {
        yield cancel(searchTask);
        abortController?.abort();
      }

      abortController = new AbortController();
      searchTask = yield fork(fetchGivingItems, 300, abortController.signal);
    }
  }
}

function* checkDepartmentCodeSaga() {
  while (true) {
    const lastDepartmentCode = yield select(getDepartmentCode);
    yield take(Types.SET_DEPARTMENT_CODE);
    const newDepartmentCode = yield select(getDepartmentCode);

    if (lastDepartmentCode !== newDepartmentCode) {
      yield fork(fetchGivingItems);
    }
  }
}

function* checkQueryFacetsSaga() {
  while (true) {
    const lastQueryFacets = yield select(getQueryFacets);
    yield take(Types.SET_QUERY_FACETS);
    const newQueryFacets = yield select(getQueryFacets);

    if (lastQueryFacets !== newQueryFacets) {
      yield fork(fetchGivingItems);
    }
  }
}

function* checkFeaturedAreaSaga() {
  while (true) {
    const lastArea = yield select(getFeaturedArea);
    yield take(Types.SET_FEATURED_AREA);
    const newArea = yield select(getFeaturedArea);

    if (lastArea !== newArea) {
      yield fork(fetchGivingItems);
    }
  }
}

function* searchSaga() {
  yield fork(checkSearchQuerySaga);
  yield fork(checkQuerySaga);
  yield fork(checkQueryFacetsSaga);
  yield fork(checkDepartmentCodeSaga);
  yield fork(checkFeaturedAreaSaga);
}

export default searchSaga;
