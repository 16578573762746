import * as React from 'react';

import { no, yes } from '../../../../assets/values/yesNo.json';
import { ISharedInputProps } from '../textInput/InputWrapper';

import './Checkbox.scss';

export const Checkbox = (props: IProps) => {
  const className = props.className || '';
  const isChecked = props.value === true || props.value === yes.value;

  const labelChecked = isChecked ? 'ogp-checkbox__label--checked' : '';
  const brandColor = props.brandColor ? 'ogp-checkbox__label--brand-color' : '';

  const onCheckedChanged = () => {
    const newChecked = !isChecked;

    if (props.onChange) {
      props.onChange(newChecked);
    }
    if (props.onChangeAsString) {
      props.onChangeAsString(newChecked ? yes.value : no.value);
    }
  };

  return (
    <div className={`ogp-checkbox ${className}`} onClick={onCheckedChanged}>
      <input
        className={`ogp-checkbox__input`}
        id={props.id}
        value={props.id}
        name={props.name}
        checked={isChecked}
        readOnly
        type='checkbox'
      ></input>
      <label
        className={`ogp-checkbox__label ${labelChecked} ${brandColor}`}
        htmlFor={props.id}
      >
        {props.label}
      </label>
    </div>
  );
};

export type IProps = ISharedInputProps & {
  name?: string;
  value?: boolean | string;

  brandColor?: boolean;
  onChange?: (checked: boolean) => void;
  onChangeAsString?: (checked: string) => void;
};
