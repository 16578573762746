import * as React from 'react';
import { IGivingItem } from '../../../../../models/GivingItem';
import { EmbedGivingItem } from '../../micros/givingItem/EmbedGivingItem';

import './EmbedItemsPage.scss';

const MAX_GIFTS_IN_CART = 3;

export interface IProps {
  givingItems: IGivingItem[];

  selectedItems: IGivingItem[];
  setSelectedItems: (items: IGivingItem[]) => void;

  onCartFullError: () => void;

  nextButtonEnabled?: boolean;
  onNextButtonPressed: () => void;

  hidden?: boolean;
}

const baseClassName = 'embed-items-page';

export const EmbedItemsPage = (props: IProps) => {
  const hiddenClass = props.hidden ? `${baseClassName}--hidden` : '';
  const buttonDisabledClass = props.nextButtonEnabled ? '' : 'btn--disabled';

  return (
    <div className={`${baseClassName} ${hiddenClass}`}>
      <div>
        {props.givingItems?.map(
          renderGivingItem(
            props.selectedItems,
            props.setSelectedItems,
            props.onCartFullError
          )
        )}
      </div>
      <div className={`${baseClassName}__button-container`}>
        <button
          className={`ut-btn corner--pill ${buttonDisabledClass} ${baseClassName}__next`}
          onClick={props.onNextButtonPressed}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const renderGivingItem =
  (
    selectedItems: IGivingItem[],
    setSelectedItems: (skus: IGivingItem[]) => void,
    onCartFullError: () => void
  ) =>
  (item: IGivingItem, index: number) => {
    const isInCart = selectedItems.includes(item);

    const onClick = () => {
      if (isInCart) {
        setSelectedItems(
          selectedItems.filter(
            temp =>
              temp.givingItemSignature.sku !== item.givingItemSignature.sku
          )
        );
      } else {
        if (selectedItems.length >= MAX_GIFTS_IN_CART) {
          onCartFullError();
          return;
        }
        setSelectedItems([...selectedItems, item]);
      }
    };

    return (
      <React.Fragment key={`${index}`}>
        <EmbedGivingItem
          givingItem={item}
          isInCart={isInCart}
          onButtonClick={onClick}
        />
      </React.Fragment>
    );
  };
