import React from 'react';

import honoreeCard from '../../../../assets/images/notification-preview/honoree-card.png';
import honoreeEmail from '../../../../assets/images/notification-preview/honoree-email.png';
import memorialCard from '../../../../assets/images/notification-preview/memorial-card.png';
import memorialEmail from '../../../../assets/images/notification-preview/memorial-email.png';

import {
  honorary,
  memorial,
} from '../../../../assets/values/dedicationTypes.json';

import './PreviewNotificationModal.scss';
import '../Modal.scss';
import { useOutsideAlerter } from '../../effects/OutsideAlerter';

export const TYPE_EMAIL = 'TYPE_EMAIL';
export const TYPE_CARD = 'TYPE_CARD';

type PreviewType = typeof TYPE_EMAIL | typeof TYPE_CARD;

const titleForType = (previewType: PreviewType) => {
  switch (previewType) {
    case 'TYPE_EMAIL':
      return 'Email Preview';
    case 'TYPE_CARD':
      return 'Card Preview';
  }
};

const imageForType = (dedicationType: string, previewType: PreviewType) => {
  switch (dedicationType) {
    case memorial.value:
      switch (previewType) {
        case TYPE_EMAIL:
          return memorialEmail;
        case TYPE_CARD:
          return memorialCard;
      }
      break;
    case honorary.value:
      switch (previewType) {
        case TYPE_EMAIL:
          return honoreeEmail;
        case TYPE_CARD:
          return honoreeCard;
      }
      break;
  }
};

export const PreviewNotificationModal = (props: IProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  useOutsideAlerter(containerRef, props.onClose);

  return (
    <div className='modal' ref={containerRef}>
      <h3 className='modal__header'>{titleForType(props.previewType)}</h3>
      <div className='preview-notification__preview-container modal__content'>
        <img
          alt={titleForType(props.previewType)}
          src={imageForType(props.dedicationTypeValue, props.previewType)}
        />
      </div>
      <div className='modal__button-container'>
        <button
          className='ut-btn btn--small corner--pill modal__button'
          onClick={props.onClose}
        >
          Exit Preview
        </button>
      </div>
    </div>
  );
};

export interface IProps {
  previewType: PreviewType;
  dedicationTypeValue: string;
  onClose: () => void;
}

export const windowClassName = 'preview-notification__window';
