import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as PledgeEndpoint from '../../api/endpoints/PledgeEndpoint';
import { IPledgeInformationResponse } from '../../models/api/PledgeInformationResponse';
import { alertAPIError } from '../../util/APIUtils';
import {
  fetchExistingPledgeSuccess,
  setExistingPledgeIsLoading,
} from './actions';
import { getPledgeID } from './existingPledge/selectors';
import * as PledgeTypes from './types';

function* fetchPledgeInformation() {
  const pledgeID = yield select(getPledgeID);
  if (pledgeID && pledgeID.length) {
    const isLoading = yield put(setExistingPledgeIsLoading(true));
    const response: IPledgeInformationResponse = yield call(
      PledgeEndpoint.fetchPledgeInformation,
      pledgeID
    );

    if (response.success) {
      yield put(fetchExistingPledgeSuccess(pledgeID, response));
    } else {
      yield put(alertAPIError());
    }
    if (isLoading) {
      yield put(setExistingPledgeIsLoading(false));
    }
  }
}

function* pledgeSaga() {
  yield takeLatest(PledgeTypes.SET_EXISTING_PLEDGE_ID, fetchPledgeInformation);
}

export default pledgeSaga;
