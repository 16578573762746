import React from 'react';
import { IProps } from '../../PledgeView';
import { TextArea } from '../../../../components/textArea/TextArea';
import { errorForCurrencyInput } from '../../../../../util/ValidationUtils';
import { Link } from 'react-router-dom';

import { CurrencyInput } from '../../../../components/textInput/CurrencyInput';
import { Routes } from '../../../../../router/Routes';
import './PledgePaymentNoIDView.scss';

const baseClassName = 'pledge-payment-no-id';

export const PledgePaymentNoIDView = (props: IProps) => {
  const [customAmountError, setCustomAmountError] = React.useState<string>();

  const setCustomAmount = (value?: string) => {
    const parsedValue = value ? parseFloat(value) : undefined;

    const errorMsg = errorForCurrencyInput(parsedValue);

    const valid = !errorMsg;

    props.setExistingPledgeNoIDPaymentAmount(valid ? parsedValue : undefined);
    setCustomAmountError(errorMsg);
  };

  return (
    <div className={`${baseClassName}`}>
      <div className='d--f ai--c pb--20'>
        <CurrencyInput
          onChange={setCustomAmount}
          value={props.existingPledgeNoIDPaymentAmount}
          error={customAmountError}
          placeholder='Custom Amount'
          className={`${baseClassName}__custom-amount`}
          styleCurrency
        />
        <SubmitButton {...props} />
      </div>
      <TextArea
        label='Pledge Details'
        placeholder={
          'Please provide the name of the fund and/or the college, school or program your pledge supports.'
        }
        className='pb--30'
        maxLength={250}
        value={props.existingPledgeComments || ''}
        onChange={props.setExistingPledgeComments}
      />
      <SubmitButton {...props} mobile />
    </div>
  );
};

const SubmitButton = (props: IProps & { mobile?: boolean }) => {
  const buttonClass = `${baseClassName}__submit-button`;
  const buttonDisabledClass = !props.existingPledgeNoIDPaymentAmount
    ? 'btn--disabled'
    : '';
  const buttonMobileClass = props.mobile ? `${buttonClass}--mobile ` : '';
  const buttonLargeClass = props.mobile ? 'ut-btn--large' : 'btn--small';

  return (
    <Link
      to={Routes.Sections.Checkout.path}
      className={`ut-btn corner--pill ${buttonClass} ${buttonDisabledClass} ${buttonMobileClass} ${buttonLargeClass}`}
    >
      Begin Checkout
    </Link>
  );
};
