import * as Types from './types';

const initialState: Types.IStore = {
  facets: [],
  query: '',
  searchQuery: '', // Query that is bubbled up to the ui
  showMore: false,
  loading: true,
};

export function searchReducer(
  state = initialState,
  action: Types.ActionTypes
): Types.IStore {
  switch (action.type) {
    case Types.SET_QUERY:
      return { ...state, query: action.value };
    case Types.SET_SEARCH_QUERY:
      return { ...state, searchQuery: action.searchValue };
    case Types.SET_DEPARTMENT:
      return { ...state, department: action.department };

    case Types.SET_SHOW_MORE:
      return { ...state, showMore: action.showMore };
    case Types.SET_DEPARTMENT_CODE:
      return { ...state, departmentCode: action.departmentCode };
    case Types.SET_FEATURED_AREA:
      return { ...state, featuredArea: action.area };
    case Types.FETCH_GIVING_ITEMS:
      return { ...state, facets: undefined, givingItemError: undefined };
    case Types.SET_QUERY_FACETS:
      return { ...state, queryFacets: action.facets };
    case Types.FETCH_GIVING_ITEMS_SUCCESS:
      return { ...state, facets: action.facets };
    case Types.FETCH_GIVING_ITEMS_FAILURE:
      return { ...state, givingItemError: action.error };
    case Types.SET_LOADING:
      return { ...state, loading: action.loading };
  }
  return state;
}
