const expandToggle = 'expand-toggle';

export const createSummaryClasses = (baseClassName: string) => ({
  root: baseClassName,
  rootHidden: `${baseClassName}--hidden`,
  gifts: `${baseClassName}__gifts`,
  bottom: `${baseClassName}__bottom`,
  amount: `${baseClassName}__amount`,
  secondaryText: `${baseClassName}__secondary-text`,
  bottomButtonContainer: `${baseClassName}__bottom-button-container`,
  bottomButton: `${baseClassName}__bottom-button`,
  clearButton: `${baseClassName}__clear`,
  expandToggle: `${baseClassName}__${expandToggle}`,
  expandToggleExpanded: `${baseClassName}__${expandToggle}--expanded`,
  giftCount: `${baseClassName}__gift-count`,
  gift: `${baseClassName}__gift`,
  giftAmount: `${baseClassName}__gift-amount`,
  giftInfo: `${baseClassName}__gift-info`,
  giftName: `${baseClassName}__gift-name`,
  giftFrequency: `${baseClassName}__gift-frequency`,
});
