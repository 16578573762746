import pledgeGiftFrequenciesJSON from '../../../assets/values/pledgeGiftFrequencies.json';
import singleGiftFrequenciesJSON from '../../../assets/values/singleGiftFrequencies.json';

export const getSingleGiftFrequencyOption = (frequencyValue?: string) => {
  return Object.values(singleGiftFrequenciesJSON).find(
    obj => obj.value.toLowerCase() === frequencyValue?.toLowerCase()
  );
};

export const getPledgeFrequencyObject = (frequencyKey?: string) => {
  return Object.values(pledgeGiftFrequenciesJSON).find(
    obj => obj.value.toLowerCase() === frequencyKey?.toLowerCase()
  );
};

// Used the label "Annual total: $200". Strips the "ly" off the annual label to avoid the
// label reading "Annually total"
export const getSingleGiftInlineFrequency = (frequency?: {
  label: string;
  value: string;
}) => {
  if (!frequency) {
    return;
  }

  if (
    frequency.label === singleGiftFrequenciesJSON.annually.label ||
    frequency.label === pledgeGiftFrequenciesJSON.annually.label
  ) {
    return 'Annual';
  }

  return frequency.label;
};
