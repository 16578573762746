import * as Types from './types';

export function setFooterWidth(width: number): Types.ActionType {
  return {
    type: Types.SET_FOOTER_WIDTH,
    footerWidth: width,
  };
}
export function setHeaderHeight(headerHeight: number): Types.ActionType {
  return {
    type: Types.SET_HEADER_HEIGHT,
    headerHeight,
  };
}
export function setNavHeight(navHeight: number): Types.ActionType {
  return {
    type: Types.SET_NAV_HEIGHT,
    navHeight,
  };
}

export function setTextFieldOpen(open: boolean): Types.ActionType {
  return {
    type: Types.SET_TEXT_FIELD_OPEN,
    open,
  };
}
