import API from '../API';
import { IAddressLookupResponse } from '../../models/api/AddressLookupResponse';
import { ISuggestion } from '../../models/smartyStreets/Suggestion';

export function fetchAutocompleteSuggestions(query: string) {
  const queryParams = [{ name: 'search', value: query }];

  return API.executeSmartyStreetAutocomplete<IAddressLookupResponse>(
    'lookup',
    queryParams,
    { method: 'GET' }
  );
}

export function fetchSuggestionVerification(suggestion: ISuggestion) {
  const queryParams = [
    { name: 'street', value: suggestion.street_line },
    { name: 'city', value: suggestion.city },
    { name: 'state', value: suggestion.state },
    { name: 'zipcode', value: suggestion.zipcode },
  ];

  return API.executeSmartyStreetAddress<any>('street-address', queryParams, {
    method: 'GET',
  });
}
