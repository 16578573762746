import singleGiftAmounts from '../../../assets/values/singleGiftAmounts.json';
import {
  GIFT_TYPE_PLEDGE,
  GIFT_TYPE_SINGLE,
  GiftType,
} from '../../models/GiftType';
import {
  buildCartFromLegacyData,
  unwrapCartFromURLParams,
} from '../../util/serialization/SerializeCart';
import * as Types from './types';

export const initialState: Types.IStore = {
  builders: { SINGLE: {}, PLEDGE: {} },
  attributes: { SINGLE: {}, PLEDGE: {} },
};

function withGiftType(giftType: GiftType, state: Types.IStore) {
  return {
    giftType,
    skusToBuilders: state.builders[giftType],
    attributes: state.attributes[giftType],
  };
}

export function cartReducer(
  state = initialState,
  action: Types.ActionTypes
): Types.IStore {
  switch (action.type) {
    case Types.ADD_GIVING_ITEM:
      {
        const { giftType, skusToBuilders } = withGiftType(
          action.giftType,
          state
        );
        const newSku = action.value.givingItemSignature?.sku;

        let presetAmount;
        if (giftType === GIFT_TYPE_SINGLE) {
          presetAmount = singleGiftAmounts.amounts[1];
        }

        if (
          !(
            newSku in skusToBuilders ||
            Object.values(skusToBuilders).length >= Types.MAX_CART_SIZE
          )
        ) {
          return {
            ...state,
            builders: {
              ...state.builders,
              [giftType]: {
                ...skusToBuilders,
                [newSku]: {
                  givingItem: action.value,
                  type: giftType,
                  amount: presetAmount,
                },
              },
            },
          };
        }
      }
      break;
    case Types.REMOVE_GIVING_ITEM: {
      const { giftType, skusToBuilders, attributes } = withGiftType(
        action.giftType,
        state
      );
      const sku = action?.sku;

      if (sku in skusToBuilders) {
        const { [sku]: deletedBuilder, ...newGiftBuilders } = skusToBuilders;

        const hasRemainingBuilders = Object.keys(newGiftBuilders).length > 0;
        const newAttributes = hasRemainingBuilders ? attributes : {};

        return {
          ...state,
          builders: { ...state.builders, [giftType]: newGiftBuilders },
          attributes: { ...state.attributes, [giftType]: newAttributes },
        };
      }
      break;
    }
    case Types.UPDATE_GIFT_FREQUENCY: {
      const { giftType, attributes } = withGiftType(action.giftType, state);
      return {
        ...state,
        attributes: {
          ...state.attributes,
          [giftType]: {
            ...attributes,
            frequency: action.frequency,
            duration: undefined,
          },
        },
      };
    }
    case Types.UPDATE_GIFT_END_DATE: {
      const { giftType, attributes } = withGiftType(action.giftType, state);
      return {
        ...state,
        attributes: {
          ...state.attributes,
          [giftType]: {
            ...attributes,
            duration: action.duration,
          },
        },
      };
    }
    case Types.UPDATE_SINGLE_GIFT_AMOUNT: {
      const { amount, sku } = action;
      const { giftType, skusToBuilders } = withGiftType(
        GIFT_TYPE_SINGLE,
        state
      );
      const builder = skusToBuilders[sku];

      return {
        ...state,
        builders: {
          ...state.builders,
          [giftType]: {
            ...skusToBuilders,
            [sku]: { ...builder, amount },
          },
        },
      };
    }
    case Types.UPDATE_PLEDGE_AMOUNT: {
      const { giftType, attributes } = withGiftType(GIFT_TYPE_PLEDGE, state);
      return {
        ...state,
        attributes: {
          ...state.attributes,
          [giftType]: {
            ...attributes,
            amount: action.amount,
          },
        },
      };
    }
    case Types.UPDATE_PLEDGE_DURATION: {
      const { giftType, attributes } = withGiftType(GIFT_TYPE_PLEDGE, state);

      return {
        ...state,
        attributes: {
          ...state.attributes,
          [giftType]: {
            ...attributes,
            durationInYears: action.durationInYears,
          },
        },
      };
    }
    case Types.SET_CART_URL_PARAMS: {
      const newState = unwrapCartFromURLParams(action.params);

      return newState || state;
    }
    case Types.SET_CART_LEGACY_DATA:
      const newState = buildCartFromLegacyData(action.data, action.givingItems);

      return newState || state;

    case Types.CLEAR_CART: {
      const giftType = action.giftType;
      if (!giftType) {
        return initialState;
      }

      return {
        ...state,
        attributes: {
          ...state.attributes,
          [giftType]: {},
        },
        builders: {
          ...state.builders,
          [giftType]: {},
        },
      };
    }
  }

  return state;
}
