import { createSelector } from 'reselect';
import { RootState } from '..';

export const getMiniEmbedState = (state: RootState) => state.miniEmbed;

export const getDark = createSelector([getMiniEmbedState], state => state.dark);
export const getSku = createSelector([getMiniEmbedState], state => state.sku);
export const getAmountButtons = createSelector(
  [getMiniEmbedState],
  state => state.amountButtons
);
export const getGivingItem = createSelector(
  [getMiniEmbedState],
  state => state.givingItem
);
export const getAmount = createSelector(
  [getMiniEmbedState],
  state => state.amount
);
