import * as Types from './types';
import { GiftType } from '../../models/GiftType';
import { ILegacyURLData } from '../../models/LegacyURLData';
import { IGivingItem } from '../../models/GivingItem';

export function setCartURLParams(params: URLSearchParams): Types.ActionTypes {
  return {
    type: Types.SET_CART_URL_PARAMS,
    params,
  };
}

export function setCartLegacyData(
  data: ILegacyURLData,
  givingItems: IGivingItem[]
): Types.ActionTypes {
  return {
    type: Types.SET_CART_LEGACY_DATA,
    data,
    givingItems,
  };
}

export function clearCart(giftType?: GiftType): Types.ActionTypes {
  return {
    type: Types.CLEAR_CART,
    giftType,
  };
}
