import * as Types from './types';

export function setFormData(formData?: any): Types.ActionType {
  return {
    type: Types.SET_FORM_DATA,
    formData,
  };
}

export function resetFormData(): Types.ActionType {
  return {
    type: Types.RESET_FORM_DATA,
  };
}

export function setFormValid(valid: boolean): Types.ActionType {
  return {
    type: Types.SET_FORM_VALID,
    valid,
  };
}

export function setShowMissingFields(
  showMissingFields: boolean
): Types.ActionType {
  return {
    type: Types.SET_FORM_MISSING_FIELDS,
    showMissingFields,
  };
}

export function refreshUniqueID(): Types.ActionType {
  return {
    type: Types.REFRESH_UNIQUE_ID,
  };
}
