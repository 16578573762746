const utexasRoot = process.env.REACT_APP_API_ROOT;

const smartyStreetRootAutocomplete =
  process.env.REACT_APP_SMARTY_STREETS_ROOT_AUTOCOMPLETE;
const smartyStreetRootStreetAddress =
  process.env.REACT_APP_SMARTY_STREETS_ROOT_ADDRESS;

const smartyStreetAPIKey = process.env.REACT_APP_SMARTY_STREETS_KEY;

const API_TYPE_UTEXAS = 'API_TYPE_UTEXAS';
const API_TYPE_SMARTY_STREET_AUTOCOMPLETE =
  'API_TYPE_SMARTY_STREET_AUTOCOMPLETE';
const API_TYPE_SMARTY_STREET_ADDRESS = 'API_TYPE_SMARTY_STREET_ADDRESS';

type ApiType =
  | typeof API_TYPE_UTEXAS
  | typeof API_TYPE_SMARTY_STREET_AUTOCOMPLETE
  | typeof API_TYPE_SMARTY_STREET_ADDRESS;

type QueryParameter = { name: string; value?: string };

const getApiRoot = (type: ApiType) => {
  switch (type) {
    case API_TYPE_UTEXAS:
      return utexasRoot;
    case API_TYPE_SMARTY_STREET_AUTOCOMPLETE:
      return smartyStreetRootAutocomplete;
    case API_TYPE_SMARTY_STREET_ADDRESS:
      return smartyStreetRootStreetAddress;
  }
};

const getAdditionalParams = (type: ApiType): QueryParameter[] => {
  switch (type) {
    case API_TYPE_UTEXAS:
      return [];
    case API_TYPE_SMARTY_STREET_AUTOCOMPLETE:
    case API_TYPE_SMARTY_STREET_ADDRESS:
      return [{ name: 'key', value: `${smartyStreetAPIKey}` }];
  }
};

const makeQuery = (parameters: QueryParameter[]) => {
  if (!parameters.length) {
    return '';
  }

  const queryString = parameters
    .filter(param => param.value !== undefined && param.value !== '')
    .map(param => `${param.name}=${encodeURIComponent(param.value!)}`)
    .join('&');

  return `?${queryString}`;
};

const createExecute = (type: ApiType) => {
  const root = getApiRoot(type);
  const additionalParams = getAdditionalParams(type);

  return <T>(
    path: string,
    queryParams: QueryParameter[] = [],
    options: RequestInit = {}
  ): Promise<T> => {
    const allParams = [...additionalParams, ...queryParams];
    const queryString = makeQuery(allParams);

    return fetch(`${root}/${path}${queryString}`, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json() as Promise<T>;
      })
      .catch(error => {
        throw error;
      });
  };
};

export default {
  execute: createExecute(API_TYPE_UTEXAS),
  executeSmartyStreetAddress: createExecute(API_TYPE_SMARTY_STREET_ADDRESS),
  executeSmartyStreetAutocomplete: createExecute(
    API_TYPE_SMARTY_STREET_AUTOCOMPLETE
  ),
};
