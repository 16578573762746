import React from 'react';
// @ts-ignore
import IntlTelInput from 'react-intl-tel-input';

import {
  InputWrapper,
  ISharedInputProps,
  IHTMLInputProps,
} from './InputWrapper';
import { useTextFieldOpenListener } from '../../wrappers/InputOpenListener';

interface CountryObject {
  dialCode: string;
  iso2: string;
}

type OnValidChanged = (isValid: boolean, value: string) => void;
type OnPhoneNumberChanged = (dialCode: string) => void;
type OnCountryCodeChanged = (dialCode: string) => void;

type IUniqueProps = {
  onCountryCodeChanged: OnCountryCodeChanged;
  onValidChanged: OnValidChanged;
  onPhoneNumberChanged: OnPhoneNumberChanged;
};

export type IProps = IUniqueProps & ISharedInputProps & IHTMLInputProps;

export const PhoneInput = (props: IProps) => {
  const inputEventProps = useTextFieldOpenListener(props);

  const onSelectFlag = (_: string, countryObject: CountryObject) => {
    props.onCountryCodeChanged(countryObject.iso2);
  };

  const phoneNumberChange = (
    valid: boolean,
    phoneValue: string,
    countryObject: CountryObject
  ) => {
    props.onCountryCodeChanged(countryObject.iso2);
    props.onValidChanged(valid, phoneValue);
    props.onPhoneNumberChanged(valid ? phoneValue : '');
  };

  return (
    <InputWrapper
      {...props}
      inputField={inputProps => (
        <IntlTelInput
          fieldName={props.name}
          inputClassName={inputProps.className}
          onPhoneNumberChange={phoneNumberChange}
          onSelectFlag={onSelectFlag}
          onPhoneNumberBlur={inputEventProps.onBlur}
          onPhoneNumberFocus={inputEventProps.onFocus}
          placeholder='Phone'
          type='tel'
          format
        />
      )}
    />
  );
};
