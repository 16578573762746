import * as React from 'react';

import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { Footer } from '../../../components/footer/footer';
import { SearchView } from '../../../containers/search/ogp/SearchView';

import { GIFT_TYPE_SINGLE } from '../../../../models/GiftType';
import { RootState } from '../../../../store';
import * as cartSelectors from '../../../../store/cart/selectors';
import { Routes } from '../../../../router/Routes';
import { bottomButtonClassName } from '../../../containers/giftSummary/mobile/MobileGiftSummary';
import LegacyURLUnwrapper from '../../../wrappers/urlQueryUnwrapper/LegacyURLUnwrapper';

import './SearchPageMobile.scss';

const baseClassName = 'mob-search-page';

const mapStateToProps = (state: RootState) => {
  const getGiftCount =
    cartSelectors.getNumberOfGiftsForGiftTypeCreator(GIFT_TYPE_SINGLE);

  return { giftCount: getGiftCount(state) };
};

const connector = connect(mapStateToProps);
type IProps = ConnectedProps<typeof connector>;

const SearchPageMobileView = (props: IProps) => {
  return (
    <LegacyURLUnwrapper>
      <div className={`${baseClassName}__content`}>
        <SearchView styleMobile />
        <Footer small />
      </div>
    </LegacyURLUnwrapper>
  );
};

const NextButtonView = () => {
  const history = useHistory();

  const onFrequencyClick = () => {
    history.push(Routes.Sections.Frequency.path);
  };

  return (
    <button
      className={`${bottomButtonClassName} ut-btn corner--pill w--100 `}
      onClick={onFrequencyClick}
    >
      Select Frequency
    </button>
  );
};

export const SearchPageMobile = connector(SearchPageMobileView);
export const NextButton = connector(NextButtonView);
