import React, { useState } from 'react';

import './accordion.scss';

export const Accordion = (props: IProps) => {
  const [dropdownState, updateDropdownState] = useState(false);
  const toggleSidebarLink = () => {
    updateDropdownState(!dropdownState);
  };
  const children = props.children.map((child: IChildren) => {
    return (
      <li className='sidebar_links-child' key={child.title}>
        <a className='sidebar_links-child__link' href={child.link}>
          <span
            className={child.external ? 'sidebar_links-external' : ''}
          ></span>
          {child.title}
        </a>
      </li>
    );
  });
  return (
    <li className='sidebar_links-dropdown'>
      <div
        className='sidebar_links-dropdown__title'
        onClick={() => toggleSidebarLink()}
      >
        {props.title}
        <span
          className={
            'sidebar_links-dropdown__icon' +
            (dropdownState ? '' : ' dropdown-closed')
          }
        ></span>
      </div>
      <ul
        className={
          'sidebar_links-children' + (dropdownState ? '' : ' dropdown-closed')
        }
      >
        {children}
      </ul>
    </li>
  );
};

interface IProps {
  title: string;
  children: IChildren[];
}

export interface IChildren {
  link: string;
  external: boolean;
  title: string;
}
