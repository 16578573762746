import React from 'react';
import CurrencyInputView from 'react-currency-input-field';

import { InputWrapper, ISharedInputProps } from './InputWrapper';
import {
  useTextFieldOpenListener,
  ITextFieldEventProps,
} from '../../wrappers/InputOpenListener';

interface IUniqueProps {
  value?: number | string;
  onChange: (value?: string) => void;
  tabIndex?: number;
}

export type IProps = IUniqueProps & ISharedInputProps & ITextFieldEventProps;

export const CurrencyInput = (props: IProps) => {
  const focusEventProps = useTextFieldOpenListener(props);
  const onChange = (value?: string) => {
    const parsedValue = parseFloat(value || '');

    if (isNaN(parsedValue)) {
      props.onChange();
    } else {
      props.onChange(value);
    }
  };

  return (
    <InputWrapper
      {...props}
      styleCurrency
      value={props.value ? `${props.value}` : undefined}
      inputField={inputProps => (
        <CurrencyInputView
          {...inputProps}
          {...focusEventProps}
          tabIndex={props.tabIndex}
          defaultValue={props.value}
          value={props.value}
          ref={undefined}
          onValueChange={value => onChange(value)}
          step={undefined}
        />
      )}
    />
  );
};
