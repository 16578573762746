import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import {
  setDepartment,
  setDepartmentCode,
  setFeaturedArea,
  setQuery,
} from '../../../store/search/actions';
import { DEPARTMENT, DEPARTMENT_CODE, LegacyKeys } from '../../../util/URLKeys';
import { Routes } from '../../../router/Routes';

interface IMatchParams {
  sku: string;
}

export const SearchOptionsUnwrapper = (props: { children: any }) => {
  const search = useLocation().search;

  const dispatch = useDispatch();

  const areaRouteMatch = useRouteMatch<IMatchParams>(
    `/${Routes.SearchArea.path}`
  );
  const areaSku = areaRouteMatch?.params.sku;

  useEffect(() => {
    try {
      const params = new URLSearchParams(search);
      const department = params.get(DEPARTMENT) || undefined;
      const departmentCode = params.get(DEPARTMENT_CODE) || undefined;
      const menu = params.get(LegacyKeys.MENU) || undefined;

      if (department) {
        dispatch(setDepartment(department));
      }

      if (departmentCode) {
        dispatch(setDepartmentCode(departmentCode));
      }

      dispatch(setFeaturedArea(areaSku));

      if (menu) {
        dispatch(setQuery(menu));
      } else if (areaSku) {
        dispatch(setQuery(areaSku || ''));
      }
    } catch (error) {
      // Unused
    }
  }, [areaSku, search, dispatch]);

  return <>{props.children}</>;
};
