import React, { useLayoutEffect, useRef } from 'react';

import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store';

import { ISingleGiftBuilder } from '../../../../models/GiftBuilder';
import * as singleGiftSelectors from '../../../../store/cart/singleGift/selectors';
import { clearCart } from '../../../../store/cart/actions';
import { FormatCurrency } from '../../../../util/NumberFormat';
import { getSingleGiftFrequencyOption } from '../../../../util/dataSets/frequencies';

import { getGiftCountString } from '../../../../util/StringUtils';
import { createSummaryClasses } from '../../../../util/classes/MobileSummaryClassNames';
import { GIFT_TYPE_SINGLE } from '../../../../models/GiftType';
import { getShowMissingFields } from '../../../../store/payment/selectors';
import { setShowMissingFields } from '../../../../store/payment/actions';
import MissingFieldsView from '../../missingFields/MissingFieldsView';

import './MobileGiftSummary.scss';

const baseClassName = 'mob-gift-summary';
const summaryClasses = createSummaryClasses(baseClassName);

export const bottomButtonClassName = summaryClasses.bottomButton;

const mapStateToProps = (state: RootState) => {
  const getItemWithSkuCreator =
    singleGiftSelectors.getGivingItemWithSkuCreator();

  return {
    itemCount: singleGiftSelectors.getItemCount(state),
    builders: singleGiftSelectors.getBuilders(state),
    attributes: singleGiftSelectors.getSharedAttributes(state),
    total: singleGiftSelectors.getTotalAmount(state),
    frequency: singleGiftSelectors.getFrequency(state),
    durations: singleGiftSelectors.getDurations(state),
    duration: singleGiftSelectors.getDuration(state),
    getItemWithSku: getItemWithSkuCreator(state),
    showMissingFields: getShowMissingFields(state),
  };
};

const mapDispatchToProps = { clearCart, setShowMissingFields };

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export type IProps = ReduxProps & {
  form: any;
  nextButton: React.ReactElement;
  showExpandableSummary?: boolean;
  onHeightUpdated?: (height: number) => void;
};

const MobileGiftSummaryComponent = (props: IProps) => {
  const { onHeightUpdated } = props;
  const [isExpanded, setExpanded] = React.useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const containerRect = containerRef.current?.getBoundingClientRect();
  useLayoutEffect(() => {
    const ref = containerRef.current;
    if (ref && onHeightUpdated) {
      onHeightUpdated(ref.getBoundingClientRect().height);
    }
  }, [onHeightUpdated, containerRect]);

  const hiddenClass = props.itemCount ? '' : summaryClasses.rootHidden;

  return (
    <div ref={containerRef} className={`${summaryClasses.root} ${hiddenClass}`}>
      {isExpanded ? (
        <div className={`${summaryClasses.gifts}`}>
          {props.builders.map(RenderGift(props))}
        </div>
      ) : null}
      <div className={`${summaryClasses.bottom}`}>
        {props.showExpandableSummary ? (
          <p className={`${summaryClasses.amount}`}>
            {FormatCurrency(props.total)}
          </p>
        ) : null}
        {ShowHideButton(props, isExpanded, setExpanded)}

        <MissingFieldsView
          form={props.form}
          showMissing={props.showMissingFields}
        />

        <div className={`${summaryClasses.bottomButtonContainer}`}>
          <ClearButton {...props} />
          {props.nextButton}
        </div>
      </div>
    </div>
  );
};

const ShowHideButton = (
  props: IProps,
  isExpanded: boolean,
  setExpanded: (expanded: boolean) => void
) => {
  const showHide = isExpanded ? 'Hide' : 'Show';
  const toggleIsExpandedClass = isExpanded
    ? summaryClasses.expandToggleExpanded
    : '';

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  return props.showExpandableSummary ? (
    <p
      className={`${summaryClasses.expandToggle} ${toggleIsExpandedClass} ${summaryClasses.secondaryText}`}
      onClick={toggleExpanded}
    >{`${showHide} gift summary`}</p>
  ) : (
    <p
      className={`${summaryClasses.giftCount} ${summaryClasses.secondaryText}`}
    >
      {getGiftCountString(props.builders.length)}
    </p>
  );
};

const RenderGift =
  (props: IProps) => (builder: ISingleGiftBuilder, index: number) => {
    const frequency = getSingleGiftFrequencyOption(props.attributes.frequency);
    const durationLabel = props.duration
      ? props.durations?.find(element => element.value === props.duration)
          ?.label
      : undefined;

    return (
      <div className={`${summaryClasses.gift}`} key={`${index}`}>
        <div className={`${summaryClasses.giftAmount}`}>
          {FormatCurrency(builder.amount)}
        </div>
        <div className={`${summaryClasses.giftInfo}`}>
          <div className={`${summaryClasses.giftName}`}>
            {builder.givingItem.givingItemSignature.name}
          </div>
          <div className={`${summaryClasses.giftFrequency}`}>
            {frequency?.label}
          </div>
          {props?.duration ? (
            <div className={`${summaryClasses.giftFrequency}`}>
              {durationLabel}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

const ClearButton = (props: IProps) => (
  <div
    onClick={() => {
      props.clearCart(GIFT_TYPE_SINGLE);
      props.setShowMissingFields(false);
    }}
    className={`${summaryClasses.bottomButton} ${summaryClasses.bottomButton}--small ${summaryClasses.clearButton} ut-btn ut-btn--secondary corner--pill`}
  >
    Clear Cart
  </div>
);

export const MobileGiftSummaryView = connector(MobileGiftSummaryComponent);
