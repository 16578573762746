import { createSelector } from 'reselect';
import { RootState } from '..';
import FieldNames from '../../util/fieldNames/CheckoutFieldNames';
import * as Types from './types';

const getAddressState = (state: RootState) => state.address;
const getLookups = createSelector([getAddressState], state => state.lookups);

const getLookupForAddressKeyCreator = () =>
  createSelector(
    [getLookups],
    lookups =>
      (key: string = Types.ADDRESS_KEY_DEFAULT) => {
        if (key in lookups) {
          return lookups[key];
        }
        return {};
      }
  );

export const getQueryForAddressKeyCreator = () =>
  createSelector(
    [getLookupForAddressKeyCreator()],
    getLookup =>
      (key: string = Types.ADDRESS_KEY_DEFAULT) =>
        getLookup(key).query
  );

export const getQueryIsValidForAddressKeyCreator = () =>
  createSelector(
    [getQueryForAddressKeyCreator()],
    getQuery =>
      (key: string = Types.ADDRESS_KEY_DEFAULT) => {
        const query = getQuery(key);
        return query && query.length > 5;
      }
  );

export const getSuggestionsForAddressKeyCreator = () =>
  createSelector(
    [getLookupForAddressKeyCreator()],
    getLookup =>
      (key: string = Types.ADDRESS_KEY_DEFAULT) =>
        getLookup(key).suggestions
  );

export const getSelectedSuggestionForAddressKeyCreator = () =>
  createSelector(
    [getLookupForAddressKeyCreator()],
    getLookup =>
      (key: string = Types.ADDRESS_KEY_DEFAULT) =>
        getLookup(key).selectedSuggestion
  );

export const getVerifiedForAddressKeyCreator = () =>
  createSelector(
    [getLookupForAddressKeyCreator()],
    getLookup =>
      (key: string = Types.ADDRESS_KEY_DEFAULT) =>
        getLookup(key).verified
  );

export const getPrefilledFormValuesForAddressKeyCreator = () =>
  createSelector(
    [
      getSelectedSuggestionForAddressKeyCreator(),
      getVerifiedForAddressKeyCreator(),
    ],
    (getSelected, getVerified) =>
      (key: string = Types.ADDRESS_KEY_DEFAULT) => {
        const selected = getSelected(key);
        const verified = getVerified(key);

        if (key === 'Street') {
          return {
            [FieldNames.fields.donorPostalCode.name]:
              verified?.components.zipcode || selected?.zipcode,
            [FieldNames.fields.donorCity.name]:
              verified?.components.city_name || selected?.city,
            [FieldNames.fields.donorStateOrProvince.name]:
              verified?.components.state_abbreviation || selected?.state,
          };
        } else if (key === 'Billing') {
          return {
            [FieldNames.fields.billingPostalCode.name]:
              verified?.components.zipcode || selected?.zipcode,
            [FieldNames.fields.billingCity.name]:
              verified?.components.city_name || selected?.city,
            [FieldNames.fields.billingStateOrProvince.name]:
              verified?.components.state_abbreviation || selected?.state,
          };
        } else {
          return {
            [FieldNames.fields.notificationPostalCode.name]:
              verified?.components.zipcode || selected?.zipcode,
            [FieldNames.fields.notificationCity.name]:
              verified?.components.city_name || selected?.city,
            [FieldNames.fields.notificationStateOrProvince.name]:
              verified?.components.state_abbreviation || selected?.state,
          };
        }
      }
  );
