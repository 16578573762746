import React from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { yes } from '../../../../assets/values/yesNo.json';
import { ITransactionResponse } from '../../../models/api/TransactionResponse';
import { RootState } from '../../../store';
import { setPaymentModalOpen } from '../../../store/modal/actions';
import { setShowMissingFields } from '../../../store/payment/actions';
import {
  getPaymentData,
  getSerializedPaymentData,
} from '../../../store/payment/selectors';
import {
  setTransactionProcessing,
  updateTransaction,
} from '../../../store/transaction/actions';
import { alertAPIError } from '../../../util/APIUtils';
import ExternalScripts from '../../../util/ExternalScripts';
import { useEventListener } from '../../effects/EventListener';
import { usePrevious } from '../../effects/Previous';

const mapStateToProps = (state: RootState) => ({
  paymentData: getPaymentData(state),
  serializedData: getSerializedPaymentData(state),
});

const mapDispatchToProps = {
  updateTransaction,
  setTransactionProcessing,
  setPaymentModalOpen,
  setShowMissingFields,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export type IProps = ReduxProps & {
  className?: string;
};

const PaymentButtonView = (props: IProps) => {
  const { paymentData, className = '' } = props;
  const previousPaymentData = usePrevious(paymentData);
  //console.log(paymentData);

  const dispatch = useDispatch();

  const onError = (
    message: string = 'Please refresh the page and try again.'
  ) => {
    // console.error(message);
    props.updateTransaction(undefined);

    dispatch(alertAPIError(message));
  };

  React.useEffect(
    ExternalScripts.loadWrapper((error: string | Event) => {
      onError(`Error loading the payment button.`);
    }),
    []
  );

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const node = document.createElement('ut-payment');
    ref.current?.appendChild(node);
  }, [ref]);

  React.useEffect(() => {
    const paymentNode = ref.current?.firstElementChild;
    if (!paymentNode) return;

    if (previousPaymentData) {
      Object.keys(previousPaymentData).forEach(key => {
        paymentNode.removeAttribute(key);
      });
    }

    Object.keys(paymentData).forEach(key => {
      paymentNode.setAttribute(key, paymentData[key]);
    });
  });

  useEventListener(ref, 'utTransactionResult', (event: any) => {
    const transactionResponse: ITransactionResponse = event.detail;
    if (transactionResponse && transactionResponse.success) {
      props.updateTransaction(transactionResponse);
    } else {
      onError(transactionResponse?.message);
    }
  });

  useEventListener(ref, 'utTransactionError', (event: any) => {
    let message;
    if (event.message) {
      message = event.message;
    }

    onError(message);

    props.setTransactionProcessing(false);
    props.setPaymentModalOpen(false);
  });

  useEventListener(ref, 'modalOpened', () => {
    props.setPaymentModalOpen(true);
  });

  useEventListener(ref, 'modalClosed', () => {
    props.setPaymentModalOpen(false);
  });

  useEventListener(ref, 'modalCompleted', () => {
    props.setTransactionProcessing(true);
  });

  const onIncompleteClick =
    paymentData['form-complete'] === yes.value
      ? undefined
      : () => {
          props.setShowMissingFields(true);
        };

  return <div className={className} ref={ref} onClick={onIncompleteClick} />;
};

export const PaymentButton = connector(PaymentButtonView);
