import React from 'react';
import { useNavHeight } from './NavHeight';

export function useScrollOnSubmission(
  scrollToRef: React.RefObject<HTMLDivElement>,
  shouldScroll: boolean
) {
  const [hasScrolled, setHasScrolled] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const navHeight = useNavHeight();

  React.useEffect(() => {
    if (!hasScrolled) {
      const yTop = scrollToRef.current?.offsetTop;
      if (!hasScrolled && shouldScroll && submitted && yTop) {
        window.scrollTo({ top: yTop - navHeight, behavior: 'smooth' });
        setHasScrolled(true);
      }
    }
  }, [shouldScroll, submitted, scrollToRef, hasScrolled, navHeight]);

  return {
    setSubmitted: (submitted: boolean) => {
      if (!submitted) {
        setHasScrolled(false);
      }
      setSubmitted(submitted);
    },
  };
}
