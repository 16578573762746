import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store';
import { clearCart } from '../../../../store/cart/actions';
import * as cartSelectors from '../../../../store/cart/pledge/selectors';
import { getTextFieldOpen } from '../../../../store/layout/selectors';
import { getShowMissingFields } from '../../../../store/payment/selectors';
import { clearPledge } from '../../../../store/pledge/actions';
import * as existingPledgeSelectors from '../../../../store/pledge/existingPledge/selectors';
import * as pledgeSelectors from '../../../../store/pledge/selectors';
import {
  PLEDGE_TYPE_ESTABLISH,
  PLEDGE_TYPE_PAYMENT,
} from '../../../../store/pledge/types';
import { createSummaryClasses } from '../../../../util/classes/MobileSummaryClassNames';
import { FormatCurrency } from '../../../../util/NumberFormat';
import MissingFieldsView from '../../missingFields/MissingFieldsView';
import { MobilePledgeSummaryEstablish } from './content/MobilePledgeSummaryEstablish';
import { MobilePledgeSummaryPayment } from './content/MobilePledgeSummaryPayment';
import './MobilePledgeSummary.scss';

export const baseClassName = `mob-pledge-summary`;
const summaryClasses = createSummaryClasses(baseClassName);

const mapStateToProps = (state: RootState) => {
  const visibleCreator = pledgeSelectors.getSummaryVisibleCreator();
  const pledgeType = pledgeSelectors.getPledgeType(state);

  let total;
  switch (pledgeType) {
    case PLEDGE_TYPE_ESTABLISH:
      total = cartSelectors.getRecurringAmount(state);
      break;
    case PLEDGE_TYPE_PAYMENT:
      total = existingPledgeSelectors.getDueTodayTotalAmount(state);
      break;
  }

  return {
    total,
    pledgeType,
    showMissingFields: getShowMissingFields(state),
    textFieldOpen: getTextFieldOpen(state),
    isVisible: visibleCreator(state),
  };
};

const mapDispatchToProps = { clearCart, clearPledge };

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export type IProps = ReduxProps & {
  form: any;
  nextButton: React.ReactElement;
  isCheckoutPage: boolean;
  onHeightUpdated?: (height: number) => void;
};

const MobilePledgeSummaryView = (props: IProps) => {
  const { onHeightUpdated } = props;

  const [isExpanded, setExpanded] = React.useState(false);

  const isHidden =
    !props.isVisible(props.isCheckoutPage) || props.textFieldOpen;
  const hiddenClass = isHidden ? summaryClasses.rootHidden : '';

  const containerRef = React.useRef<HTMLDivElement>(null);
  const containerRect = containerRef.current?.getBoundingClientRect();
  React.useLayoutEffect(() => {
    const ref = containerRef.current;
    if (ref && onHeightUpdated) {
      onHeightUpdated(ref.getBoundingClientRect().height);
    }
  }, [onHeightUpdated, containerRect]);

  return (
    <div ref={containerRef} className={`${summaryClasses.root} ${hiddenClass}`}>
      {isExpanded ? <ExpandedSummaryContent {...props} /> : null}
      <div className={`${summaryClasses.bottom}`}>
        <p className={`${summaryClasses.amount}`}>
          {FormatCurrency(props.total)}
        </p>
      </div>
      <div>{showHideButton(isExpanded, setExpanded)}</div>
      <MissingFieldsView
        form={props.form}
        showMissing={props.showMissingFields}
      />

      <div className={`${summaryClasses.bottomButtonContainer}`}>
        <ClearButton {...props} />
        {props.nextButton}
      </div>
    </div>
  );
};

const showHideButton = (
  isExpanded: boolean,
  setExpanded: (expanded: boolean) => void
) => {
  const showHide = isExpanded ? 'Hide' : 'Show';
  const toggleIsExpandedClass = isExpanded
    ? summaryClasses.expandToggleExpanded
    : '';

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  return (
    <p
      className={`${summaryClasses.expandToggle} ${toggleIsExpandedClass} ${summaryClasses.secondaryText}`}
      onClick={toggleExpanded}
    >{`${showHide} gift summary`}</p>
  );
};

const ExpandedSummaryContent = (props: IProps) => {
  switch (props.pledgeType) {
    case PLEDGE_TYPE_ESTABLISH:
      return <MobilePledgeSummaryEstablish />;
    case PLEDGE_TYPE_PAYMENT:
      return <MobilePledgeSummaryPayment />;
  }
  return null;
};

const ClearButton = (props: IProps) => (
  <div
    onClick={() => {
      props.clearCart();
      props.clearPledge();
    }}
    className={`${summaryClasses.bottomButton} ${summaryClasses.bottomButton}--small ${summaryClasses.clearButton} ut-btn ut-btn--secondary corner--pill`}
  >
    Clear Cart
  </div>
);

export const MobilePledgeSummary = connector(MobilePledgeSummaryView);
