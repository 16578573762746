import { FacetKeys, IFacet } from '../../models/Facet';

// Results to display before "show more" is clicked
export const MINIMIZED_ITEM_COUNT = 5;

export type IDisplayFacet = {
  value: string;
  label: string;
};

export const QUERY_FACETS_GIFTS: IDisplayFacet[] = [
  { value: FacetKeys.prioritized, label: 'Featured' },
  // { value: FacetKeys.scholarships, label: "Scholarships" },
  // { value: FacetKeys.programs, label: "Programs" },
  // { value: FacetKeys.faculty, label: "Faculty" },
  // { value: FacetKeys.health, label: "Health" },
];
export const QUERY_FACETS_PLEDGES: IDisplayFacet[] = [
  { value: FacetKeys.pledge, label: 'Pledges' },
];

export interface IStore {
  // Search parameters
  query: string;
  searchQuery: string;
  queryFacets?: IDisplayFacet[];
  department?: string;
  departmentCode?: string;
  featuredArea?: string;

  // Search results & state management
  facets?: IFacet[];
  givingItemError?: string;
  showMore?: boolean;
  loading?: boolean;
}

export const SET_LOADING = 'SET_LOADING';
export const SET_FEATURED_AREA = 'SET_FEATURED_AREA';
export const SET_QUERY = 'SET_QUERY';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_DEPARTMENT = 'SET_DEPARTMENT';
export const SET_DEPARTMENT_CODE = 'SET_DEPARTMENT_CODE';
export const SET_QUERY_FACETS = 'SET_FACETS';
export const SET_SHOW_MORE = 'SET_SHOW_MORE';
export const FETCH_GIVING_ITEMS = 'FETCH_GIVING_ITEMS';
export const FETCH_GIVING_ITEMS_SUCCESS = 'FETCH_GIVING_ITEMS_SUCCESS';
export const FETCH_GIVING_ITEMS_FAILURE = 'FETCH_GIVING_ITEMS_FAILURE';

interface ISetQuery {
  type: typeof SET_QUERY;
  value: string;
}

interface ISetSearchQuery {
  type: typeof SET_SEARCH_QUERY;
  searchValue: string;
}

interface ISetDepartment {
  type: typeof SET_DEPARTMENT;
  department?: string;
}

interface ISetDepartmentCode {
  type: typeof SET_DEPARTMENT_CODE;
  departmentCode?: string;
}

interface ISetFeaturedArea {
  type: typeof SET_FEATURED_AREA;
  area?: string;
}

interface ISetShowMore {
  type: typeof SET_SHOW_MORE;
  showMore: boolean;
}

interface IFetchGivingItems {
  type: typeof FETCH_GIVING_ITEMS;
  query?: string;
}
interface IFetchGivingItemsSuccess {
  type: typeof FETCH_GIVING_ITEMS_SUCCESS;
  facets: IFacet[];
}
interface IFetchGivingItemsFailure {
  type: typeof FETCH_GIVING_ITEMS_FAILURE;
  error?: string;
}

interface ISetQueryFacets {
  type: typeof SET_QUERY_FACETS;
  facets: IDisplayFacet[];
}

interface ISetLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

export type ActionTypes =
  | ISetQuery
  | ISetSearchQuery
  | IFetchGivingItems
  | IFetchGivingItemsSuccess
  | ISetShowMore
  | IFetchGivingItemsFailure
  | ISetQueryFacets
  | ISetDepartment
  | ISetDepartmentCode
  | ISetLoading
  | ISetFeaturedArea;
