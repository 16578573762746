import * as Types from './types';

const initialState: Types.IStore = { paymentModalOpen: false };

export function modalReducer(state = initialState, action: Types.ActionTypes) {
  switch (action.type) {
    case Types.SET_MODAL:
      return { ...state, modal: action.modal };
    case Types.SET_PAYMENT_MODAL_OPEN:
      return { ...state, paymentModalOpen: action.open };
  }
  return state;
}
