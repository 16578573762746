import './PledgePaymentWithID.scss';

import React from 'react';

import { no, yes } from '../../../../../../assets/values/yesNo.json';
import {
  IExistingPledgePaymentType,
  TYPE_CUSTOM,
  TYPE_OUTSTANDING,
  TYPE_REMAINING,
  TYPE_PER_PAYMENT,
} from '../../../../../util/dataSets/existingPledgePaymentAmounts';
import {
  formatDate,
  getEarliestDuePledge,
  pledgeIsOverdue,
} from '../../../../../util/DateUtils';
import { pledgeIDValid } from '../../../../../util/PledgeUtils';
import { Radio } from '../../../../components/radio/Radio';
import { UnwrapEventWithCallback } from '../../../../components/textInput/InputWrapper';
import { TextInput } from '../../../../components/textInput/TextInput';
import { useScrollOnSubmission } from '../../../../effects/layout/ScrollOnSubmission';
import { IProps } from '../../PledgeView';
import { PledgePaymentAmountButton } from './amountButton/PledgePaymentAmountButton';
import { IPledgePayments } from '../../../../../models/PledgePayments';
// import { ExistingPledgeDetailView } from './detail/ExistingPledgeDetailView';

const baseClassName = 'pledge-payment-id';

export const AmountButtons = (
  props: IProps & { buttonContainerRef: React.RefObject<HTMLDivElement> }
) => {
  const [selectedAmountSetting, setSelectedAmountSetting] = React.useState<
    IExistingPledgePaymentType | undefined
  >(props.existingPledgePaymentType);

  if (!props.existingPledges.length) {
    return null;
  }

  const createOnClick = (amountSetting: IExistingPledgePaymentType) => () => {
    props.setExistingPledgePaymentType(amountSetting);
    setSelectedAmountSetting(amountSetting);
  };

  const settingIsSelected = (setting: string) =>
    setting === selectedAmountSetting;

  const earliestPledge = getEarliestDuePledge(props.existingPledges);
  const isOverdue = earliestPledge && pledgeIsOverdue(earliestPledge);
  const oustandingAmountLabel = `${
    isOverdue ? 'Past due on' : 'Due by'
  } ${formatDate(earliestPledge?.dateDue)}`;

  const buttonClass = `${baseClassName}__amount-button`;
  return (
    <div
      className={`${baseClassName}__amount-buttons pb--40`}
      ref={props.buttonContainerRef}
    >
      <PledgePaymentAmountButton
        value={props.existingPledgeOutstandingAmount}
        overdue={isOverdue}
        label={oustandingAmountLabel}
        onClick={createOnClick(TYPE_OUTSTANDING)}
        selected={settingIsSelected(TYPE_OUTSTANDING)}
        className={buttonClass}
      />
      <PledgePaymentAmountButton
        value={props.existingPledgeRemainingAmount}
        label={`Pledge Balance`}
        selected={settingIsSelected(TYPE_REMAINING)}
        onClick={createOnClick(TYPE_REMAINING)}
        className={buttonClass}
      />
      <PledgePaymentAmountButton
        value={props.existingPledgeCustomAmountTotal}
        label={`Specify Amount`}
        selected={settingIsSelected(TYPE_CUSTOM)}
        onClick={createOnClick(TYPE_CUSTOM)}
        className={buttonClass}
      />
    </div>
  );
};

const PaymentCartButton = (props: IProps & { value: IPledgePayments }) => {
  const selected = props.selectedPayments.find(
    item =>
      item.pledgeId === props.value.pledgeReceiptLink.toString() &&
      item.startDate === new Date(props.value.paymentDueDateCymd).toISOString()
  );
  const addOnClick = (amountSetting: IExistingPledgePaymentType) => () => {
    const selectedGifts = props.selectedPayments;
    if (selectedGifts.length === 9) return;
    props.setExistingPledgePaymentType(amountSetting);
    props.togglePaymentAmount(props.value);
  };

  return (
    <button
      className={
        selected
          ? 'ut-btn btn--small corner--pill selected'
          : 'ut-btn btn--small corner--pill'
      }
      value={props.value?.paymentAmountDue}
      onClick={addOnClick(TYPE_PER_PAYMENT)}
    >
      {!selected ? 'Add to Cart' : ' Remove '}
    </button>
  );
};

const PaymentsCards = (
  props: IProps & { buttonContainerRef: React.RefObject<HTMLDivElement> }
) => {
  if (!props.existingPledges.length) {
    return null;
  }

  return (
    <div
      className={`${baseClassName}__payment-cards pb--40`}
      ref={props.buttonContainerRef}
    >
      {props.existingPledges.map(item => (
        <div className='card'>
          <div className='card-container'>
            <div className='card-header'>
              <div className='title'>{item.pledgeTitle}</div>
              <div className='subtitle'>ID-{item.id}</div>
            </div>
            <div className='line-item-wrapper'>
              {item.payments.length ? (
                item.payments.map(payment => (
                  <div className='line-item'>
                    <span className='due-date'>
                      {new Date(payment.paymentDueDate).toDateString()}
                    </span>
                    <span className='amount'>${payment.paymentAmountDue}</span>
                    <PaymentCartButton
                      key={
                        payment.pledgeReceiptLink.toString() +
                        '_' +
                        payment.paymentDueDate.toString()
                      }
                      {...props}
                      value={payment}
                    />
                  </div>
                ))
              ) : (
                <div className='nothing-due'>
                  {'No payments are currently due on this Pledge.'}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const AutomaticPaymentsSection = (props: IProps) => {
  if (!props.existingPledgeAutopayPossible) {
    return <div className={`${baseClassName}__autopay-empty`}></div>;
  }

  const onAutopayChanged = (value: string) => {
    props.setExistingPledgeEnableAutopay(value === yes.value);
  };

  const options = [
    { label: 'No thanks', value: no.value },
    {
      label: `Yes, charge my card automatically on ${formatDate(
        getEarliestDuePledge(props.existingPledges)?.dateDue
      )}`,
      value: yes.value,
    },
  ];

  let autopayRadioValue;
  switch (props.existingPledgeAutopayEnabled) {
    case true:
      autopayRadioValue = yes.value;
      break;
    case false:
      autopayRadioValue = no.value;
      break;
    default:
      break;
  }

  return (
    <React.Fragment>
      <div className={`${baseClassName}__autopay-section`}>
        <h3 className='ut-h3'>Set Up Automatic Payments</h3>
        <div className={`${baseClassName}__recommended-badge`}>Recommended</div>
      </div>
      <p className={`pb--30`}>
        Never miss a pledge payment by setting up automatic payments. You can
        opt out any time.
      </p>
      <Radio
        className='pb--50'
        options={options}
        value={autopayRadioValue}
        onChange={onAutopayChanged}
      />
    </React.Fragment>
  );
};

export const ExistingPledgeContent = (
  props: IProps & { scrollToRef: React.RefObject<HTMLDivElement> }
) => {
  const buttonContainerRef = React.createRef<HTMLDivElement>();
  const paymentValid = props.existingPledgePaymentValid;
  const maxPayments = props.selectedPayments.length >= 9;

  if (!props.existingPledges.length) {
    if (props.existingPledgeID) {
      return (
        <div className='not-found-error'>
          <b>
            Payments to Pledge {props.existingPledgeID} are not available yet.
          </b>
          <div>
            Please contact{' '}
            <a href='mailto:pledges@austin.utexas.edu'>
              pledges@austin.utexase.edu
            </a>
          </div>
        </div>
      );
    }
    return <div></div>;
  }

  return (
    <React.Fragment>
      <h3 className='ut-h3 pb--30' ref={props.scrollToRef}>
        Make a Payment
      </h3>
      {maxPayments ? (
        <div className='spinner-container bottom-spacing'>
          A maximum of 9 payments can be added at once to your cart, please
          revisit this page after checkout in order to make more payments.
        </div>
      ) : null}
      {/* <AmountButtons {...props} buttonContainerRef={buttonContainerRef} /> */}
      <PaymentsCards {...props} buttonContainerRef={buttonContainerRef} />
      {/* <PledgeDetails {...props} /> */}
      {
        paymentValid && false ? <AutomaticPaymentsSection {...props} /> : null //TODO: Turn back on automatic payments when necessary
      }
    </React.Fragment>
  );
};

// const PledgeDetails = (props: IProps) => {
//   if (!props.existingPledges.length) {
//     console.log('No Pledges');
//     return null;
//   }

//   const firstPledge = props.existingPledges[0];
//   const title = `Funds for ID-${firstPledge?.id}`;

//   return (
//     <React.Fragment>
//       {props.existingPledges.map((pledge, index) => {
//         const customAmount = props.customAmountForExistingPledgeID(pledge.id);
//         const hasError = !props.customAmountValidForExistingPledgeID(pledge.id);
//         return (
//           <React.Fragment key={pledge.id}>
//             <ExistingPledgeDetailView
//               tabIndex={index + 1}
//               pledge={pledge}
//               title={title}
//               showHeaderOnDesktop={index === 0}
//               pledgePaymentType={props.existingPledgePaymentType}
//               onCustomAmountUpdated={props.setExistingPledgeCustomPaymentAmount}
//               customAmount={customAmount}
//               hasError={hasError}
//             />
//           </React.Fragment>
//         );
//       })}
//     </React.Fragment>
//   );
// };

export const PledgePaymentWithIDView = (props: IProps) => {
  // Clear any previously loaded/entered data
  const { clearPledge } = props;
  React.useEffect(() => {
    clearPledge();
  }, [clearPledge]);
  const scrollToRef = React.createRef<HTMLDivElement>();
  const shouldScrollToAmount = props.scrollToPaymentAmountButtons || false;
  const { setSubmitted } = useScrollOnSubmission(
    scrollToRef,
    shouldScrollToAmount && props.existingPledges.length > 0
  );

  const [pledgeIDInputValue, setPledgeIDInputValue] = React.useState<
    string | undefined
  >(props.existingPledgeID);
  const [hasError, setHasError] = React.useState(false);

  const idFieldName = 'pledge';

  const onSubmitClicked = () => {
    const valid = pledgeIDValid(pledgeIDInputValue);
    setHasError(!valid);

    if (valid) {
      props.clearPledge();
      props.setExistingPledgeID(pledgeIDInputValue);
      setSubmitted(true);
    }
  };

  const onPledgeIDChange = (value?: string) => {
    props.clearPledge();
    setHasError(false);
    setPledgeIDInputValue(value);
    setSubmitted(false);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      return onSubmitClicked();
    }
  };

  const detailsButtonActiveClass = props.existingPledges.length ? 'active' : '';
  const valid = !hasError && props.existingPledges.length > 0;
  const errorMessage = hasError ? 'Please Enter a valid Pledge ID.' : undefined;
  const pid = props.existingPledgeID;

  return (
    <React.Fragment>
      <div className={`${baseClassName}__container`}>
        <TextInput
          className={`${baseClassName}__id-input`}
          label='Pledge ID'
          placeholder='Pledge ID'
          name={idFieldName}
          value={pid}
          onChange={UnwrapEventWithCallback(onPledgeIDChange)}
          error={errorMessage}
          valid={valid}
          required
          keydown={handleKeyDown}
        />
        <button
          className={`ut-btn btn--small corner--pill ${baseClassName}__id-button`}
          onClick={onSubmitClicked}
        >
          View Details
        </button>
        <button
          className={`ut-btn--large corner--pill ${baseClassName}__id-button--large ${detailsButtonActiveClass}`}
          onClick={onSubmitClicked}
        >
          View Pledge Details
        </button>
      </div>
      {props.existingPledgeIsLoading ? (
        <div className='spinner-container'>
          <div className='loading-spinner'></div> Fetching Pledge...
        </div>
      ) : (
        <ExistingPledgeContent {...props} scrollToRef={scrollToRef} />
      )}
    </React.Fragment>
  );
};
