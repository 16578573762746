import * as React from 'react';

import { Route, Switch } from 'react-router-dom';

import { PledgeHomePage } from '../home/pledge/desktop/PledgeHomePage';
import { CheckoutPage } from '../checkout/desktop/CheckoutPage';

import { Routes } from '../../../router/Routes';
import { WindowTo404Redirect } from '../../router/redirects/WindowTo404Redirect';

const basePath = Routes.Roots.Pledge.path;

export const PledgePageDesktop = (props: {}) => {
  return (
    <React.Fragment>
      <Switch>
        <Route path={basePath + Routes.Sections.Checkout.path}>
          <CheckoutPage />
        </Route>
        <Route
          exact
          path={[
            basePath + Routes.Sections.Frequency.path,
            basePath + Routes.Sections.Amount.path,
            basePath + Routes.Sections.Search.path,
          ]}
        >
          <PledgeHomePage />
        </Route>
        <Route path='*'>
          <WindowTo404Redirect />
        </Route>
      </Switch>
    </React.Fragment>
  );
};
