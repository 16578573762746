import { createSelector } from 'reselect';

import { RootState } from '../..';
import { IGift } from '../../../models/Gift';
import { IPledge } from '../../../models/Pledge';
import { isEmpty, notEmpty } from '../../../util/ArrayUtils';
import {
  TYPE_CUSTOM,
  TYPE_OUTSTANDING,
  TYPE_REMAINING,
  TYPE_PER_PAYMENT,
} from '../../../util/dataSets/existingPledgePaymentAmounts';

const getPledgeState = (state: RootState) => state.pledge;

const getResponsePledge = createSelector(
  [getPledgeState],
  state => state.existingPledgeResponse?.pledge
);

const getResponsePledgeRelated = createSelector(
  [getPledgeState],
  state => state.existingPledgeResponse?.relatedPledges
);

const getCustomPaymentIDsToAmounts = createSelector(
  [getPledgeState],
  state => state.existingPledgeCustomPaymentAmounts
);

const getPerPaymentIDsToAmounts = createSelector(
  [getPledgeState],
  state => state.existingPledgePerPaymentAmounts
);

export const getPledgeID = createSelector(
  [getPledgeState],
  state => state.existingPledgeID
);

export const getIDKnown = createSelector(
  [getPledgeState],
  state => state.existingPledgeIDKnown
);

export const getNoIDPaymentAmount = createSelector(
  [getPledgeState],
  state => state.existingPledgeNoIDPaymentAmount
);

export const getPledges = createSelector(
  [getResponsePledge, getResponsePledgeRelated],
  (existing, related = []) => {
    if (isEmpty(existing)) return [];
    return [...related, existing].filter(notEmpty);
  }
);

export const getPaymentType = createSelector(
  [getPledgeState],
  state => state.existingPledgePaymentType
);

export const getOutstandingAmount = createSelector([getPledges], pledges =>
  pledges.reduce((sum, pledge) => sum + pledge.outstandingAmount, 0)
);

export const getRemainingAmount = createSelector([getPledges], pledges =>
  pledges.reduce((sum, pledge) => sum + pledge.remainingBalance, 0)
);

export const getAutopayEnabled = createSelector(
  [getPledgeState],
  state => state.existingPledgeEnableAutopay
);

export const getAutopayPossible = createSelector(
  [getIDKnown, getPaymentType],
  (idKnown, paymentType) => idKnown === true && paymentType === TYPE_OUTSTANDING
);

const getAutopayValid = createSelector(
  [getAutopayPossible, getAutopayEnabled],
  (possible, enabled) => !possible || enabled !== undefined
);

export const getComments = createSelector(
  [getPledgeState],
  state => state.existingPledgeComments
);

export const getIsLoading = createSelector(
  [getPledgeState],
  state => state.existingPledgeIsLoading
);

export const getCustomAmountForPledgeIDCreator = () =>
  createSelector(
    [getCustomPaymentIDsToAmounts],
    idsToAmounts => (id: string) => {
      // if (!(idsToAmounts && Object.keys(idsToAmounts).includes(id))) {
      //   return;
      // }

      // const amount = idsToAmounts[id];
      // if (amount === undefined || isNaN(amount) || amount === null) {
      //   return;
      // }
      return idsToAmounts[id];
    }
  );

export const getPerPaymentAmountForPledgeIdCreator = () =>
  createSelector([getPerPaymentIDsToAmounts], idsToAmounts => (id: string) => {
    const destructured = Object.keys(idsToAmounts).map(item =>
      item.substring(0, item.indexOf('_'))
    );
    if (!(idsToAmounts && destructured.includes(id.toString()))) {
      return;
    }
    return Object.keys(idsToAmounts)
      .map(item => {
        if (item.indexOf(id) !== -1) {
          return idsToAmounts[item];
        }
        return 0;
      })
      .reduce((a, b) => (a || 0) + (b || 0), 0);
  });

export const getCustomAmountIsValidForPledgeIDCreator = () =>
  createSelector(
    [getCustomPaymentIDsToAmounts],
    idsToAmounts => (id: string) => {
      const amount = idsToAmounts[id];
      if (
        amount === undefined ||
        isNaN(amount) ||
        amount === null ||
        amount < 0
      ) {
        return false;
      }
      return true;
    }
  );

export const getCustomAmountTotal = createSelector(
  [getPledges, getCustomAmountForPledgeIDCreator()],
  (pledges, getCustomAmount) =>
    pledges.reduce((sum, pledge) => sum + (getCustomAmount(pledge.id) || 0), 0)
);

const getCustomAmountsValid = createSelector(
  [
    getCustomAmountTotal,
    getCustomAmountIsValidForPledgeIDCreator(),
    getPledges,
  ],
  (total, isValid, pledges) => {
    return total > 0 && pledges.every(pledge => isValid(pledge.id));
  }
);

export const paymentValidForKnownID = createSelector(
  [getPaymentType, getCustomAmountsValid],
  (paymentType, customAmountValid) => {
    switch (paymentType) {
      case TYPE_OUTSTANDING:
      case TYPE_REMAINING:
        return true;
      case TYPE_CUSTOM:
        return customAmountValid;
      case TYPE_PER_PAYMENT:
        return true;
      default:
        return false;
    }
  }
);

const getGiftValidNoID = createSelector(
  [getNoIDPaymentAmount],
  amount => (amount && amount > 0 && !isNaN(amount) ? true : false) // Force cast to boolean
);

const getGiftValidKnownID = createSelector(
  [paymentValidForKnownID, getAutopayValid],
  (paymentValid, autopayValid) => {
    return paymentValid && autopayValid;
  }
);

export const getDueTodayAmountForPledgeCreator = () =>
  createSelector(
    [
      getPaymentType,
      getCustomAmountForPledgeIDCreator(),
      getPerPaymentAmountForPledgeIdCreator(),
    ],
    (paymentType, getCustomAmount, getPerPaymentAmount) => (pledge: IPledge) => {
      switch (paymentType) {
        case TYPE_OUTSTANDING:
          return pledge.outstandingAmount;
        case TYPE_REMAINING:
          return pledge.remainingBalance;
        case TYPE_CUSTOM:
          return getCustomAmount(pledge.id);
        case TYPE_PER_PAYMENT:
          return getPerPaymentAmount(pledge.id);
      }
    }
  );

export const getDueTodayTotalAmount = createSelector(
  [
    getIDKnown,
    getPledges,
    getNoIDPaymentAmount,
    getDueTodayAmountForPledgeCreator(),
  ],
  (idKnown, pledges, amount = 0, getAmount) => {
    if (idKnown) {
      return pledges.reduce((sum, pledge) => sum + (getAmount(pledge) || 0), 0);
    } else {
      return amount;
    }
  }
);

export const getGiftsForPayment = createSelector(
  [
    getIDKnown,
    getResponsePledge,
    getDueTodayAmountForPledgeCreator(),
    getResponsePledgeRelated,
    getNoIDPaymentAmount,
  ],
  (
    idKnown,
    existingPledge,
    getAmountForExistingPledge,
    relatedPledges = [],
    amountForNoID = 0
  ) => {
    if (idKnown) {
      const pledges = [...relatedPledges, existingPledge].filter(notEmpty);
      return pledges.map(
        pledge =>
          ({
            giftType: 'pledge',
            amount: getAmountForExistingPledge(pledge),
            givingItemSku: pledge.givingItemSignature?.sku,
            givingItemName: pledge.givingItemSignature?.name,
            pledgeId: `${pledge.id}`,
          } as IGift)
      );
    } else if (amountForNoID) {
      return [{ giftType: 'pledge', amount: amountForNoID } as IGift];
    }
    return [];
  }
);

export const getGiftsForPerPayment = createSelector(
  [
    getIDKnown,
    getResponsePledge,
    getResponsePledgeRelated,
    getNoIDPaymentAmount,
    getPerPaymentIDsToAmounts,
  ],
  (
    idKnown,
    existingPledge,
    relatedPledges = [],
    amountForNoID = 0,
    idsToAmounts
  ) => {
    if (idKnown) {
      const pledges = [...relatedPledges, existingPledge].filter(notEmpty);
      const payments = pledges.flatMap(pledge => pledge.payments);
      const ids: string[] = Object.keys(idsToAmounts).map(item => item);
      return payments
        .filter(pmt =>
          ids.includes(
            pmt?.pledgeReceiptLink.toString() +
              '_' +
              pmt?.paymentDueDate.toString()
          )
        )
        .map(
          pmt =>
            ({
              giftType: 'pledge pay',
              amount:
                idsToAmounts[`${pmt.pledgeReceiptLink}_${pmt.paymentDueDate}`],
              pledgeId: `${pmt.pledgeReceiptLink}`,
              startDate: `${new Date(pmt.paymentDueDateCymd).toISOString()}`,
              givingItemSku: '',
              givingItemName: '',
            } as IGift)
        );
    } else if (amountForNoID) {
      return [{ giftType: 'pledge pay', amount: amountForNoID } as IGift];
    }
    return [];
  }
);

export const getSummaryVisibleCreator = () =>
  createSelector(
    [getIDKnown, getPaymentType, getPledges],
    (idKnown, paymentType, pledges) => (checkout: boolean) => {
      if (!idKnown) {
        return checkout;
      }

      return pledges.length && paymentType !== undefined;
    }
  );

export const getGiftValid = createSelector(
  [
    getIDKnown,
    getGiftValidKnownID,
    getGiftValidNoID,
    getPaymentType,
    getGiftsForPerPayment,
  ],
  (idKnown, validWithID, validNoID, paymentType, gifts) => {
    if (paymentType === TYPE_PER_PAYMENT) {
      return gifts.length && validWithID;
    }
    return idKnown ? validWithID : validNoID;
  }
);
