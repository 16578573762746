import { createSelector } from 'reselect';
import { RootState } from '..';

const getModalState = (state: RootState) => state.modal;

export const getModal = createSelector([getModalState], state => state.modal);

export const getPaymentModalOpen = createSelector(
  [getModalState],
  state => state.paymentModalOpen
);
