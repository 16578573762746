import React from 'react';

import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setReferrer } from '../../../store/referrer/actions';
import { REFERRER } from '../../../util/URLKeys';

const mapDispatchToProps = { setReferrer };

const connector = connect(() => ({}), mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type IProps = ReduxProps & {
  children: React.ReactNode;
};

const ReferrerQueryUnwrapperView = (props: IProps) => {
  const { setReferrer } = props;
  const searchString = useLocation().search;

  React.useEffect(() => {
    const urlParams = new URLSearchParams(searchString);

    const referrer = unwrapReferrerFromURLParams(urlParams);
    if (referrer) {
      setReferrer(referrer);
    }
  }, [searchString, setReferrer]);

  return <>{props.children}</>;
};

const unwrapReferrerFromURLParams = (params: URLSearchParams) => {
  try {
    return params.get(REFERRER) || undefined;
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
  }
};

export default connector(ReferrerQueryUnwrapperView);
