import useComponentSize from '@rehooks/component-size';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNavHeight } from '../../../store/layout/actions';
import { getNavHeight } from '../../../store/layout/selectors';

export function useNavHeight(): number {
  return useSelector(getNavHeight);
}
export function useNavHeightSetter<T = any>(ref: React.RefObject<T>): void {
  const dispatch = useDispatch();

  const currentHeight = useSelector(getNavHeight);
  const { height } = useComponentSize(ref);

  useEffect(() => {
    if (height !== currentHeight) {
      dispatch(setNavHeight(height));
    }
  }, [height, currentHeight, dispatch]);
}
