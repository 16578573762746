import * as Types from './types';
import { ILegacyURLData } from '../../models/LegacyURLData';
import { IGivingItem } from '../../models/GivingItem';

export function setURLData(urlData?: ILegacyURLData): Types.ActionTypes {
  return {
    type: Types.SET_URL_DATA,
    urlData,
  };
}

export function setGivingItems(givingItems?: IGivingItem[]) {
  return {
    type: Types.SET_ITEMS,
    givingItems,
  };
}
