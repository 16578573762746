import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { PledgeView } from '../../../../containers/pledge/PledgeView';
import { PledgeSummary } from '../../../../containers/pledgeSummary/desktop/PledgeSummary';
import { SwitchGiftTypeLink } from '../../../../components/switchGiftTypeLink/SwitchGiftTypeLink';
import { setGiftTypePledge } from '../../../../../store/app/actions';
import { useFooterWidthSetter } from '../../../../effects/layout/FooterWidth';

import { GIFT_TYPE_SINGLE } from '../../../../../models/GiftType';

import './PledgeHomePage.scss';
import LegacyURLUnwrapper from '../../../../wrappers/urlQueryUnwrapper/LegacyURLUnwrapper';

const mapStateToProps = () => ({});
const mapDispatchToProps = { setGiftTypeSingle: setGiftTypePledge };

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export const PledgeHomePage = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  useFooterWidthSetter(containerRef);

  return (
    <LegacyURLUnwrapper>
      <div className='columns is-marginless'>
        <div className='column home-page__container--pledge' ref={containerRef}>
          <div className='pledge-home__content'>
            <SwitchGiftTypeLink
              giftType={GIFT_TYPE_SINGLE}
              className='pledge-home__ogp'
            />
            <PledgeView />
          </div>
        </div>
        <div className='home-page__container--summary'>
          <PledgeSummary />
        </div>
      </div>
    </LegacyURLUnwrapper>
  );
};
