import React from 'react';

import { ConfirmationToSearchRedirect } from '../../router/redirects/ConfirmationToSearchRedirect';
import { ConfirmationView } from '../../containers/confirmation/ConfirmationView';

import { useFooterWidthSetter } from '../../effects/layout/FooterWidth';
import confirmationBevo from '../../../../assets/images/confirmation-bevo.gif';

import './ConfirmationPage.scss';

export const ConfirmationPage = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  useFooterWidthSetter(containerRef);

  return (
    <div className='columns is-marginless confirmation-page'>
      <ConfirmationToSearchRedirect />
      <div
        className='columns is-marginless column is-7 is-centered'
        ref={containerRef}
      >
        <div className='column is-narrow confirmation-page__confirmation-container'>
          <ConfirmationView />
        </div>
      </div>
      <div className='column is-marginless is-5 is-centered'>
        <div className='confirmation-page__gif-container'>
          <img
            className='confirmation-page__gif'
            src={confirmationBevo}
            alt=''
          ></img>
        </div>
      </div>
    </div>
  );
};
