import React from 'react';
import { useDispatch } from 'react-redux';
import { setTextFieldOpen } from '../../store/layout/actions';

type FocusEvent = React.FocusEvent<any>;

export interface ITextFieldEventProps {
  onBlur?: (event: FocusEvent) => void;
  onFocus?: (event: FocusEvent) => void;
}

export const useTextFieldOpenListener = (props: ITextFieldEventProps) => {
  const dispatch = useDispatch();

  return {
    onBlur: (event: FocusEvent) => {
      dispatch(setTextFieldOpen(false));
      if (props.onBlur) {
        props.onBlur(event);
      }
    },
    onFocus: (event: FocusEvent) => {
      dispatch(setTextFieldOpen(true));
      if (props.onFocus) {
        props.onFocus(event);
      }
    },
  };
};
