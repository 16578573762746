import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import NotificationTypes from '../../../../../assets/values/notificationTypes.json';
import CheckoutFieldNames from '../../../../util/fieldNames/CheckoutFieldNames';
import FormKeys from '../../../../util/fieldNames/CheckoutFieldNames';
import { emailRegex } from '../../../../util/ValidationUtils';
import { useWatchedField } from '../../../effects/WatchedField';
import {
  IInputFieldGroupProps,
  InputFieldGroup,
} from '../../../wrappers/InputFieldGroup';
import {
  HookFormRadio,
  HookFormTextArea,
  HookFormTextInput,
} from '../../textInput/HookFormWrapper';
import { AddressFields } from '../AddressFields/AddressFields';
import { NameFields } from '../NameFields/NameFields';

const notificationOptions = [
  NotificationTypes.email,
  // NotificationTypes.card,
  NotificationTypes.none,
];

const cardForms = (props: IProps, clearButton: JSX.Element) => {
  return (
    <>
      <NameFields
        given={FormKeys.fields.recipientNameGiven}
        family={FormKeys.fields.recipientNameFamily}
        context={props.context}
        showMissing={props.showMissing}
        hideTitle
      />
      <AddressFields
        addressLabelPrefix='Recipient'
        line1={FormKeys.fields.notificationAddressLine1}
        line2={FormKeys.fields.notificationAddressLine2}
        city={FormKeys.fields.notificationCity}
        stateOrProvince={FormKeys.fields.notificationStateOrProvince}
        postalCode={FormKeys.fields.notificationPostalCode}
        country={FormKeys.fields.notificationCountry}
        context={props.context}
        showMissing={props.showMissing}
      />
      {props.injectControlledInput({
        as: HookFormTextArea,
        nameOrSuffix: FormKeys.fields.notificationMessage.name,
        label: 'Printed Message (Optional)',
        className: 'column is-full',
        maxLength: 250,
      })}
      <div className='column is-full d--f'>
        <button
          type='button'
          onClick={props.onPreviewCard}
          className='ut-btn btn--small corner--pill'
        >
          Preview
        </button>
        {clearButton}
      </div>
    </>
  );
};

const emailForms = (props: IProps, clearButton: JSX.Element) => {
  return (
    <>
      <NameFields
        given={FormKeys.fields.recipientNameGiven}
        family={FormKeys.fields.recipientNameFamily}
        context={props.context}
        showMissing={props.showMissing}
        hideTitle
      />
      {props.injectControlledInput({
        as: HookFormTextInput,
        nameOrSuffix: FormKeys.fields.notificationEmail.name,
        label: 'Recipient Email',
        placeholder: 'Recipient Email',
        className: 'column is-full',
        required: FormKeys.fields.notificationEmail.required,
        rules: {
          required: FormKeys.fields.notificationEmail.required,
          pattern: {
            value: emailRegex,
            message: 'Not a valid email address',
          },
        },
      })}
      {props.injectControlledInput({
        as: HookFormTextArea,
        nameOrSuffix: FormKeys.fields.notificationMessage.name,
        label: 'Email Message (Optional)',
        className: 'column is-full',
        maxLength: 250,
      })}
      <div className='column is-full d--f'>
        <button
          type='button'
          onClick={props.onPreviewEmail}
          className='ut-btn btn--small corner--pill'
        >
          Preview
        </button>
        {clearButton}
      </div>
    </>
  );
};

const NotificationFieldsView = (props: IProps) => {
  const { control, setValue, getValues } = useFormContext();

  const notificationTypeKey = FormKeys.fields.notificationType;
  const watchedNotificationTypeField = useWatchedField(
    notificationTypeKey.name,
    props.defaultValues[notificationTypeKey.name] ||
      NotificationTypes.email.value
  );

  // Clears all fields with "notification" in the name
  const clearValues = () => {
    const valuesDict = getValues();
    Object.keys(valuesDict)
      .filter(key =>
        key.includes(CheckoutFieldNames.prefixes.notification.notification)
      )
      .forEach(key => {
        setValue(key, '');
      });

    setValue(
      notificationTypeKey.name,
      watchedNotificationTypeField.defaultValue
    );
  };

  const clearButton = (
    <button
      onClick={clearValues}
      className='ut-link--secondary as--c'
      style={{ marginLeft: '30px' }}
    >
      Clear form
    </button>
  );

  let forms;
  switch (watchedNotificationTypeField.stringValue) {
    case 'none':
      break;
    case 'email':
      forms = emailForms(props, clearButton);
      break;
    case 'print':
      forms = cardForms(props, clearButton);
      break;
  }

  return (
    <div className='columns is-multiline pb--20'>
      <h4 className='ut-h4 column is-full donor-fields__subtitle'>
        Send a notification
      </h4>
      <div className='column is-full'>
        <Controller
          control={control}
          as={HookFormRadio}
          options={notificationOptions}
          name={notificationTypeKey.name}
          defaultValue={watchedNotificationTypeField.defaultValue}
          value={watchedNotificationTypeField.stringValue}
          className='column is-four-fifths'
          styleAsButtons
          required={notificationTypeKey.required}
        />
      </div>
      {forms}
    </div>
  );
};

export interface INotificationFieldsProps {
  onPreviewEmail: () => void;
  onPreviewCard: () => void;
  defaultValues: any;
  showMissing: boolean;
}

type IProps = IInputFieldGroupProps & INotificationFieldsProps;

export const NotificationFields = InputFieldGroup(NotificationFieldsView);
