import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setCartURLParams } from '../../../store/cart/actions';
import React, { useEffect, ReactNode } from 'react';
import { unwrapDataFromURLParams } from './LegacyURLUnwrapper';
import { setURLData } from '../../../store/legacy/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = { setSerializedCart: setCartURLParams };

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type IProps = ReduxProps & {
  children: ReactNode;
};

const CartQueryUnwrapperView = (props: IProps) => {
  const { setSerializedCart } = props;
  const searchString = useLocation().search;

  useEffect(() => {
    const queryParams = new URLSearchParams(searchString);
    setSerializedCart(queryParams);
    const urlData = unwrapDataFromURLParams(queryParams);
    setURLData(urlData);
  }, [setSerializedCart, searchString]);

  return <>{props.children}</>;
};

export default connector(CartQueryUnwrapperView);
