import {
  annually,
  monthly,
  oneTime,
} from '../../assets/values/singleGiftFrequencies.json';
import { getPledgeFrequencyObject } from '../util/dataSets/frequencies';
import { IGift } from './Gift';
import { GIFT_TYPE_PLEDGE, GIFT_TYPE_SINGLE } from './GiftType';
import { IGivingItem } from './GivingItem';

export interface IGiftAttributeBlock {
  [GIFT_TYPE_SINGLE]?: ISingleGiftSharedAttributes;
  [GIFT_TYPE_PLEDGE]?: IPledgeGiftSharedAttributes;
}

export interface ISingleGiftSharedAttributes {
  frequency?: string;
  duration?: number;
}

export interface IPledgeGiftSharedAttributes {
  frequency?: string;
  amount?: number;
  durationInYears?: number;
}

export interface ISingleGiftBuilder {
  type: typeof GIFT_TYPE_SINGLE;
  amount?: number;
  givingItem: IGivingItem;
}

export interface IPledgeGiftBuilder {
  type: typeof GIFT_TYPE_PLEDGE;
  givingItem: IGivingItem;
}

export type IGiftBuilder = ISingleGiftBuilder | IPledgeGiftBuilder;

export const builderIsValid = (
  builder: IGiftBuilder,
  attributes: IGiftAttributeBlock
) => {
  switch (builder.type) {
    case GIFT_TYPE_SINGLE:
      return singleGiftIsValid(builder, attributes[GIFT_TYPE_SINGLE]);
    case GIFT_TYPE_PLEDGE:
      return pledgeGiftIsValid(builder, attributes[GIFT_TYPE_PLEDGE]);
  }
};

const singleGiftIsValid = (
  builder: ISingleGiftBuilder,
  sharedAttributes?: ISingleGiftSharedAttributes
) => {
  return (
    builder.amount &&
    builder.amount > 0 &&
    sharedAttributes &&
    sharedAttributes.frequency
  );
};

const pledgeGiftIsValid = (
  builder: IPledgeGiftBuilder,
  sharedAttributes?: IPledgeGiftSharedAttributes
) => {
  const frequencyOption = getPledgeFrequencyObject(sharedAttributes?.frequency);
  return (
    sharedAttributes &&
    sharedAttributes.amount &&
    sharedAttributes.amount > 0 &&
    sharedAttributes.durationInYears &&
    frequencyOption
  );
};

export const buildGiftUnsafe = (
  builder: IGiftBuilder,
  attributes: IGiftAttributeBlock
) => {
  switch (builder.type) {
    case GIFT_TYPE_SINGLE:
      return buildSingleGiftUnsafe(builder, attributes[GIFT_TYPE_SINGLE]!);
    case GIFT_TYPE_PLEDGE:
      return buildPledgeGiftUnsafe(builder, attributes[GIFT_TYPE_PLEDGE]!);
  }
};

const buildSingleGiftUnsafe = (
  builder: ISingleGiftBuilder,
  sharedAttributes: ISingleGiftSharedAttributes
) => {
  const isRecurring = sharedAttributes.frequency !== oneTime.value;
  const isSustained = isRecurring && sharedAttributes.duration === -1;

  let giftType = 'one time';

  let nextDateDue;

  if (isSustained) {
    giftType = 'sustaining';
  } else if (isRecurring) {
    giftType = 'recurring';
  }

  if (isRecurring) {
    const date = new Date();

    if (sharedAttributes.frequency === monthly.value) {
      date.setMonth(date.getMonth() + 1);
    } else if (sharedAttributes.frequency === annually.value) {
      date.setFullYear(date.getFullYear() + 1);
    }

    nextDateDue = date.toISOString();
  }

  const startDate = new Date().toISOString();

  return {
    giftType,
    givingItemName: builder.givingItem.givingItemSignature.name,
    givingItemSku: builder.givingItem.givingItemSignature.sku,
    giftFrequency: isRecurring ? sharedAttributes.frequency : undefined,
    frequency: isRecurring ? sharedAttributes.frequency : undefined,
    // giftDuration: isSustained ? sharedAttributes.duration : undefined,
    duration:
      isRecurring && !isSustained ? sharedAttributes.duration : undefined,
    startDate: isRecurring ? startDate : undefined,
    nextDateDue,
    amount: builder.amount!,
    codings: builder.givingItem.codings,
    link: builder.givingItem.links,
    benefitAmount: builder.givingItem.benefitAmount,
    defaultDedication: builder.givingItem.dedication,
  } as IGift;
};

const buildPledgeGiftUnsafe = (
  builder: IPledgeGiftBuilder,
  sharedAttributes: IPledgeGiftSharedAttributes
) => {
  const frequencyOption = getPledgeFrequencyObject(sharedAttributes.frequency)!;
  const duration =
    sharedAttributes.durationInYears! * frequencyOption.paymentsPerYear;

  return {
    giftType: 'pledge',
    givingItemSku: builder.givingItem.givingItemSignature.sku,
    givingItemName: builder.givingItem.givingItemSignature.name,
    duration,
    amount: sharedAttributes.amount!,
    frequency: sharedAttributes.frequency,
    codings: builder.givingItem.codings,
    startDate: new Date().toISOString(),
    links: builder.givingItem.links,
    benefitAmount: builder.givingItem.benefitAmount,
    defaultDedication: builder.givingItem.dedication,
  } as IGift;
};
