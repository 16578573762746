import { call, put, take, select, fork, delay } from 'redux-saga/effects';

import * as Types from './types';

import * as AddressAPI from '../../api/endpoints/AddressEndpoint';

import { setSuggestions } from './actions';
import {
  getQueryForAddressKeyCreator,
  getQueryIsValidForAddressKeyCreator,
} from './selectors';
import { IAddressLookupResponse } from '../../models/api/AddressLookupResponse';

function* fetchAutocompleteSuggestions(
  addressKey: string,
  debounceTime?: number
) {
  const validForKey = yield select(getQueryIsValidForAddressKeyCreator());
  const queryForKey = yield select(getQueryForAddressKeyCreator());

  const query = queryForKey(addressKey);

  if (!query || !validForKey(addressKey)) {
    return;
  }

  try {
    if (debounceTime) {
      yield delay(debounceTime);
    }

    const response: IAddressLookupResponse = yield call(
      AddressAPI.fetchAutocompleteSuggestions,
      query
    );

    if (response) {
      yield put(setSuggestions(addressKey, response.suggestions));
    }
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.log(error);
  }
}

function* watchQuerySaga() {
  while (true) {
    const action = yield take(Types.SET_QUERY);
    yield fork(fetchAutocompleteSuggestions, action.addressKey, 300);
  }
}

function* addressSaga() {
  yield fork(watchQuerySaga);
}

export default addressSaga;
