import * as React from 'react';
import useScreenWidth, { ViewType } from '../../effects/layout/ScreenWidth';

export const ResponsiveContainer = <P extends object>(
  Desktop: React.ComponentType<P>,
  Mobile: React.ComponentType<P>
) => {
  const screenWidth = useScreenWidth();

  return (props: P) => {
    switch (screenWidth) {
      case ViewType.Desktop:
        return <Desktop {...(props as P)} />;
      case ViewType.Mobile:
        return <Mobile {...(props as P)} />;
      default:
        return null;
    }
  };
};
