import { IPledge } from '../models/Pledge';

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function isEmpty(obj: IPledge | undefined): boolean {
  return obj === undefined || !Object.keys(obj).length;
}
