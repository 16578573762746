import React from 'react';
import confirmationBevo from '../../../../assets/images/transaction-loading.gif';

import './TransactionModal.scss';

export const TransactionModal = () => (
  <div className='modal'>
    <div className='modal__content'>
      <div className='transaction-modal__title ut-h4'>
        <b>Your payment is processing.</b>
      </div>
      <div className='transaction-modal__gif-container'>
        <img
          className='transaction-modal__gif'
          src={confirmationBevo}
          alt=''
        ></img>
      </div>
    </div>
  </div>
);

export const windowClassName = 'transaction-modal__window';
