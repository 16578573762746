import {
  GiftType,
  GIFT_TYPE_SINGLE,
  GIFT_TYPE_PLEDGE,
} from '../models/GiftType';

export interface IRoute {
  label: string;
  path: string;
}

export const appendBasePath = (basePath: string) => (location: string) =>
  `${basePath}${location}`;

export const rootForGiftType = (giftType: GiftType) => {
  switch (giftType) {
    case GIFT_TYPE_SINGLE:
      return OGP;
    case GIFT_TYPE_PLEDGE:
      return Pledge;
  }
};

const OGP = {
  label: 'OGP',
  path: '/',
};

const Pledge = {
  label: 'Pledge',
  path: '/pledge/',
};

const Confirmation = {
  label: 'Confirmation',
  path: '/confirmation',
};

const Amount = {
  label: 'Amount',
  path: 'amount',
};

const Frequency = {
  label: 'Frequency',
  path: 'frequency',
};

const Checkout = {
  label: 'Details',
  path: 'checkout',
};

const Search = {
  label: 'Find Funds',
  path: '',
};

const SearchArea = {
  label: '',
  path: 'area/:sku',
};

export const Routes = {
  Roots: {
    OGP,
    Pledge,
    Confirmation,
  },

  // Sections may fall under the OGP or Pledge root.
  Sections: {
    Search,
    Frequency,
    Amount,
    Checkout,
  },

  SearchArea,
};

export const PATH_EMBED = '/embed';
export const PATH_MINI_EMBED = '/miniEmbed';
