import * as React from 'react';

export const CompanyMatchingTooltipData = () => (
  <div>
    Contact your company to start the match process.
    <br />
    <a
      href='http://giving.utexas.edu/matching-gifts'
      target='blank'
      className='ut-link--tooltip'
    >
      See if your company matches.
    </a>
  </div>
);
