import { ILegacyURLData } from '../../models/LegacyURLData';
import { IGivingItem } from '../../models/GivingItem';

export interface IStore {
  urlData?: ILegacyURLData;
  givingItems?: IGivingItem[];
}

export const SET_URL_DATA = 'SET_URL_DATA';
export const SET_ITEMS = 'SET_URL_DATA;';

interface ISetURLData {
  type: typeof SET_URL_DATA;
  urlData?: ILegacyURLData;
}

interface ISetGivingItems {
  type: typeof SET_ITEMS;
  givingItems?: IGivingItem[];
}

export type ActionTypes = ISetURLData | ISetGivingItems;
