import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { IPledge } from '../../../../../models/Pledge';
import { baseClassName } from '../MobilePledgeSummary';
import { RootState } from '../../../../../store';
import * as pledgeSelectors from '../../../../../store/pledge/existingPledge/selectors';
import { createSummaryClasses } from '../../../../../util/classes/MobileSummaryClassNames';
import { FormatCurrency } from '../../../../../util/NumberFormat';
// import { formatDate } from '../../../../../util/DateUtils';

const mapStateToProps = (state: RootState) => ({
  knownID: pledgeSelectors.getIDKnown(state),
  existingPledges: pledgeSelectors.getPledges(state),
  amount: pledgeSelectors.getNoIDPaymentAmount(state),
  autopayEnabled: pledgeSelectors.getAutopayEnabled(state),
});

const connector = connect(mapStateToProps);
type IProps = ConnectedProps<typeof connector>;

const MobilePledgeSummaryPaymentView = (props: IProps) => {
  const content = props.knownID ? contentWithID(props) : contentNoID(props);
  return <React.Fragment>{content}</React.Fragment>;
};

const contentWithID = (props: IProps) => {
  return props.existingPledges.map(renderPledge(props));
};

const contentNoID = (props: IProps) =>
  renderGift(props)(0, 'Other - Specify in comments');

const renderPledge = (props: IProps) => (pledge: IPledge, index: number) =>
  renderGift(props)(index, pledge.givingItemSignature.name, pledge.dateDue);

const renderGift =
  (props: IProps) => (index: number, name?: string, dateDue?: string) => {
    const summaryClasses = createSummaryClasses(baseClassName);

    return (
      <div className={`${summaryClasses.gift}`} key={`${index}`}>
        <div className={`${summaryClasses.giftAmount}`}>
          {FormatCurrency(props.amount)}
        </div>
        <div className={`${summaryClasses.giftInfo}`}>
          <p className={`${summaryClasses.giftName}`}>{name}</p>
          {/* <div className={`${summaryClasses.giftFrequency}`}>
            <p>Next Payment - {formatDate(dateDue)}</p>
            <p>
              Automatic Payments -{' '}
              {props.autopayEnabled ? 'Enabled' : 'Disabled'}
            </p>
          </div> */}
        </div>
      </div>
    );
  };

export const MobilePledgeSummaryPayment = connector(
  MobilePledgeSummaryPaymentView
);
